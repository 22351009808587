import { useEffect, useState } from "react"
import api from "../components/api"


const Modal = (props) => {

    const usuario_id = props.usuario_id

    const [novaSenha, setNovaSenha] = useState('')
    const [confirmaNovaSenha, setConfirmaNovaSenha] = useState('')


    const salva = () => {


        if (novaSenha == '') {

            window.toastr.error('<b>Senha</b> não pode ficar em branco!')
            return false

        }


        if (novaSenha != confirmaNovaSenha) {

            window.toastr.error('Senhas não conferem!')
            return false

        }


        var dataPost = {

            novaSenha: novaSenha,

        }



        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma altera a senha?',
		    buttons: {
		        Sim: function () {




                                
                    api.put(`usuarioSenha/${usuario_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        limpa()
                        window.toastr.success('Senha alterada com sucesso!')
                        window.$('.modal-AlteraSenha').modal('hide')
                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }

    const limpa = () => {

        setNovaSenha('')
        setConfirmaNovaSenha('')

    }

    useEffect(() => {

        limpa()

    }, [])


    return (


        <div>

                <div class={`modal fade modal-AlteraSenha`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Alterar senha</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Nova senha *</label>
                                        <input type="password" className="form-control" value={novaSenha}  onChange={event => setNovaSenha(event.target.value)} maxLength={25} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Confirma nova senha *</label>
                                        <input type="password" className="form-control" value={confirmaNovaSenha} onChange={event => setConfirmaNovaSenha(event.target.value)} maxLength={25} />
                                            
                                        
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal