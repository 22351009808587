import { useState } from 'react'
import moment from 'moment'
import axios from 'axios'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";


const Modal = (props) => {


    const certidao_id = props.certidao_id
    const fornecedor_id = props.fornecedor_id
    const descricaoAnexo = props.descricaoAnexo
    const validade = props.validade
    const fornecedorCertidao_id = props.fornecedorCertidao_id

    const usuario_id = sessionStorage.getItem('usuario_id')

    const [file, setFile] = useState('')
    const [dataValidade, setDataValidade] = useState()
    

    
    const salvaAnexo = () => {

        //console.log(validade)

        if (validade == true) {


            if (dataValidade == '') {

                window.toastr.error('Por favor, a data de vencimento da certidão!')
                return false

            } else {

                if (moment(dataValidade, "DD/MM/YYYY", true).isValid() == false) {
        
                    window.toastr.error('<b>Data de vencimento</b> inv&aacute;lida!');
                    return false;

                } else {

                    //console.log('aqui')
                    
                    var dataHoje = moment().format('YYYY-MM-DD');

                    

                    var dataValidade_array = moment(dataValidade).format('YYYY-MM-DD').split('/')
                    var dataValidadeF2 = dataValidade_array[2] + '-' + dataValidade_array[1] + '-' + dataValidade_array[0]

                    var dataValidadeF3 = new Date(dataValidadeF2)

                    //console.log(dataAtendimentoF3, dataHoje)  
                    if (moment(dataValidadeF3, 'YYYY-MM-DD').diff(dataHoje, 'days') < 0) {
                        console.log(moment(dataValidadeF3, 'YYYY-MM-DD').diff(dataHoje, 'days') )
                        window.toastr.error('<b>Data de validade</b> não pode ser anterior a data atual!');
                        return false;

                    }

                }


            }



        }




        if (file == null || file == '') {


            window.toastr.error('Por favor, selecione o <b>arquivo</b> para anexar')
            return false


        } else {

            
            if (file.substr(file.length - 3).toLowerCase() != 'pdf') {

                window.toastr.error('Por favor, apenas arquivos no formato <b>PDF</b> para anexar!')
                return false


            }

        }

        

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma anexar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileCertidaoFornecedor");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("certidao_id", certidao_id); // string junto ao formData.
                    formData.append("fornecedor_id", fornecedor_id); // string junto ao formData.
                    formData.append("dataValidade", dataValidade); // string junto ao formData.
                    formData.append("usuario_id", usuario_id); // string junto ao formData.
                    formData.append('fornecedorCertidao_id', fornecedorCertidao_id)
                    formData.append('validade', validade)
                    

                    axios.post(`${process.env.REACT_APP_API}/certidaoAnexo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        console.log(response.data)
                        setFile()
                        setDataValidade()
                        window.$('#FornecedorAnexo').modal('hide')
                        
                        /*
                        setTipoDoc_id('')
                        setFile('')
                        setTipoDoc_descri('')
                        setDestinoAnexo('')
                        carregaAnexos()
                        props.totalizaAnexos()
                        props.carregaHistoricoDemanda()
                        props.setDemandaStatus_id(response.data.demandaStatus_id)
                        props.setDemandaStatusAnterior_id(response.data.demandaStatus_id)
                        */
                        
                        window.toastr.success('Arquivo anexado com sucesso!')
                        setFile('')
                        props.listaCertidoes()


                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    return (

        <div>

            <div class='modal fade modal-FornecedorAnexo'
                    id="FornecedorAnexo"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                    <div class="modal-dialog modal-lg" >
                        <div class="modal-content"  >
                            <div class="modal-header" >
                                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                    <i class="font-icon-close-2"></i>
                                </button>
                                <h4 class="modal-title" id="myModalLabel">Fornecedor - {descricaoAnexo}</h4>
                            </div>
                            <div class="modal-body">


                                <div className="row">
                                    
                                    <div className="col-md-12" style={{ display: validade == true ? 'table-row' : 'none'}}>
                                        <fieldset className="form-group">
                                            <label className="form-label" for="exampleError">Data vencimento *</label>
                                            
                                            <DatePicker   selected={dataValidade || null}  onChange={(date) => setDataValidade(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" /> } 
                                            />
                                                
                                                
                                            
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12" >
                                        <fieldset className="form-group">
                                            <label className="form-label">Arquivo *</label>
                                            <input type="file" className="form-control" id="fileCertidaoFornecedor" value={file} onChange={event => setFile(event.target.value)} />

                                        </fieldset>
                                    </div>



                                </div>


                           

                             </div>
                            <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => salvaAnexo()} >Salvar</button>
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )


}


export default Modal