import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, sleep } from '../functions/generalFunctions';
import ModalDemandaSolicitante from '../modal/ModalDemandaSolicitante'

const ComprasSolicitantes = () => {


    const [resultado, setResultado] = useState([])
    
    const [demandaSolicitante_id, setDemandaSolicitante_id] = useState()


    const columns = useMemo(
        () => [
          {
            accessorKey: 'solicitante',
            header: 'Solicitante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
         

         
          {
            accessorKey: 'precisaAutorizaGestao',
            header: 'Necessita autorização Gestão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },

              Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == true ? 'info' : '' }`}>{renderedCellValue == true ? 'Sim' : ''}</span>
           
            ),
            
          },
         
        ],
        [],
      );


    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

            //console.log(result.data)

            setResultado(result.data)
                

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        carregaSolicitantes()

    }, [])

    return (

        <div>

            <button type="button" class="btn btn-inline btn-warning" onClick={() => { window.$('.modal-DemandaSolicitante').modal('show');setDemandaSolicitante_id()}} >+ Novo</button>
           
                    
            <br/><br/>


                <MaterialReactTable 
                    
                    columns={columns} 
                    data={resultado} 
                    localization={MRT_Localization_PT_BR}
                    //rowsPerPage={20}
                    //options={options}
                    initialState={{ density: 'compact' }}
                    muiTableHeadCellProps={{
                        //easier way to create media queries, no useMediaQuery hook needed.
                        sx: {
                            fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                            },
                        },
                        }}
                    muiTableBodyCellProps={{
                        sx: {
                            fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                            },
                            },
                    }}

                    muiTableBodyRowProps={({ row }) => ({
                        onClick: (event) => {
                        //console.info(event, row.original.usuario_id);
                        //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                        window.$('.modal-DemandaSolicitante').modal('show');setDemandaSolicitante_id(row.original.demandaSolicitante_id)

                        },
                        sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                        },
                    })}
                    
                />



            <ModalDemandaSolicitante demandaSolicitante_id={demandaSolicitante_id} carregaSolicitantes={carregaSolicitantes} />

        </div>
    )


}

export default ComprasSolicitantes