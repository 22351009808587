import { useState, useEffect } from 'react'
import api from "../components/api"


const Modal = (props) => {

    const fornecedor_id = props.fornecedor_id
    const [resultado, setResultado] = useState([])

    const [totalAnexosExistentes, setTotalAnexosExistentes] = useState(0)


    const download = (arquivo) => {


        if (arquivo) {

            window.open(`${process.env.REACT_APP_API}/upload/certidoes/${arquivo}`)
            
        }
        
        
    }



    const carregaCertidoesFornecedor = () => {

        
        api.get(`fornecedorCertidoesPrevia/${fornecedor_id}`).then((result) => {

            setTotalAnexosExistentes(result.data.length)


            setResultado(result.data.map((rs) => 
            

                <tr style={{  fontSize: '11px' }}>

                    <td style={{ cursor: rs.arquivo ? 'pointer' : 'auto'}} onClick={() => download(rs.arquivo)}>
                        <font color={ rs.arquivo ? 'blue' : ''}>{rs.descricao}</font>
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        { rs.validade == false ? 'N/A' : rs.dataValidade }
                    </td>
                    

                </tr>

            
            ))

        }).catch((err) => {

            console.log(err)

        })

    }



    const vinculaCertidoes = () => {


        

    }


    useEffect(() => {

        if (fornecedor_id) {

            carregaCertidoesFornecedor()

        }

    }, [fornecedor_id])


    return (

        <div>

                <div class={`modal fade modal-PreviaCertidoes`}
                    id="ModalPreviaCertidoes"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Certidões encontradas ({totalAnexosExistentes})</h4>
                        </div>
                        <div class="modal-body">

                            <div style={{ display: totalAnexosExistentes == 0 ? 'table-row' : 'none', textAlign: 'center' }}>
                                
                                
                                Nenhum registro encontrado
                                
                                
                            </div>

                            <div className="row">
                                <div className="col-md-12">

                                    <div style={{ display: totalAnexosExistentes > 0 ? 'block' : 'none'}}>

                                        <table id="table-sm" class="table table-bordered table-hover table-sm" >
                                            <thead>
                                                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                        
                                                        <th>
                                                            
                                                        Descrição                                 

                                                        </th>
                                                        <th>
                                                            <center>
                                                            Validade
                                                            </center>

                                                        </th>
                                                       
                                                    </tr>
                                                    
                                            </thead>
                                            <tbody>

                                                {resultado}

                                            </tbody>                       
                                            
                                            
                                        </table>

                                    </div>

                                </div>
                            </div>

                            


                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => vinculaCertidoes()} style={{ display: totalAnexosExistentes > 0 ? 'table-row' : 'none' }} >Vincular</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal