import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import moment from 'moment'
import { sleep } from '../functions/generalFunctions';
import { PieChart, Pie, Sector, Cell, BarChart, Bar, LabelList, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer  } from "recharts";


const renderCustomizedLabel = (props) => {
    //const { x, y, width, height, value } = props;
    const {x, y, fill, value} = props;
    const radius = 10;
  
    return (
      <g>
    
        <text 
           x={x} 
           y={y} 
           dy={-4} 
           fontSize='16' 
           fontFamily='sans-serif'
           fill={fill}  
           textAnchor="right">
            
           </text>
      </g>
    );
  };


  const CustomTooltip = ({ active, payload, label }) => {
    //console.log(payload)
    if (active && payload && payload.length) {
      return (
        <div className="customTooltip">
          <p><font color="brown">{(payload[0]?.payload?.total)}</font><br/> <br/>             
          
          {`${payload[0]?.payload?.name}`}
          </p>
        </div>
      );
    }
  
    return null;
  };




const ComprasIndicadores = () => {

    const [resultado, setResultado] = useState([])
    const [detalhes, setDetalhes] = useState()

    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()
    const [tipoIndicador, setTipoIndicador] = useState('fase')
    const [columnVisibility, setColumnVisibility] = useState({
        responsavel: false,
      });


    function carregaAnos() {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }


        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#003366', '#d742f5', '#f54242', '#486360', '#42f554'];  

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel2 = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };


    var columns = useMemo(
        () => 
        
        [

                {
                    accessorKey: 'parametro',
                    header: 'Parâmetro',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },


                {
                    accessorKey: 'univ',
                    header: '',
                    muiTableHeadCellProps: {
                        align: 'left',
                    },
                    muiTableBodyCellProps: {
                        align: 'left',
                    },
                },
                


                {
                    accessorKey: 'mediaDias',
                    header: 'Média dias',
                    muiTableHeadCellProps: {
                        align: 'right',
                    },
                    muiTableBodyCellProps: {
                        align: 'right',
                    },
                    
                },
        
        ],
        [],
    );




      const carregaIndicadores = () => {

        if (tipoIndicador == 'status') {


            var dataPost = {

                ano: ano,

            }


            api.post('demandaMediaFase', dataPost).then((result) => {

                

                setData(result.data.map((rs) => {
               
                    return {
    
                        name: rs.fase,
                        total: rs.total,
                        //descricao: rs.descricao,
                        //quantidade: rs.quantidade,
                        
    
                    }
    
                                   
    
                }))

            }).catch((err) => {

                console.log(err.response)

            })


        } else {

            if (tipoIndicador == 'faseGrafico') {


                var dataPost = {

                    ano: ano,
    
                }
    
    
                api.post('demandaMediaFase', dataPost).then((result) => {
    
                    //console.log(result.data, '!!')
    
                    setData2(result.data.map((rs) => {
                   
                        return {
        
                            name: rs.fase,
                            total: rs.total,
                            //descricao: rs.descricao,
                            //quantidade: rs.quantidade,
                            
        
                        }
        
                                       
        
                    }))
    
                }).catch((err) => {
    
                    console.log(err.response)
    
                })



                
            } else {

                if (tipoIndicador == 'solicitanteGrafico' || tipoIndicador == 'compradorGrafico') {



                    var dataPost = {

                        ano: ano,
                        tipoIndicador: tipoIndicador == 'solicitanteGrafico' ? 'solicitante' : 'comprador',
        
                    }
        
        
                    api.post('demandaMediaDias', dataPost).then((result) => {
        
                        //console.log(result.data, '!!')
        
                        setData2(result.data.map((rs) => {
                       
                            return {
            
                                name: rs.parametro,
                                total: parseInt(rs.univ.replaceAll('demanda(s)', '')),
                                //descricao: rs.descricao,
                                //quantidade: rs.quantidade,
                                
            
                            }
            
                                           
            
                        }))
        
                    }).catch((err) => {
        
                        console.log(err.response)
        
                    })


                } else {



                    if (tipoIndicador == 'diasConclusao') {

            
                        api.post(`exportaDiasDemandaConclusao/${ano}`).then((result) => {
            
                            //console.log(result.data)
            
                            //setResultado(result.data)

                            window.toastr.info('Aguarde, gerando XLSX...')

                            if (result.data.status == 'ok') {

                    
        
                                sleep(1000).then(() => {
                
                                    //console.log(result.data[0].fileNameCompleto, '!!')
                                    //window.location.href = result.data.arquivo
                                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                                    window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                                })
                                
                
                            }
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })


                    } else {

                        var dataPost = {

                            ano: ano,
                            tipoIndicador: tipoIndicador,
            
                        }
            
                        api.post('demandaMediaDias', dataPost).then((result) => {
            
                            //console.log(result.data)
            
                            setResultado(result.data)
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })

                    }


                }


            }
        }

    }

    useEffect(() => {

        carregaIndicadores()
        carregaAnos()

    }, [ano, tipoIndicador])



    useEffect(() => {

        if (tipoIndicador == 'fase') {

            setColumnVisibility({ responsavel: true }); 

        } else {

            setColumnVisibility({ responsavel: false });

        }

        
      }, [tipoIndicador]);
    


    return (

        <div>

            <div className="row">


              <div className="col-md-3 col-sm-6">
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">Ano</label>
                      <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                          {listaAnos}
                      </select>
                      
                  </fieldset>
              </div>

              <div className="col-md-9 col-sm-6">
                  <fieldset className="form-group">
                      <label className="form-label" for="exampleError">Indicador</label>
                      <select type="text" className="form-control" value={tipoIndicador} onChange={event => setTipoIndicador(event.target.value)}>
                          <option value="fase">Média de dias por fase</option>
                          <option value="comprador">Média de dias por comprador</option>
                          <option value="solicitante">Total de demandas por solicitante</option>
                          <option value="compradorGrafico">Gráfico comprador - Percentual</option>
                          <option value="solicitanteGrafico">Gráfico solicitante - Percentual</option>
                          <option value="faseGrafico">Gráfico fase - Percentual</option>
                          <option value="status">Gráfico status - Quantitativo</option>
                          <option value="diasConclusao">Total de dias data cadastro/conclusão</option>
                      </select>
                      
                  </fieldset>
              </div>
            

              <div className="col-md-12 col-sm-6">


              <div style={{ display: tipoIndicador == 'status' || tipoIndicador == 'compradorGrafico' || tipoIndicador == 'faseGrafico' || tipoIndicador == 'solicitanteGrafico' ? 'none' : 'block'}}>

                    <MaterialReactTable 
                        
                        columns={columns} 
                        data={resultado} 
                        localization={MRT_Localization_PT_BR}
                        //rowsPerPage={20}
                        //options={options}
                        initialState={{ density: 'compact' }}
                        onColumnVisibilityChange={ setColumnVisibility }
                        muiTableHeadCellProps={{
                            //easier way to create media queries, no useMediaQuery hook needed.
                            sx: {
                                fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                                },
                            },
                            }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                        }}

                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                            //console.info(event, row.original.usuario_id);
                            //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                            //window.$('.modal-Modalidade').modal('show');setModalidade_id(row.original.modalidade_id)

                            },
                            sx: {
                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            },
                        })}
                        
                    />

                    </div>

                </div>


                <div className="col-md-12 col-sm-6">
             
                       
                        <div style={{ display: tipoIndicador == 'compradorGrafico' || tipoIndicador == 'faseGrafico' || tipoIndicador == 'solicitanteGrafico' ? 'block' : 'none'}}>
                            
                            <PieChart width={700} height={820}>
                                <Legend layout="vertical" verticalAlign="top" align="top" />
                                <Pie labelLine={false} data={data2} dataKey="total" outerRadius={250} fill="green" label={renderCustomizedLabel2} >
                                {data2.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                                </Pie>
                            </PieChart>
                        </div>

                        <div style={{ display: tipoIndicador == 'status' ? 'block' : 'none'}}>



                            <ResponsiveContainer  >

                                <BarChart
                                
                                width={500}
                                height={350}
                                data={data}
                                margin={{top: 5, right: 0, left: 0, bottom: 25}}
                                
                                >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name"
                                    fontFamily="sans-serif"
                                    fontSize='0.8rem'
                                    tickSize
                                    dy='20'
                                    textAnchor="middle"
                                    label={{ position: 'top' }} />
                                <YAxis hide />
                                <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                
                                
                                <Bar dataKey={'total'} position="middle"  fill="#8884d8"  onClick={(data)=> { setDetalhes(data) }} style={{ cursor: 'pointer'}}>
                                    <LabelList dataKey={'total'} content={renderCustomizedLabel} label={{ position: 'top' }} />
                                    
                                </Bar>
                                        
                                
                                </BarChart>


                            </ResponsiveContainer>



                    </div>

                </div>


                    

            </div>


        </div>

    )

}

export default ComprasIndicadores