import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import api from "../components/api"
import InputMask from 'react-input-mask'
import { validaCPF } from "../functions/generalFunctions"

const ComprasAtestadoNF = (props) => {


    const { demandaStatus_id, 
            demanda_id, tes: 
            tesProps, 
            produto: produtoProps, 
            numeroContrato: numeroContratoProps, 
            cpfGestorContrato: cpfGestorContratoProps, 
            nomeGestorContrato: nomeGestorContratoProps,
            centroCusto_id: centroCusto_idProps,
            centroCustoDescri: centroCustoDescriProps,
            itemContabil: itemContabilProps,
            itemContabilDescri: itemContabilDescriProps,
         } = props

    //console.log(props)

    const [tes, setTes] = useState('')
    const [produto, setProduto] = useState('')
    const [numeroContrato, setNumeroContrato] = useState('')
    const [cpfGestorContrato, setCpfGestorContrato] = useState('')
    const [nomeGestorContrato, setNomeGestorContrato] = useState('')

    const [colorItemContabil, setColorItemContabil] = useState('white')
    const [itemContabil, setItemContabil] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [resultadoItemContabil, setResultadoItemContabil] = useState('')

    const [centroCusto, setCentroCusto] = useState('')
    const [colorCentroCusto, setColorCentroCusto] = useState('white')
    const [centroCustoDescri, setCentroCustoDescri] = useState('')
    const [resultadoCentroCusto, setResultadoCentroCusto] = useState('')
    const [centroCusto_id, setCentroCusto_id] = useState()

    const [resultadoGestorContrato, setResultadoGestorContrato] = useState('')
    const [nomeMarcado, setNomeMarcado] = useState(false)


    useEffect(() => {

        setItemContabil(itemContabilProps)

    }, [itemContabilProps])


    useEffect(() => {

        setItemContabilDescri(itemContabilDescriProps)

    }, [itemContabilDescriProps])


    useEffect(() => {

        setCentroCustoDescri(centroCustoDescriProps)

    }, [centroCustoDescriProps])


    useEffect(() => {

        setCentroCusto_id(centroCusto_idProps)

    }, [centroCusto_idProps])


    useEffect(() => {

        setCpfGestorContrato(cpfGestorContratoProps ? cpfGestorContratoProps : '')

    }, [cpfGestorContratoProps])

    useEffect(() => {

        setNomeGestorContrato(nomeGestorContratoProps)

        if (nomeGestorContratoProps) {

            setNomeMarcado(true)

        }

    }, [nomeGestorContratoProps])


    useEffect(() => {

        setTes(tesProps)

    }, [tesProps])

    useEffect(() => {

        setProduto(produtoProps)

    }, [produtoProps])


    useEffect(() => {

        setNumeroContrato(numeroContratoProps)

    }, [numeroContratoProps])



    useEffect(() => {

        //console.log(centroCusto_id)

        if (centroCusto_id) {

            setColorCentroCusto('orange')

        } else {

            setColorCentroCusto('white')

        }
        

    }, [centroCusto_id])



    useEffect(() => {

        if (itemContabil != null && itemContabil != '') {

            setColorItemContabil('orange')

        } else {

            setColorItemContabil('white')

        }
        

    }, [itemContabil])




    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (centroCustoDescri != '' && centroCusto_id == null) {

            var dataPost = {

                centroCustoDescri: centroCustoDescri,

            }

            api.post('buscaCentroCusto', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoCentroCusto(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoCentroCusto('');setCentroCusto_id(rs.centroCusto_id);setCentroCusto(rs.centroCusto);setCentroCustoDescri(`${rs.centroCusto} - ${rs.descricao}`)}}><font color='purple'>[{rs.centroCusto}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [centroCustoDescri])



    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (nomeGestorContrato != '' && nomeMarcado == false) {

            var dataPost = {

                busca: nomeGestorContrato,

            }

            api.post('buscaGestorContrato', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoGestorContrato(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoGestorContrato('');setNomeMarcado(true);setCpfGestorContrato(rs.cpfGestorContrato);setNomeGestorContrato(rs.nomeGestorContrato)}}><font color='purple'>[{rs.cpfGestorContrato}]</font> - {rs.nomeGestorContrato}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [nomeGestorContrato])


    

    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (itemContabilDescri != '' && (itemContabil == null || itemContabil == '')) {

            var dataPost = {

                itemContabilDescri: itemContabilDescri,

            }

            api.post('buscaItemContabil', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoItemContabil(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoItemContabil('');setItemContabil(rs.itemContabil);setItemContabilDescri(`${rs.itemContabil} - ${rs.itemContabilDescri}`)}}><font color='purple'>[{rs.itemContabil}]</font> - {rs.itemContabilDescri}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemContabilDescri])




    const salva = () => {

        if (!validaCPF(cpfGestorContrato.replaceAll('.', '').replaceAll('-', ''))) {

            window.toastr.error('<b>CPF Gestor do contrato</b> inválido!')
            return false

        }

        if (itemContabil == '' || itemContabil == null) {

            window.toastr.error('<b>Item Contábil</b> não pode ficar em branco!')
            return false

        }


        if (centroCusto_id == '' || centroCusto_id == null) {

            window.toastr.error('<b>Centro de Custo</b> não pode ficar em branco!')
            return false

        }

        if (nomeGestorContrato == '' || nomeGestorContrato == null) {

            window.toastr.error('<b>Gestor do Contrato</b> não pode ficar em branco!')
            return false

        }

        if (numeroContrato == '' || numeroContrato == null) {

            window.toastr.error('<b>Número do contrato</b> não pode ficar em branco!')
            return false

        }

        if (produto == '' || produto == null) {

            window.toastr.error('<b>Produto</b> não pode ficar em branco!')
            return false

        }


        var dataPost = {

            tes: tes,
            produto: produto,         
            numeroContrato: numeroContrato,   
            cpfGestorContrato: cpfGestorContrato,
            nomeGestorContrato: nomeGestorContrato,
            itemContabil: itemContabil,
            itemContabilDescri: itemContabilDescri,
            centroCusto_id: centroCusto_id,

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar os dados para Atestado de Nota Fiscal?',
		    buttons: {
		        Sim: function () {


                          
                    api.put(`demandaAtestadoNF/${demanda_id}`, dataPost).then((result) => {

                        console.log(result.data)
                        window.toastr.success('Registro salvo com sucesso!')

                    }).catch((err) => {

                        console.log(err.response)

                    })

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    return (

        <div className="row">
                    
                    
            <div class="col-md-12">
                <section class="card card-purple mb-3">
                    <header class="card-header">
                        Dados para Atestado de Nota Fiscal
                        
                    </header>
                    <div class="card-block">
                        <p class="card-text">
                            
                        <div className="row">

                            <div className="col-md-6" >

                                <fieldset className="form-group">
                                    <label className="form-label">Produto *</label>
                                    <input type="text" className="form-control" value={produto} maxLength={50} onChange={event => setProduto(event.target.value)}  disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>    
                            <div className="col-md-6" >

                                <fieldset className="form-group">
                                    <label className="form-label">N<sup>o</sup> Contrato *</label>
                                    <input type="text" className="form-control" value={numeroContrato} maxLength={50} onChange={event => setNumeroContrato(event.target.value)}  disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>   
                            <div className="col-md-4" style={{ display: 'none' }}>

                                <fieldset className="form-group">
                                    <label className="form-label">T.E.S</label>
                                    <input type="text" className="form-control" value={tes} maxLength={50} onChange={event => setTes(event.target.value)}  disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div> 
                            <div className="col-md-9">

                                <fieldset className="form-group">
                                    <label className="form-label">Gestor do contrato *</label>
                                    <input type="text" className="form-control" value={nomeGestorContrato} maxLength={255} onFocus={() => {setResultadoGestorContrato();setNomeMarcado(false)}}  onChange={event => setNomeGestorContrato(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                    {resultadoGestorContrato}
                                </fieldset>

                            </div>

                            <div className="col-md-3" >

                                <fieldset className="form-group">
                                    <label className="form-label">CPF Gestor do contrato *</label>
                                    <InputMask mask="999.999.999-99" type="text" className="form-control" value={cpfGestorContrato} onChange={event => setCpfGestorContrato(event.target.value)} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                </fieldset>

                            </div>  
                            <div className="col-md-12" >
                                
                                
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError">Item Contábil *</label>
                                    <textarea type="text" placeholder='Busque pelo Item Contábil...' className="form-control" style={{ backgroundColor: colorItemContabil }} value={itemContabilDescri} onChange={event => setItemContabilDescri(event.target.value)} onFocus={() => {setItemContabil();setItemContabilDescri('')}} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                    {resultadoItemContabil}
                                    
                                </fieldset>

                            </div>  
                            <div className="col-md-12" >
                                
                                
                                <fieldset className="form-group">
                                    <label className="form-label" for="exampleError">Centro de Custo *</label>
                                    <textarea type="text" placeholder='Busque pelo Centro de Custo...' className="form-control" style={{ backgroundColor: colorCentroCusto }} value={centroCustoDescri} onChange={event => setCentroCustoDescri(event.target.value)} onFocus={() => {setCentroCusto_id();setCentroCustoDescri('');setCentroCusto('')}} disabled={ demandaStatus_id == 10 || demandaStatus_id == 11 ? false : false} />
                                    {resultadoCentroCusto}
                                    
                                </fieldset>

                            </div>  
                            
                            <div className="col-md-12">

                                <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()} >Salvar informações para Atestado de NF</button>

                            </div>

                        </div>

                            
                        </p>
                    </div>
                </section>
            </div>

            

            <div class="col-md-12">
                <br/><br/>
            </div>

        </div>

    )

}


export default ComprasAtestadoNF