import React, { createContext, useState, useContext } from "react";

const totalVinculoPendenteContext = createContext();

export default function AmbienteProvider({ children }) {
  const [totalVinculoPendente, setTotalVinculoPendente] = useState();

  return (
    <totalVinculoPendenteContext.Provider value={{ totalVinculoPendente, setTotalVinculoPendente }} >
      {children}
    </totalVinculoPendenteContext.Provider>
  );
}

export function useTotalVinculoPendenteContext() {
  const context = useContext(totalVinculoPendenteContext);
  //if (!context) throw new Error("useCount must be used within a CountProvider");
  const { totalVinculoPendente, setTotalVinculoPendente } = context;
  return { totalVinculoPendente, setTotalVinculoPendente };
}

