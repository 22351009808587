import { useEffect, useState, PureComponent } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import api from '../components/api'
import { fmtMoney, sleep } from '../functions/generalFunctions'

//import CurrencyInput from '../components/CurrencyInput';
import CurrencyInput from '../components/CurrencyInput'
import axios from 'axios'


const Orcamento = () => {

    //const linkAPI = 'http://172.16.1.253:3378/'
    //const linkAPI = 'https://api-faj.koderia.com.br/'
    const linkAPI = 'http://192.168.255.165:3378/'



    const anoAtual = parseInt(moment().year() + 1)
    const usuario_id = sessionStorage.getItem('usuario_id')

    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())
    const [fluxoCaixa, setFluxoCaixa] = useState('')
    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState([])
    const [tipo, setTipo] = useState('')
    
    const [resultado, setResultado] = useState([])


    const [valorJan, setValorJan] = useState('')
    const [valorFev, setValorFev] = useState('')
    const [valorMar, setValorMar] = useState('')
    const [valorAbr, setValorAbr] = useState('')
    const [valorMai, setValorMai] = useState('')
    const [valorJun, setValorJun] = useState('')
    const [valorJul, setValorJul] = useState('')
    const [valorAgo, setValorAgo] = useState('')
    const [valorSet, setValorSet] = useState('')
    const [valorOut, setValorOut] = useState('')
    const [valorNov, setValorNov] = useState('')
    const [valorDez, setValorDez] = useState('')



    const [valorJanAnt, setValorJanAnt] = useState('')
    const [valorFevAnt, setValorFevAnt] = useState('')
    const [valorMarAnt, setValorMarAnt] = useState('')
    const [valorAbrAnt, setValorAbrAnt] = useState('')
    const [valorMaiAnt, setValorMaiAnt] = useState('')
    const [valorJunAnt, setValorJunAnt] = useState('')
    const [valorJulAnt, setValorJulAnt] = useState('')
    const [valorAgoAnt, setValorAgoAnt] = useState('')
    const [valorSetAnt, setValorSetAnt] = useState('')
    const [valorOutAnt, setValorOutAnt] = useState('')
    const [valorNovAnt, setValorNovAnt] = useState('')
    const [valorDezAnt, setValorDezAnt] = useState('')



    const [existente, setExistente] = useState(false)



    const [justificativa, setJustificativa] = useState('')
    const [justificativa_id, setJustificativaId] = useState()
    const [aberto, setAberto] = useState(true)

    const [file, setFile] = useState()
    const [arquivo, setArquivo] = useState('')

    //const mesAtual = moment().month() + 1



    function carregaAnos() {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }

    const carregaParametrosPreenchimento = () => {


        api.get('dataAjusteOrcamento').then((result) => {

            //console.log(result.data[0].status)
            setAberto(result.data[0].status)

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const carregaFluxoCaixa = () => {

        var dataPost = {

            usuario_id: usuario_id,
            tipo: tipo,

        }

        api.post('usuarioFluxoCaixaTipo', dataPost).then((result) => {

            //console.log(result.data)
            //return false

            var data = result.data

            setResultadoFluxoCaixa(data.map((rs) =>

                    <option value={rs.fluxoCaixa}>{rs.fluxoCaixa}</option>

            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const salvaNovoOrcamento = (metaSaldo_id, valorOrcamento) => {

        var dataPost = {

            metaSaldo_id: metaSaldo_id,            
            valorOrcamento: valorOrcamento.replaceAll('.', '').replaceAll(',', '.'),

        }

        //console.log(dataPost)

        api.put('metaSaldoNovo', dataPost).then((result) => {

            //carregaGrafico()
            //window.toastr.success('Salvo com sucesso!')

        }).catch((err) => {

            console.log(err)

        })

    }


    const carregaJustificativa = () => {

        if (ano && fluxoCaixa && tipo) {

            var dataPost = {

                ano: ano,
                fluxoCaixa: fluxoCaixa,
                tipo: tipo,
    
            }
    
            api.post('justificativaMetaSaldoShow', dataPost).then((result) => {

                //console.log(result.data)
                
                setJustificativaId(result.data[0].justificativa_id)
                setJustificativa(result.data[0].justificativa)
                setArquivo(result.data[0].arquivo)
                //setFile(result.data[0].arquivo)
                
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        

    }


    const carregaDados = () => {

        if (ano != '' && fluxoCaixa != '' && tipo != '') {

            var dataPost = {

                ano: ano,
                fluxoCaixa: fluxoCaixa,
                tipo: tipo,

            }


            //console.log('aqui')


            api.post('orcamentoPreviaMostra', dataPost).then((result) => {

                var data = result.data

                //console.log(data)

                if (data.length > 0) { setExistente(true) }

                for (var k = 0; k < data.length; k++) {


                    switch (data[k].mes) {


                        case 1:

                            setValorJan(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            //result.data[3].totalOrcamentoAno ? fmtMoney(result.data[3].totalOrcamentoAno) : 0
                            break;

                        case 2:

                            setValorFev(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 3:

                            setValorMar(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 4:

                            setValorAbr(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 5:

                            setValorMai(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 6:

                            setValorJun(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 7:

                            setValorJul(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;


                        case 8:

                            setValorAgo(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        
                        case 9:

                            setValorSet(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 10:

                            setValorOut(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 11:

                            setValorNov(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                        case 12:

                            setValorDez(data[k].valor ? fmtMoney(data[k].valor, 2).replaceAll('R$', '') : null)
                            break;

                    }


                }


            }).catch((err) => {

                console.log(err.response)

            })


        }

        api.get()


    }


    const salva = () => {




        var dataPost = {

            ano: ano,
            tipo: tipo,
            fluxoCaixa: fluxoCaixa,
            valorJan: valorJan != null && valorJan != '' ? fmtMoney(valorJan, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorFev: valorFev != null && valorFev != '' ? fmtMoney(valorFev, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorMar: valorMar != null && valorMar != '' ? fmtMoney(valorMar, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorAbr: valorAbr != null && valorAbr != '' ? fmtMoney(valorAbr, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorMai: valorMai != null && valorMai != '' ? fmtMoney(valorMai, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorJun: valorJun != null && valorJun != '' ? fmtMoney(valorJun, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorJul: valorJul != null && valorJul != '' ? fmtMoney(valorJul, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorAgo: valorAgo != null && valorAgo != '' ? fmtMoney(valorAgo, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorSet: valorSet != null && valorSet != '' ? fmtMoney(valorSet, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorOut: valorOut != null && valorOut != '' ? fmtMoney(valorOut, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorNov: valorNov != null && valorNov != '' ? fmtMoney(valorNov, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,
            valorDez: valorDez != null && valorDez != '' ? fmtMoney(valorDez, 2).toString().replaceAll('R$', '').replaceAll('.', '').replaceAll(',', '.') : null,

        }

        console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma os dados?',
		    buttons: {
		        Sim: function () {


                        salvaJustificativa()
                                    

                        if (existente == false) {


                            api.post('orcamentoPrevia', dataPost).then((result) => {

                                console.log(result.data)

                                window.toastr.success('Registro salvo com sucesso!')

                            }).catch((err) => {
                    
                                console.log(err.response)
                    
                            })


                        } else {

                            
                            
                            api.put('orcamentoPrevia', dataPost).then((result) => {

                                console.log(result.data)
                                window.toastr.success('Registro salvo com sucesso!')



                            }).catch((err) => {
                    
                    
                    
                            })

                        }


                    },
                    Cancelar: function () {
                        //$.alert('Canceled!');
                    }
    
                },
    
            });

      


    }



    const salvaJustificativa = () => {


        if (justificativa == '') {

            //window.toastr.error('<b>Justificativa</b> não pode ficar em branco!')
            //return false

        }

        /*
        var dataPost = {

            ano: ano,
            fluxoCaixa: fluxoCaixa,
            tipo: tipo,
            justificativa: justificativa,

        }
        */

        //console.log(dataPost)
        


        
        const formData = new FormData();
        const imagefile = document.querySelector("#arquivoJustificativa");


        // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
        
        formData.append("justificativa", justificativa); // string junto ao formData.
        formData.append("fluxoCaixa", fluxoCaixa); // string junto ao formData.
        formData.append("ano", ano); // string junto ao formData.
        formData.append("tipo", tipo); // string junto ao formData.
        formData.append("arquivo", imagefile.files[0]);


        if (justificativa_id) {

            /*

            api.put(`justificativaMetaSaldo/${justificativa_id}`, dataPost).then((result) => {

                console.log(result.data)
                window.toastr.success('Salvo com sucesso!')

            }).catch((err) => {

                console.log(err.response)

            })
            */


            axios.put(`${linkAPI}justificativaMetaSaldo/${justificativa_id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-access-token': '!nf0$@ud#2',
            }
            }).then(response => {

                console.log(response.data)
                if (response.data.arquivo) { setArquivo(response.data.arquivo) }
                
                document.getElementById('arquivoJustificativa').value = ''
                setFile()
                window.toastr.success('Registro salvo com sucesso!')                            



            })



        } else {

            /*

            api.post('justificativaMetaSaldo', dataPost).then((result) => {

                
                if (result.data) {

                    setJustificativaId(result.data[0].justificativa_id)
                    window.toastr.success('Salvo com sucesso!')

                }

            }).catch((err) => {

                console.log(err.response)

            })

            */



            axios.post(`${linkAPI}justificativaMetaSaldo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'x-access-token': '!nf0$@ud#2',
                }
                }).then(response => {

                    console.log(response.data)
                    if (response.data.arquivo) { setArquivo(response.data.arquivo) }
                    setJustificativaId(response.data.justificativa_id)
                    document.getElementById('arquivoJustificativa').value = ''
                    setFile()
                    window.toastr.success('Registro salvo com sucesso!')                            



                })



        }

        
                    

              

    }


    const carregaOrcamentoAnterior = () => {


        if (ano && tipo && fluxoCaixa) {

            var dataPost = {

                ano: ano,
                tipo: tipo,
                fluxoCaixa: fluxoCaixa,
    
            }
    
            
    
            api.post('metaSaldoAnoFluxo', dataPost).then((result) => {
    
                //console.log(result.data)

                var data = result.data

                for (var k = 0; k < data.length; k++) {


                    switch (data[k].mes) {


                        case 1:

                            setValorJanAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            //result.data[3].totalOrcamentoAno ? fmtMoney(result.data[3].totalOrcamentoAno) : 0
                            break;

                        case 2:

                            setValorFevAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 3:

                            setValorMarAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 4:

                            setValorAbrAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 5:

                            setValorMaiAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 6:

                            setValorJunAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 7:

                            setValorJulAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;


                        case 8:

                            setValorAgoAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        
                        case 9:

                            setValorSetAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 10:

                            setValorOutAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 11:

                            setValorNovAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                        case 12:

                            setValorDezAnt(data[k].valor ? fmtMoney(data[k].valor, 2) : null)
                            break;

                    }


                }
            
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        

    }


    async function exportaExcel() {


  
        await api.get('exportaExcelOrcamentoPrevia').then((result) => {
  
            console.log(result.data)
  
            if (result.data.status == 'ok') {

              
  
                sleep(1000).then(() => {
  
                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                
  
            }
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
      }



    const limpa = () => {

        setValorJan('')
        setValorFev('')
        setValorMar('')
        setValorAbr('')
        setValorMai('')
        setValorJun('')
        setValorJul('')
        setValorAgo('')
        setValorSet('')
        setValorOut('')
        setValorNov('')
        setValorDez('')


        setValorJanAnt('')
        setValorFevAnt('')
        setValorMarAnt('')
        setValorAbrAnt('')
        setValorMaiAnt('')
        setValorJunAnt('')
        setValorJulAnt('')
        setValorAgoAnt('')
        setValorSetAnt('')
        setValorOutAnt('')
        setValorNovAnt('')
        setValorDezAnt('')

        setArquivo('')
        setJustificativa('')
        setJustificativaId()

    }



    useEffect(() => {

        limpa()
        carregaAnos()
        carregaParametrosPreenchimento()

    }, [])

    useEffect(() => {
        
        limpa()
        setResultado()
        carregaFluxoCaixa()
        setFluxoCaixa('')

    }, [tipo])

    useEffect(() => {

        
        limpa()
        //setResultadoX()
        setResultado()
        
        carregaDados()
        carregaOrcamentoAnterior()
        //carregaGrafico()
        carregaJustificativa()

    }, [tipo, ano, fluxoCaixa])


    return (


        <div>

            <h5 className="m-t-lg with-border">Orçamento</h5>

            <div className="row">
                <div className="col-md-3 col-sm-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-3 col-sm-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Tipo</label>
                        <select type="text" className="form-control" value={tipo} onChange={event => setTipo(event.target.value)}>
                            <option value="">[Selecione]</option>
                            <option value="RECEITAS">RECEITAS</option>
                            <option value="DESPESAS">DESPESAS</option>
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-4 col-sm-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Fluxo de Caixa</label>
                        <select type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)}>
                            <option value="">[Selecione]</option>
                            {resultadoFluxoCaixa}
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-2 col-sm-2">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">&nbsp;</label>
                        
                        <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} disabled={ sessionStorage.getItem('perfil') == 'Administrador' ? false : true }>Exportar XLS</button>
                        
                    </fieldset>
                </div>

            </div>
            
            
                    
            <br/><br/>

            <div style={{ display: fluxoCaixa != '' && tipo != '' ? 'block' : 'none'}}>


                <div className="col-md-12 col-sm-12" style={{ display: fluxoCaixa != '' && tipo != '' ? 'block' : 'none'}}>


                    <table id="table-sm" class="table table-bordered table-hover table-sm">
                        <thead>
                            <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                <th style={{ fontSize: '11px', width: '100px', textAlign: 'center'}}>
                                    Mês
                                </th>
                                <th style={{ textAlign: 'center'}}>
                                    
                                    Orçamento Atual
                                </th>
                                <th>
                                    Novo orçamento
                                </th>
                                                            
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Janeiro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorJanAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" value={valorJan} onChange={event => setValorJan(event.target.value)} className='form-control' placeholder='0,00' />
                                    

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Fevereiro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorFevAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorFev} onChange={event => setValorFev(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Março
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorMarAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorMar} onChange={event => setValorMar(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Abril
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorAbrAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorAbr} onChange={event => setValorAbr(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Maio
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorMaiAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorMai} onChange={event => setValorMai(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Junho
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorJunAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorJun} onChange={event => setValorJun(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Julho
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorJulAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorJul} onChange={event => setValorJul(event.target.value)} placeholder='0,00' />

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Agosto
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorAgoAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorAgo} onChange={event => setValorAgo(event.target.value)} placeholder='0,00'/>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Setembro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorSetAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorSet} onChange={event => setValorSet(event.target.value)} placeholder='0,00'/>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Outubro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorOutAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorOut} onChange={event => setValorOut(event.target.value)} placeholder='0,00'/>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Novembro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorNovAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text" className='form-control' value={valorNov} onChange={event => setValorNov(event.target.value)} placeholder='0,00'/>

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Dezembro
                                </td>
                                <td style={{ textAlign: 'right'}}>
                                    {valorDezAnt}
                                </td>
                                <td>
                                    
                                    <CurrencyInput type="text"  className='form-control' value={valorDez} onChange={event => setValorDez(event.target.value)} placeholder='0,00'/>

                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br/>

                </div>

                <div className="col-md-12" style={{ display: fluxoCaixa != '' && tipo != '' ? 'block' : 'none'}}>
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Arquivo</label>
                        
                        <input type="file" className="form-control" id="arquivoJustificativa" value={file} onChange={event => setFile(event.target.value)} />
                        <div style={{ display: arquivo != '' && arquivo != null ? 'block' : 'none'}} >Arquivo atual: <Link to={`${linkAPI}upload/${arquivo}`} >{arquivo}</Link>
                        </div>
                        
                    </fieldset>
                </div>

                <div className="col-md-12" style={{ display: fluxoCaixa != '' && tipo != '' ? 'block' : 'none'}}>
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Justificativa</label>
                        <textarea className="form-control" value={justificativa} onChange={event => setJustificativa(event.target.value)} maxLength={1000} placeholder='Max. 1.000 caracteres'/>
                            
                        
                    </fieldset>
                </div>

                <div className="col-md-12" style={{ display: fluxoCaixa != '' && tipo != '' ? 'block' : 'none'}}>

                    <br/>
                    <center>
                    <button type="button" class="btn btn-inline btn-primary" onClick={() => salva()} disabled={ aberto == false ? true : false }>Salvar</button>
                    </center>
                    

                </div>
                

            </div>

        </div>

    )

}


export default Orcamento