

import FluxoCaixaMensal from '../componentsPages/FluxoCaixaMensal'
import DemonstrativoFinanceiro from '../componentsPages/DemonstrativoFinanceiro'
import DemonstrativoFinanceiroDetalhado from '../componentsPages/DemonstrativoFinanceiroDetalhado'

const FluxoCaixaDiario = () => {



	if (sessionStorage.getItem('perfil') == 'Gestor') {


		return (

			<div>

				<h5 className="m-t-lg with-border">Financeiro</h5>
							

				<FluxoCaixaMensal />


			</div>

		)

	} else {


		return (

			
			<div>


				<h5 className="m-t-lg with-border">Financeiro</h5>

				<section className="tabs-section" >
					<div className="tabs-section-nav tabs-section-nav-icons" >
						<div className="tbl" >
							<ul className="nav" role="tablist" >


									<li className="nav-item " >
										<a className="nav-link active" href="#tabs-1-tab-3" role="tab" data-toggle="tab" >
											<span className="nav-link-in active">
											<i className="font-icon font-icon-home"></i>
												Fluxo de Caixa 
											</span>
										</a>
									</li>
									<li className="nav-item" >
										<a className="nav-link" href="#tabs-1-tab-4" role="tab" data-toggle="tab" >
											<span className="nav-link-in">
												
											<i className="font-icon font-icon-pencil"></i>
											
												<font color="brown">Demonstrativo Financeiro</font>
											</span>
										</a>
									</li>
									<li className="nav-item" >
										<a className="nav-link" href="#tabs-1-tab-5" role="tab" data-toggle="tab" >
											<span className="nav-link-in">
												
											<i className="font-icon font-icon-zigzag"></i>
											
												<font color="#5f3b6e">Demonstrativo Analítico</font>
											</span>
										</a>
									</li>
								
								
							</ul>
						</div>
					</div>

					
						<div class="tab-content" >
							
							
							
							<div role="tabpanel" className={`tab-pane fade in active show`}  id="tabs-1-tab-3">

								<FluxoCaixaMensal />

							</div>

							<div role="tabpanel" className={ sessionStorage.getItem('perfil') != 'Gestor' ? `tab-pane fade` : `tab-pane fade in active show`}  id="tabs-1-tab-4">

								<DemonstrativoFinanceiro />

							</div>
							<div role="tabpanel" className={ sessionStorage.getItem('perfil') != 'Gestor' ? `tab-pane fade` : `tab-pane fade in active show`}  id="tabs-1-tab-5">

								<DemonstrativoFinanceiroDetalhado />

							</div>

						</div>
					
					
				</section>

				


			</div>

		)

	}

}


export default FluxoCaixaDiario