
import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'


const ItemProcesso = (props) => {


    const item_id = props.item_id
    const ano = props.ano

    const [resultado, setResultado] = useState([])

    const [totalQtde, setTotalQtde] = useState(0)
    const [mediaValorUnit, setMediaValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)


    const carrega = () => {

        if (item_id && ano) {


            api.get(`itemProcessos/${item_id}/${ano}`).then((result) => {

                //var dataTotais = result.data[1][0]
                
                //setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
                //setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
                //setValorTotal(fmtMoney(dataTotais.valorTotal, 2))
    
                //console.log(result.data)
    
                var data = result.data
    
                setResultado(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px'}}>
                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.processo}
                            
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}
                            
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.valorPesqPreco == null ? '0' : fmtMoney(rs.valorPesqPreco, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.valorTotalPrevisto == null ? '0' : fmtMoney(rs.valorTotalPrevisto, 2)}
    
                        </td>
                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.dataInicio}
    
                        </td>
                       
                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.modalidade}
    
                        </td>
                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.dataPregao}
    
                        </td>
                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

            

        }

        

    }


    useEffect(() => {

        carrega()

    }, [item_id])

    return (

        <div>

                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    <thead>
                            <tr style={{ textAlign: 'center'}}>
                                
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Processo

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Qtde

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Pesq. Preço

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Total Previsto

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Data Início

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Modalidade

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Data Pregão

                                </th>
                                
                               
                            </tr>
                            
                    </thead>
                    <tbody>
                    {resultado}
                    </tbody>
                    
                </table>



        </div>
    )
}

export default ItemProcesso