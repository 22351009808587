import { useEffect, useState } from "react"
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney } from '../functions/generalFunctions'
import api from "../components/api"


const Modal = (props) => {

    const processo_id = props.processo_id
    

    const [siafisicoPregao, setSiafisicoPregao] = useState('')    
    const [descricao, setDescricao] = useState('')
    const [item_id, setItem_id] = useState('')
    const [processo, setProcesso] = useState('')
    const [quantidade, setQuantidade] = useState('')
    const [valorPesqPreco, setValorPesqPreco] = useState('')
    const [valorTotalPrevisto, setValorTotalPrevisto] = useState('')
    const [modalidade, setModalidade] = useState('')

    const [listaModalidadesProcesso, setListaModalidadesProcesso] = useState([])

    const salva = () => {

        
        if (item_id == '') {

            window.toastr.error('<b>Siafisico</b> não pode ficar em branco!')
            return false

        }

          
        if (processo == '') {

            window.toastr.error('<b>Processo</b> não pode ficar em branco!')
            return false

            
        }

        if (quantidade == '') {

            window.toastr.error('<b>Quantidade</b> não pode ficar em branco!')
            return false

        }

        if (valorPesqPreco == '') {

            window.toastr.error('<b>Valor Pesquisa de Preço</b> não pode ficar em branco!')
            return false

        }


        if (modalidade == '') {

            window.toastr.error('<b>Modalidade</b> não pode ficar em branco!')
            return false

        }



        var dataPost = {

            item_id: item_id,
            processo: processo,
            quantidade: quantidade,
            valorPesqPreco: valorPesqPreco,
            modalidade: modalidade,

        }

        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {



                    if (processo_id) {


                            
                        api.put(`processo/${processo_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            //limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaProcessos()
                            window.$('.modal-Processo').modal('hide')
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })




                    } else {



                        api.post(`processo`, dataPost).then((result) => {

                            //console.log(result.data)

                            //limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaProcessos()
                            window.$('.modal-Processo').modal('hide')
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    }


                              

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


        


    }

    const limpa = () => {

        setSiafisicoPregao('')
        setItem_id('')
        setDescricao('')
        setProcesso('')
        setQuantidade('')
        setValorPesqPreco('')
        setValorTotalPrevisto('')
        setModalidade('')

    }

    const infoItem = () => {

        if (siafisicoPregao != '') {

            api.get(`infoItem/${siafisicoPregao}`).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setItem_id(result.data[0].item_id)
                    

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')

            })

        }
        
    }



    const carrega = () => {

        if (processo_id) {


            api.get(`processo/${processo_id}`).then((result) => {

                //console.log(result.data)

                const data = result.data[0]
                
                setSiafisicoPregao(data.siafisicoPregao)                
                setDescricao(data.descricao)
                setItem_id(data.item_id)
                setProcesso(result.data[0].processo)
                setQuantidade(result.data[0].quantidade)
                setValorPesqPreco(result.data[0].valorPesqPreco)
                setValorTotalPrevisto(result.data[0].valorTotalPrevisto)
                setModalidade(result.data[0].modalidade)

            }).catch((err) => {


                console.log(err.response)

            })

        }



    }

    const carregaModalidades = () => {


        api.get(`modalidadesProcesso`).then((result) => {

            setListaModalidadesProcesso(result.data.map((rs) => 
            
                <option value={rs.modalidadeProcesso}>{rs.modalidadeProcesso}</option>
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const apaga = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o processo?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`processo/${processo_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-Processo').modal('hide')
                                props.carregaProcessos()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    useEffect(() => {

        limpa()
        carrega()
        carregaModalidades()

    }, [processo_id])


    return (


        <div>

                <div class={`modal fade modal-Processo`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Processo {processo_id}</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Siafisico</label>
                                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => setSiafisicoPregao(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        onBlur={() => infoItem()}
                                        />
                                    </fieldset>
                                </div>
                                
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descrição</label>
                                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                                    </fieldset>
                                </div>

                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Processo</label>
                                        <input type="text" className="form-control" value={processo} onChange={event => setProcesso(event.target.value)} />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Modalidade</label>
                                        <select className="form-control" value={modalidade} onChange={event => setModalidade(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            {listaModalidadesProcesso}
                                        </select>
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Quantidade</label>
                                        <input type="text" className="form-control" value={quantidade} onChange={event => setQuantidade(event.target.value)} 
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} 
                                        />
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Pesquisa de Preço</label>
                                        <CurrencyInput type="text" className="form-control" value={valorPesqPreco} onChange={event => setValorPesqPreco(event.target.value)} placeholder="0,00" />
                                    </fieldset>
                                </div>

                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Valor Total Previsto</label>
                                        <CurrencyInput type="text" className="form-control" value={valorTotalPrevisto} onChange={event => setValorTotalPrevisto(event.target.value)} placeholder="0,00" />
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apaga()} style={{ display: processo_id ? 'table-row' : 'none' }} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal