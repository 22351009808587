import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'


const ItemPlanej = (props) => {


    const item_id = props.item_id
    const [resultado, setResultado] = useState([])

    const [totalQtde, setTotalQtde] = useState(0)
    const [mediaValorUnit, setMediaValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)


    const carrega = () => {

        api.get(`empenhoItem/${item_id}`).then((result) => {

            var dataTotais = result.data[1][0]
            
            setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
            setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
            setValorTotal(fmtMoney(dataTotais.valorTotal, 2))


            var data = result.data[0]

            setResultado(data.map((rs) => 
            
                <tr style={{ fontSize: '11px'}}>
                    <td style={{ textAlign: 'center'}}>
                        
                        {rs.dataEmissao}
                        
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        
                        {rs.empenho}
                        
                    </td>
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.valorUnit == null ? '0' : fmtMoney(rs.valorUnit, 2)}

                    </td>
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.valorTotal == null ? '0' : fmtMoney(rs.valorTotal, 2)}

                    </td>
                   
                    <td style={{ textAlign: 'left'}}>
                        
                        {rs.fornecedor}

                    </td>
                    
                </tr>
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

        carrega()

    }, [item_id])

    return (

        <div>

                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    <thead>
                            <tr style={{ textAlign: 'center'}}>
                                
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Data Emissão

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Empenho

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Qtde

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Valor Unit.

                                </th>
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Valor Total

                                </th>
                                
                               
                                <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    Fornecedor

                                </th>

                            </tr>
                            
                    </thead>
                    <tbody>
                    {resultado}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                            <th colspan="2">
                                Total
                            </th>
                            <th>
                                {totalQtde}
                            </th>
                            <th>
                                {mediaValorUnit}
                            </th>
                            <th>
                                {valorTotal}
                            </th>
                            <th colspan="2">
                                &nbsp;
                            </th>
                        </tr>
                    </tfoot>
                </table>



        </div>
    )
}

export default ItemPlanej