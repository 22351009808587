import { useEffect, useState, PureComponent } from 'react'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</p>
          
        </div>
      );
    }
  
    return null;
  };

class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
  
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          {value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </text>
      );
    }
  }



const ModalResultadoAno = (props) => {

    var ano = props.ano
    var mes = props.mes

    const [resultado, setResultado] = useState([])
    const [dataChart, setDataChart] = useState([])
    const [resultadoTotal, setResultadoTotal] = useState(0)


    const consulta = () => {


        api.get(`totalReceitasMes/${ano}/${mes}`).then((result) => {

            //console.log(result.data[0])

            var data = result.data[0]
            
            setResultadoTotal(result.data[1].totalResultado)

            setResultado(data.map((rs) =>
            
                <tr style={{ fontSize: '11px'}} >

                    <td>
                        <center>
                        {rs.mesNome}
                        </center>
                    </td>
                    <td style={{ textAlign: 'right', color: rs.resultado >= 0 ? 'blue' : 'red' }}>
                        
                        {fmtMoney(rs.resultado)}

                    </td>

                </tr>
            
            ))


            setDataChart(data.map((rs) => {

                return {

                    name: rs.mesNome,
                    total: rs.resultado,
                    
                }

            }
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {

        consulta()

    }, [ano,  mes])


    return (

        <div>


                <div class="modal fade modal-FluxoCaixaResultadoAno"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Fluxo Caixa {ano} - RESULTADO</h4>
                        </div>
                        <div class="modal-body">

                            <table id="table-sm" class="table table-bordered table-hover table-sm">
                                <thead>
                                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        <th>
                                            Mês
                                        </th>
                                        <th>
                                            Resultado
                                        </th>
                                        
                                        
                                    </tr>
                                </thead>
                                <tbody>

                                   {resultado}   
                                   
                                <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF', color: resultadoTotal >= 0 ? 'blue' : 'red' }}>
                                    <th >
                                        <center>
                                        Total
                                        </center>
                                    </th>
                                    <th>
                                        <div align="right">
                                        {fmtMoney(resultadoTotal)}
                                        </div>
                                    </th>
                                    
                                </tr>     
                                </tbody>


                            </table>

                            <br/>
                            <br/>

                            <ResponsiveContainer width="100%" height={200}>

                                    

                                <LineChart data={dataChart} margin={{ top: 5, right: 20, bottom: 5, left: 0 } }>
                                    <Line type="monotone" dataKey="total" stroke="#8884d8" label={<CustomizedLabel />} />
                                    <Line type="monotone" dataKey="meta" stroke="#eb4034" />

                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" style={{ fontSize: '11px', fontFamily: 'Arial' }}
                                    
                                    
                                    />
                                    <YAxis style={{ fontSize: '10px', fontFamily: 'Arial' }} 
                                    tick={false}
                                    //hide={true}
                                    //tickFormatter={tick => {return tick.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });}}
                                    />
                                    
                                    
                                    
                                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                </LineChart>


                            </ResponsiveContainer>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )

}

export default ModalResultadoAno