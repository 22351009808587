import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../components/api'
import moment from 'moment'
import { sleep } from '../functions/generalFunctions'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';

import "../components/styles.css";

registerLocale('ptBR', ptBR)



const DemonstrativoFinanceiroDetalhado = () => {


    var dataInicio = `${moment().format('YYYY')}-01-01`

    var dataInicio = moment(dataInicio, 'YYYY-MM-DD').toDate()
    

    const [startDate, setStartDate] = useState(dataInicio);
    const [endDate, setEndDate] = useState(new Date())

    const [dataUltimoMovimento, setDataUltimoMovimento] = useState()

    const [tipoDemonstrativo, setTipoDemonstrativo] = useState('T')
    const [resultado, setResultado] = useState([])


    const [itemContabil, setItemContabil] = useState()
    const [fluxoCaixa, setFluxoCaixa] = useState('')
    const [codigoNatureza, setCodigoNatureza] = useState('')
    const [area, setArea] = useState('')

    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState()

    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [colorItemContabil, setColorItemContabil] = useState('white')
    const [resultadoItemContabil, setResultadoItemContabil] = useState('')

    const [resultadoNatureza, setResultadoNatureza] = useState()
    const [naturezaDescri, setNaturezaDescri] = useState('')
    const [colorNatureza, setColorNatureza] = useState('white')

    const [resultadoArea, setResultadoArea] = useState()
    const [total, setTotal] = useState(0)
    const [totalReceitas, setTotalReceitas] = useState(0)
    const [totalDespesas, setTotalDespesas] = useState(0)

    const consultaDataUltimoMovimento = () => {


        api.get('listaDataMovimento').then((result) => {

            //console.log(result.data)      
            setDataUltimoMovimento(result.data[0].dataMovimento)      

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

        if (itemContabil) {

            setColorItemContabil('orange')

        } else {

            setColorItemContabil('white')

        }
        

    }, [itemContabil])



    useEffect(() => {

        if (codigoNatureza) {

            setColorNatureza('orange')

        } else {

            setColorNatureza('white')

        }
        

    }, [codigoNatureza])



    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (itemContabilDescri != '' && itemContabil == null) {

            var dataPost = {

                itemContabilDescri: itemContabilDescri,

            }

            console.log(dataPost)

            api.post('buscaItemContabil', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoItemContabil(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoItemContabil('');setItemContabil(rs.itemContabil);setItemContabilDescri(`${rs.itemContabil} - ${rs.itemContabilDescri}`)}}><font color='purple'>[{rs.itemContabil}]</font> - {rs.itemContabilDescri}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemContabilDescri])




    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (naturezaDescri != '' && codigoNatureza == null) {

            var dataPost = {

                naturezaDescri: naturezaDescri,

            }

            //console.log(dataPost)

            api.post('buscaNatureza', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoNatureza(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoNatureza('');setCodigoNatureza(rs.codigoNatureza);setNaturezaDescri(`${rs.codigoNatureza} - ${rs.naturezaDescri}`)}}><font color='purple'>[{rs.codigoNatureza}]</font> - {rs.naturezaDescri}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [naturezaDescri])



    const exportaExcel = async () => {




        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            area: area,
            fluxoCaixa: fluxoCaixa,

        }

        await api.post('demonstrativoFinanceiroDetalheXLS', dataPost).then((result) => {

            console.log(result.data)
            window.toastr.info('Aguarde...')

            sleep(3000).then(() => {
            
                window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)

            })

            
           
            

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const atualiza = () => {

        
        //setando data1
        var data1 = moment(startDate,'YYYY-MM-DD');
        //setando data2
        var data2 = moment(endDate,'YYYY-MM-DD');
        //tirando a diferenca da data2 - data1 em dias
        var diff  = data2.diff(data1, 'days');

        if (diff < 0) {

            window.toastr.error('Data início não pode ser maior que a data fim!')
            return false

        }


        var dataPost = {

            tipoDemonstrativo: tipoDemonstrativo,
            dataInicio: moment(startDate).format('YYYY-MM-DD'),
            dataFim: moment(endDate).format('YYYY-MM-DD'),
            itemContabil: itemContabil,
            codigoNatureza: codigoNatureza,
            area: area,
            fluxoCaixa: fluxoCaixa,

        }

        api.post('demonstrativoFinanceiroDetalhe', dataPost).then((result) => {

            //console.log(result.data)
            setResultado(result.data[0].map((rs) =>
            
                <tr style={{ fontSize: '11px'}}>

                    <td>{rs.codigoNatureza}</td>
                    <td>{rs.natureza}</td>
                    <td>{rs.itemConta}</td>
                    <td>{rs.tipo}</td>
                    <td>{rs.fluxoCaixa}</td>
                    <td>{rs.area}</td>
                    <td>{rs.itemContaDescri}</td>
                    <td style={{ textAlign: 'center'}}>{rs.data}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalReceitas ? rs.totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>
                    <td style={{ textAlign: 'right'}}>{rs.totalDespesas ? rs.totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 0, maximumFractionDigits: 0, }) : '0,00'}</td>


                </tr>
            
            ))

            setTotal(result.data[1].total)
            setTotalReceitas(result.data[1].totalReceitas)
            setTotalDespesas(result.data[1].totalDespesas)
            

        }).catch((err) => {

            console.log(err.response)

        })

    }

    const listaFLuxoCaixa = () => {


        api.get('fluxoCaixa').then((result) => {

            //console.log(result.data)
            setResultadoFluxoCaixa(result.data.map((rs, key) => 
            
                <option value={rs.fluxoCaixa} key={key}>{rs.fluxoCaixa}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const listaArea = () => {

        api.post('listaAreaFiltro').then((result) => {

            
            setResultadoArea(result.data.map((rs, key) =>
            
                <option value={rs.area} key={key}>{rs.area}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    //buscaItemContabil

    useEffect(() => {

        listaFLuxoCaixa()
        listaArea()
        consultaDataUltimoMovimento()

    }, [])

    return (

        <div>

                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar XLSX </button>
                        
                <br/><br/>

                <div className="row">

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Inicial</label>
                            <DatePicker   selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2" >
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Data Final</label>
                            <DatePicker  selected={endDate} onChange={(date) => setEndDate(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA"
                            customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: '#dfe8f0'}} /> } 
                            />
                            
                        </fieldset>
                    </div>

                    <div className="col-md-3 col-sm-3" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Tipo de demonstrativo</label>
                            <select type="text" className="form-control" value={tipoDemonstrativo} onChange={event => setTipoDemonstrativo(event.target.value)}>
                                <option value="T">Total</option>
                                <option value="O">Operacionais</option>
                                <option value="E">Especiais</option>
                            </select>
                        </fieldset>
                    </div>
                    
                    <div className="col-md-4 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Fluxo de Caixa</label>
                            <select type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)} >

                                <option value="">[Selecione]</option>
                                {resultadoFluxoCaixa}


                            </select>
                                
                        </fieldset>
                    </div>
                   
                    <div className="col-md-4 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Área</label>
                            <select type="text" className="form-control" value={area} onChange={event => setArea(event.target.value)} >

                                <option value="">[Selecione]</option>
                                {resultadoArea}
                                

                            </select>
                                
                        </fieldset>
                    </div>


                    <div className="col-md-6 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Natureza</label>
                            <input type="text" placeholder='Busque pela Natureza...' className="form-control" style={{ backgroundColor: colorNatureza }} value={naturezaDescri} onChange={event => setNaturezaDescri(event.target.value)} onFocus={() => {setCodigoNatureza();setNaturezaDescri('')}} />
                            {resultadoNatureza}
                                
                        </fieldset>
                    </div>

                    <div className="col-md-6 col-sm-3">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Item Contábil</label>
                            <input type="text" placeholder='Busque pelo Item Contábil...' className="form-control" style={{ backgroundColor: colorItemContabil }} value={itemContabilDescri} onChange={event => setItemContabilDescri(event.target.value)} onFocus={() => {setItemContabil();setItemContabilDescri('')}} />
                            {resultadoItemContabil}
                              
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">Último movimento</label>
                            <input type="text" className="form-control" value={dataUltimoMovimento} disabled style={{ backgroundColor: '#f7e1c1'}} />
                                
                        </fieldset>
                    </div>

                    <div className="col-md-2 col-sm-2">
                        <fieldset className="form-group">
                            <label className="form-label" for="exampleError2">&nbsp;</label>
                            
                            <button type="button" class="btn btn-inline btn-info" onClick={() => atualiza()}>Consultar</button>

                        </fieldset>
                    </div>



                </div>


                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    
                    <thead>
                        <tr style={{ textAlign: 'right', fontSize: '11px'}}>
                            <th colspan="8"  >Total</th>
                            <th colspan="2" style={{ backgroundColor: total < 0 ? '#f7e1e6' : '#d1f0d9'}}>{total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, })}</th>
                        </tr>
                        <tr style={{ fontSize: '11px'}}>
                            <th>Cód. Natureza</th>
                            <th>Natureza</th>
                            <th>Item Contábil</th>
                            <th>Tipo</th>
                            <th>Fluxo de Caixa</th>
                            <th>Área</th>
                            <th>Item Contábil</th>
                            <th style={{ textAlign: 'center'}}>Data</th>
                            <th style={{ backgroundColor: '#afd8e0'}} ><center>Receitas</center></th>
                            <th style={{ backgroundColor: '#e0baaf'}} ><center>Despesas</center></th>
                        </tr>
                    </thead>
                    <tbody>
                        {resultado}
                    </tbody>
                    <tfoot>
                        <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                            <th colspan="8" style={{ textAlign: 'right'}}>
                                Total
                            </th>
                            <th >

                                {totalReceitas ? totalReceitas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                            </th>
                            <th  >

                                {totalDespesas ? totalDespesas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, }) : '0,00'}

                            </th>
                        </tr>
                    </tfoot>
                    
                </table>

        </div>

    )

}


export default DemonstrativoFinanceiroDetalhado