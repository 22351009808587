import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, sleep } from '../functions/generalFunctions';
import ModalModalidade from '../modal/ModalModalidade'

const ComprasModalidades = () => {

    const [resultado, setResultado] = useState([])
    
    const [modalidade_id, setModalidade_id] = useState()


    const columns = useMemo(
        () => [
          {
            accessorKey: 'modalidade',
            header: 'Modalidade',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
         

         /*
          {
            accessorKey: 'planejado',
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          */
        ],
        [],
      );


      const carregaModalidades = () => {

        api.get('modalidades').then((result) => {

            setResultado(result.data.map((rs) => {


                return {

                    modalidade_id: rs.modalidade_id,
                    modalidade: rs.modalidade,


                }


            }

                
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        carregaModalidades()

    }, [])


    return (

        <div>


            <button type="button" class="btn btn-inline btn-info" onClick={() => { window.$('.modal-Modalidade').modal('show');setModalidade_id()}} >+ Novo</button>
           
                    
           <br/><br/>


               <MaterialReactTable 
                   
                   columns={columns} 
                   data={resultado} 
                   localization={MRT_Localization_PT_BR}
                   //rowsPerPage={20}
                   //options={options}
                   initialState={{ density: 'compact' }}
                   muiTableHeadCellProps={{
                       //easier way to create media queries, no useMediaQuery hook needed.
                       sx: {
                           fontSize: {
                           xs: '8px',
                           sm: '9px',
                           md: '10px',
                           lg: '11px',
                           xl: '12px',
                           },
                       },
                       }}
                   muiTableBodyCellProps={{
                       sx: {
                           fontSize: {
                               xs: '8px',
                               sm: '9px',
                               md: '10px',
                               lg: '11px',
                               xl: '12px',
                           },
                           },
                   }}

                   muiTableBodyRowProps={({ row }) => ({
                       onClick: (event) => {
                       //console.info(event, row.original.usuario_id);
                       //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                       window.$('.modal-Modalidade').modal('show');setModalidade_id(row.original.modalidade_id)

                       },
                       sx: {
                       cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                       },
                   })}
                   
               />



            <ModalModalidade modalidade_id={modalidade_id} carregaModalidades={carregaModalidades} />


        </div>

    )

}

export default ComprasModalidades