import { useEffect, useState } from 'react'
import api from '../components/api'


const Modal = (props) => {

    const demandaSolicitante_id = props.demandaSolicitante_id

    const [solicitante, setSolicitante] = useState('')
    const [precisaAutorizaGestao, setPrecisaAutorizaGestao] = useState(false)


    const apaga = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`solicitante/${demandaSolicitante_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-DemandaSolicitante').modal('hide')
                                limpa()
                                props.carregaSolicitantes()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const salva = () => {

        if (solicitante == '') {

            window.toastr.error('<b>Solicitante</b> não pode ficar em branco!')
            return false

        }

        var dataPost = {

            solicitante: solicitante,
            precisaAutorizaGestao: precisaAutorizaGestao,
            
        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar o solicitante?',
		    buttons: {
		        Sim: function () {


                    if (demandaSolicitante_id) {

                        api.put(`solicitante/${demandaSolicitante_id}`, dataPost).then((result) => {
            
                            //console.log(result.data)
                            if (result.data.alerta == 'ok') {

                                window.toastr.success('Registro salvo com sucesso!')
                                window.$('.modal-DemandaSolicitante').modal('hide')
                                limpa()
                                props.carregaSolicitantes()

                            }
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    } else {
            
                        api.post('solicitante', dataPost).then(() => {
            

                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-DemandaSolicitante').modal('hide')
                            limpa()
                            props.carregaSolicitantes()
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    }

                    

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });
        
    }


    const carrega = () => {

        api.get(`solicitante/${demandaSolicitante_id}`).then((result) => {

            setSolicitante(result.data[0].solicitante)
            setPrecisaAutorizaGestao(result.data[0].precisaAutorizaGestao)

        }).catch((err) => {

            console.log(err.response)

        })
    }


    const limpa = () => {

        setSolicitante('')
        setPrecisaAutorizaGestao(false)

    }

    useEffect(() => {

        limpa()
        carrega()

    }, [demandaSolicitante_id])



    return (

        <div>

            <div class={`modal fade modal-DemandaSolicitante`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Solicitante</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Solicitante</label>
                                        <input type="text" className="form-control" value={solicitante} maxLength={255} onChange={event => setSolicitante(event.target.value)}/>
                                    </fieldset>
                                </div>
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Necessita de autorização da Gestão imediata</label>
                                        <select type="text" className="form-control" value={precisaAutorizaGestao} onChange={event => setPrecisaAutorizaGestao(event.target.value)}>
                                            <option value={false}>Não</option>
                                            <option value={true}>Sim</option>
                                        </select>
                                    </fieldset>
                                </div>
                            </div>



                            

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apaga()} style={{ display: demandaSolicitante_id != null ? 'table-row' : 'none' }} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default Modal