
import { useEffect, useMemo, useState } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Importacoes = () => {



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [

             
          {
            accessorKey: 'ad_new', //normal accessorKey
            header: 'Início',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          
          {
            accessorKey: 'tipo', //access nested data with dot notation
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          {
            accessorKey: 'status', //access nested data with dot notation
            header: 'Status',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            Cell: ({ renderedCellValue, row }) => (
            
                <span class={`label label-${ renderedCellValue == 'Importado' ? 'success' : renderedCellValue == 'Erro' ? 'danger' : 'warning' }`}>{renderedCellValue}</span>
           
            ),
           
          },
         
          
        ],
        [],
    );



    const consulta = () => {

        api.get('listaImportacaoTodos').then((result) => {

            var data = result.data
            
            
            setResultado(data.map((rs) => {


                return {

                    ad_new: rs.ad_new,
                    tipo: rs.tipo,
                    status: rs.status,

                }


            }))

        }).catch((err) => {


            console.log(err.response)


        })


    }




    useEffect(() => {

        consulta()

    }, [])


    return (


        <div>

            <h5 className="m-t-lg with-border">Importações</h5>

            <button type="button" class="btn btn-inline btn-primary" onClick={() => consulta()}>Atualizar status de importação</button>
            
            
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      //navigate(`/Usuario/${row.original.usuario_id}`)

                    },
                    sx: {
                      //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                
            />


        </div>


    )

}

export default Importacoes