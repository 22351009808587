import { useEffect, useState } from 'react'
import PlanejConsumoDetalhe from '../componentsPages/PlanejConsumoDetalhe'
import PlanejConsumoConsumo from '../componentsPages/PlanejConsumoConsumo'
import api from '../components/api'

const Modal = (props) => {

    const planejamento_id = props.planejamento_id
    const item_id = props.item_id
    
    const [totalConsumo, setTotalConsumo] = useState(0)



    const carregaTotalConsumo = () => {

        if (item_id) {

            api.get(`empenhoItemTotal/${item_id}`).then((result) => {

                var data = result.data[0]
                setTotalConsumo(data.total)

            }).catch((err) => {

                console.log(err.response)

            })

        }

    }


    useEffect(() => {

        window.$('[href="#tabs-4-tab-1k2"]').tab('show');
        carregaTotalConsumo()

    }, [planejamento_id])

    return (

        <div>

            <div class='modal fade modal-PlanejConsumoEdit'
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Planejamento - Item {planejamento_id}</h4>
                        </div>
                        <div class="modal-body">



                            <section className="tabs-section">
                                <div className="tabs-section-nav tabs-section-nav-inline">
                                    <ul className="nav" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#tabs-4-tab-1k2" role="tab" data-toggle="tab">
                                                Detalhes
                                            </a>
                                        </li>
                                        <li className="nav-item" style={{ display: 'none' }}>
                                            <a className="nav-link" href="#tabs-4-tab-2k2" role="tab" data-toggle="tab">
                                                Consumo&nbsp;<span class="label label-pill label-danger">{totalConsumo}</span>
                                                
                                            </a>
                                        </li>
                                        
                                        
                                    </ul>
                                </div>

                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4-tab-1k2">


                                        <PlanejConsumoDetalhe planejamento_id={planejamento_id} carregaPlanej={props.carregaPlanej} />


                                    </div>
                                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2k2">

                                        <PlanejConsumoConsumo item_id={item_id} carregaItens={props.carregaItens} />


                                    </div>
                                   
                                    
                                </div>
                            </section>


                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        
    )

}

export default Modal