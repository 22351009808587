
import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import ModalVinculoEdit from '../modal/ModalVinculoEdit'
import { useAtualizaContext } from '../context/atualizaContext'
import { fmtMoney } from '../functions/generalFunctions';

const FluxoCaixaVinculosPendentes = (props) => {

    const { dataAtualiza } = useAtualizaContext()

    const [resultado, setResultado] = useState([])
    const [vinculo_id, setVinculo_id] = useState()

    const columns = useMemo(
        () => [
          {
            accessorKey: 'codigoNatureza', //access nested data with dot notation
            header: 'Cód. Natureza',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'natureza',
            header: 'Natureza',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'itemContabil', //normal accessorKey
            header: 'Item Contábil',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'tipo',
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'fluxoCaixa',
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'area',
            header: 'Área',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            header: 'Descr. item contábil',
            accessorKey: 'itemContabilDescri',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            header: 'Entrada',
            accessorKey: 'entrada',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            header: 'Saída',
            accessorKey: 'saida',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            header: 'Data movimento',
            accessorKey: 'dataMovimento',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          
          
        ],
        [],
      );


      const carregaVinculos = () => {

        api.get('listaVinculoPendente').then((result) => {

            
            var data = result.data
            //console.log(data)
            setResultado(data.map((rs) => {

                return {

                    vinculo_id: rs.vinculo_id,
                    codigoNatureza: rs.codigoNatureza,
                    natureza: rs.natureza,
                    itemContabil: rs.itemContabil,
                    tipo: rs.tipo,
                    fluxoCaixa: rs.fluxoCaixa,
                    area: rs.area,
                    itemContabilDescri: rs.itemContabilDescri,
                    entrada: rs.entrada == null ? '' : fmtMoney(rs.entrada),
                    saida: rs.saida == null ? '' : fmtMoney(rs.saida),
                    dataMovimento: rs.dataMovimento,

                }

            }
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

      if (sessionStorage.getItem('perfil') == 'Administrador') {

        carregaVinculos()

      }

    }, [dataAtualiza])
    

    return (

        <div>


                        <MaterialReactTable 
                            
                            columns={columns} 
                            data={resultado} 
                            localization={MRT_Localization_PT_BR}
                            //rowsPerPage={20}
                            //options={options}
                            initialState={{ density: 'compact' }}
                            muiTableHeadCellProps={{
                                //easier way to create media queries, no useMediaQuery hook needed.
                                sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                    },
                                },
                            }}

                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                  //console.info(event, row.original.usuario_id);
                                  //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                  
                                  window.$('.modal-VinculoEditPend').modal('show');setVinculo_id(row.original.vinculo_id)
            
                                },
                                sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                              })}
                            
                        />


                <ModalVinculoEdit vinculo_id={vinculo_id}  origem='Pend' consultaTotalVinculosPendentes={props.consultaTotalVinculosPendentes} />
        

        </div>
    )

}

export default FluxoCaixaVinculosPendentes