
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';



const CentroCusto = () => {


    const consulta = () => {


        api.get('centroCusto').then((result) => {

            

            setResultado(result.data.map((rs) => {

                return {
                    
                    centroCusto_id: rs.centroCusto_id,
                    centroCusto: rs.centroCusto,
                    descricao: rs.descricao,
                    validado: rs.validado == true ? 'Sim' : 'Não',
                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
         
          {
            accessorKey: 'centroCusto', //access nested data with dot notation
            header: 'Código',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          
          {
            accessorKey: 'descricao', //normal accessorKey
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'validado',
            header: 'Validado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          
        ],
        [],
      );




    useEffect(() => {

        consulta()

    }, [])


    return (
        <div>

            <h5 className="m-t-lg with-border">Administração - Centros de Custos</h5>


                        

            <button type="button" class="btn btn-inline btn-warning">+ Centro de Custo</button>
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                    fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                    },
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/Usuario/${row.original.usuario_id}`)

                    },
                    sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
                
            />


        </div>
    )

}

export default CentroCusto