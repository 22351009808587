import { useEffect, useState } from "react"
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'

const Modal = (props) => {


    const fluxoCaixa_id = props.fluxoCaixa_id

    const [status, setStatus] = useState()

    const [resultadoReceitas, setResultadoReceitas] = useState()
    const [resultadoDespesas, setResultadoDespesas] = useState()
    const [resultadoTotalReceitas, setResultadoTotalReceitas] = useState()
    const [resultadoTotalDespesas, setResultadoTotalDespesas] = useState()
    
    const [resultadoTotalGeral, setResultadoTotalGeral] = useState()
    const [mesNumero, setMesNumero] = useState('')
    const [mes, setMes] = useState()
    const [ano, setAno] = useState()


    function valida(val) {

        api.put(`fluxoCaixaValidaStatus/${fluxoCaixa_id}/${val}/${ano}/${mesNumero}`).then((result) => {

            //console.log(result.data)
            if (result.data.status == 'ok') {

                window.toastr.success('Registro salvo com sucesso!')
                props.carregaLista()

                switch (val) {

                    case 'P':
                        setStatus('Pendente de validação')
                        break;

                    case 'V':
                        setStatus('Validado')
                        break;

                }                   

            }
            

        }).catch((err) => {

            console.log(err.response)

        })


    }



    async function geraPDF() {


        await api.get(`fluxoCaixaValidaPDF/${fluxoCaixa_id}`).then((result) => {

            if (result.data) {

                //window.open(`https://api-faj.koderia.com.br/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                

            }

            
        }).catch((err) => {

            console.log(err.response)

        })

    }


    const carrega = () => {


        if (fluxoCaixa_id) {            

            api.get(`fluxoCaixaValida/${fluxoCaixa_id}`).then((result) => {

                setMes(result.data[0][0].mesNome)
                setMesNumero(result.data[0][0].mes)
                setAno(result.data[0][0].ano)
                setStatus(result.data[0][0].status)

                setResultadoTotalGeral(result.data[0].map((rs) => 
                
                    <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                        <th>
                        {fmtMoney(rs.saldoAnterior)}
                        </th>
                        <th>
                            <font color='blue'>{fmtMoney(rs.receitas)}</font>
                        </th>
                        <th>
                            <font color='red'>{fmtMoney(rs.despesas)}</font>
                        </th>
                        <th>
                            {fmtMoney(rs.resultado)}
                        </th>
                        <th>
                            {fmtMoney(rs.saldoAtual)}
                        </th>
                        
                    </tr>
                ))


                setResultadoTotalReceitas(result.data[2].map((rs) =>
                
                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                        <th>
                            {rs.fluxoCaixa}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana1)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana2)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana3)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana4)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana5)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana6)}
                        </th>
                        <th style={{ textAlign: 'right', fontWeight: 'bold'}}>
                            {fmtMoney(rs.total)}
                        </th>
                    </tr>
                
                ))


                setResultadoReceitas(result.data[1].map((rs) => 
                
                    <tr style={{ fontSize: '11px'}}>

                        <td>
                            {rs.fluxoCaixa}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana1)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana2)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana3)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana4)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana5)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana6)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold'}}>
                            {fmtMoney(rs.total)}
                        </td>

                    </tr>
                
                ))


                setResultadoDespesas(result.data[3].map((rs) => 
                
                    <tr style={{ fontSize: '11px'}}>

                        <td>
                            {rs.fluxoCaixa}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana1)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana2)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana3)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana4)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana5)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana6)}
                        </td>
                        <td style={{ textAlign: 'right', fontWeight: 'bold'}}>
                            {fmtMoney(rs.total)}
                        </td>

                    </tr>
                
                ))



                setResultadoTotalDespesas(result.data[4].map((rs) => 
                
                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>

                        <th>
                            {rs.fluxoCaixa}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana1)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana2)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana3)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana4)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana5)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.valorSemana6)}
                        </th>
                        <th style={{ textAlign: 'right', fontWeight: 'bold'}}>
                            {fmtMoney(rs.total)}
                        </th>

                    </tr>
                
                ))


            }).catch((err) => {

                console.log(err.response)

            })

        }


    }



    useEffect(() => {


        carrega()


    }, [fluxoCaixa_id])

    return (

        <div>


            <div class="modal fade modal-FluxoCaixaMensal"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Fluxo Caixa  - {`${mes}/${ano}`}</h4>
                        </div>
                        <div class="modal-body">


                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                            <thead>
                            <tr style={{ textAlign: 'center'}}>
                                
                                <th>Saldo Anterior: </th>
                                <th>Receitas: </th>
                                <th>Despesas: </th>
                                <th>Resultado: </th>
                                <th>Saldo atual: </th>
                                
                                
                            </tr>
                            </thead>
                            <tbody>
                                {resultadoTotalGeral}
                                
                            </tbody>
                        </table>
                        <br/>
                        <table id="table-sm" class="table table-bordered table-hover table-sm">

                            <tr>
                                <th style={{ backgroundColor: '#afd8e0'}} colspan="8"><center>Receitas</center></th>
                            </tr>
                            <tr style={{ textAlign: 'center', fontWeight: 'bold'}}>
                                <td>Semana</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>Total</td>
                            </tr>
                            
                            <tbody>
                                {resultadoReceitas}
                                {resultadoTotalReceitas}
                                <tr>
                                    <td colspan="8"><br/></td>
                                </tr>
                                <tr>
                                    <th style={{ backgroundColor: '#e0baaf'}} colspan="8"><center>Despesas</center></th>
                                </tr>
                                {resultadoDespesas}
                                {resultadoTotalDespesas}
                            </tbody>
                        </table>

                        <br/>

                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                            <tr>
                                <th style={{ width: '20%', textAlign: 'right', fontSize: '11px'}}>Status</th>
                                <td style={{ fontSize: '11px', backgroundColor: status == 'Pendente de validação' ? '#e0c59f' : '#9fb7e0'}}>
                                    
                                    {status}
                                    
                                </td>
                            </tr>
                        </table>


                        </div>
                        <div class="modal-footer">
                            
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => geraPDF()}>PDF</button>
                            <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => valida('P')} style={{ display: 'none'}}>Deixar pendente de validação</button>
                            <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => valida('V')}>Validar</button>
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                        </div>
                    </div>
                </div>
            </div>
                        
        </div>

    )

}


export default Modal