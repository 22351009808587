import { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney } from '../functions/generalFunctions'

import api from '../components/api'
import ModalFluxoCaixaMensal from '../modal/ModalFluxoCaixaMensal'
import { useAtualizaContext } from '../context/atualizaContext'



const FluxoCaixaMensalValidado = () => {

    const { dataAtualiza } = useAtualizaContext()

    const [fluxoCaixa_id, setFluxoCaixa_id] = useState()

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())

    const [fluxoCaixa, setFluxoCaixa] = useState()
    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState()

    const [resultado, setResultado] = useState([])

    function carregaAnos() {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    function carrega() {

        if (ano != '') {


            api.get(`fluxoCaixaValidaIndex/${ano}`).then((result) => {

                setResultado(result.data.map((rs) => {


                    return {

                        fluxoCaixa_id: rs.fluxoCaixa_id,
                        ano: rs.ano,
                        mes: rs.mes,
                        receitas: fmtMoney(rs.receitas),
                        despesas: fmtMoney(rs.despesas),
                        resultado: fmtMoney(rs.resultado),
                        status: rs.status,
                        ad_new: rs.ad_new,


                    }


                }))

            }).catch((err) => {

                console.log(err)

            })


        }


    }


    useEffect(() => {

        carregaAnos()
        carrega()

    }, [ano, dataAtualiza])



    const columns = useMemo(
        () => [
            {
                accessorKey: 'ano', //access nested data with dot notation
                header: 'Ano',
                size: 10,
            },
            {
                accessorKey: 'mes',
                header: 'Mês',
                size: 10,
            },
            {
                accessorKey: 'receitas', //normal accessorKey
                header: 'Receitas',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'right',
                    
                },
                muiTableBodyCellProps: {
                    align: 'right',
                    
                },
            },
            {
                accessorKey: 'despesas',
                header: 'Despesas',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },

            },
            {
                accessorKey: 'resultado',
                header: 'Resultado',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },

            },
            {
                accessorKey: 'status',
                header: 'Status',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },

            },
            {
                accessorKey: 'ad_new',
                header: 'Em',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },

            },

        ],
        [],
    );




    return (

        <div>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>

                    </fieldset>
                </div>
               

            </div>



            <MaterialReactTable

                columns={columns}
                data={resultado}
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                        fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                        },
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                        },
                    },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                        //console.info(event, row.original.usuario_id);
                        //navigate(`/Usuario/${row.original.fluxoCaixa_id}`)

                        window.$('.modal-FluxoCaixaMensal').modal('show')
                        setFluxoCaixa_id(row.original.fluxoCaixa_id)

                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}

            />


            <ModalFluxoCaixaMensal fluxoCaixa_id={fluxoCaixa_id} carregaLista={carrega} />

        </div>
    )

}

export default FluxoCaixaMensalValidado