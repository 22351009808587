import React, { createContext, useState, useContext } from "react";

const atualizaContext = createContext();

export default function AmbienteProvider({ children }) {
  const [dataAtualiza, setDataAtualiza] = useState();

  return (
    <atualizaContext.Provider value={{ dataAtualiza, setDataAtualiza }} >
      {children}
    </atualizaContext.Provider>
  );
}

export function useAtualizaContext() {
  const context = useContext(atualizaContext);
  //if (!context) throw new Error("useCount must be used within a CountProvider");
  const { dataAtualiza, setDataAtualiza } = context;
  return { dataAtualiza, setDataAtualiza };
}

