
import { useState, useEffect } from "react"
import api from "../components/api"
import axios from 'axios'

const DemandaAnexos = (props) => {

    const perfil = sessionStorage.getItem('perfil')

    const demanda_id = props.demanda_id
    const demandaStatus_id = props.demandaStatus_id
    const processo = props.processo

    const usuario_id = sessionStorage.getItem('usuario_id')
    const usuario_descri = sessionStorage.getItem('nome')

    const [resultadoAnexos, setResultadoAnexos] = useState([])
    const [resultadoDocumentos, setResultadoDocumentos] = useState([])
    const [tipoDoc_id, setTipoDoc_id] = useState('')
    const [tipoDoc_descri, setTipoDoc_descri] = useState('')
    const [file, setFile] = useState()

    const [numeroProtheus, setNumeroProtheus] = useState('')

    const [destinoAnexo, setDestinoAnexo] = useState('')

    const Download = (arquivo) => {

        if (arquivo) {

            window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${arquivo}`)

        }
        
    }



    const carregaAnexos = () => {

        //console.log('aqui')

        api.get(`demandaAnexos/${demanda_id}`).then((result) => {

            //console.log(result.data)

            setResultadoAnexos(result.data.map((rs) =>

            
                <tr style={{ fontSize: '11px', cursor: rs.arquivo ? 'pointer' : 'auto'}} onClick={() => Download(rs.arquivo)}> 

                    <td >
                        {rs.descricao}
                    </td>
                    <td >
                        <center>
                        {rs.destinoAnexo}
                        </center>
                    </td>
                    <td style={{ textAlign: 'center', backgroundColor: rs.status == 'Anexado' ? '#ceedd8' : '#f0e7e6' }} >
                        {rs.status}
                    </td>

                </tr>
            
            ))

            setResultadoDocumentos(result.data.map((rs) => 
            
                (perfil == 'Financeiro' && rs.descricao == 'Reserva de Recursos') || (perfil != 'Financeiro') ?

                <option value={rs.tipoDoc_id}>{rs.descricao}</option>

                :

                ''
            
            ))

        }).catch((err) => {

            console.log(err.response)

        }) 

    }

    const salvaAnexo = () => {

        if (tipoDoc_id == '') {

            window.toastr.error('Por favor, informe o tipo de documento!')
            return false

        }

        if (tipoDoc_id == 3 && numeroProtheus == '') {

            window.toastr.error('Por favor, informe o <b>N<sup>o</sup> Pedido Protheus</b>!')
            return false

        }

        if (file == null || file == '') {


            window.toastr.error('Por favor, selecione o <b>arquivo</b> para anexar')
            return false


        } else {

            
            if (file.substr(file.length - 3).toLowerCase() != 'pdf') {

                window.toastr.error('Por favor, apenas arquivos no formato <b>PDF</b> para anexar!')
                return false


            }

        }

        

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma anexar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileAnexoPedido");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("tipoDoc_id", tipoDoc_id); // string junto ao formData.
                    formData.append("demanda_id", demanda_id); // string junto ao formData.
                    formData.append("usuario_id", usuario_id); // string junto ao formData.
                    formData.append('tipoDoc_descri', tipoDoc_descri);
                    formData.append('usuario_descri', usuario_descri);
                    formData.append('demandaStatusAnterior_id', demandaStatus_id)
                    formData.append('destinoAnexo', destinoAnexo)
                    formData.append('processo', processo)
                    formData.append('numeroProtheus', numeroProtheus)

                    //console.log('altera')
                    //return false

                    axios.post(`${process.env.REACT_APP_API}/demandaAnexo`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        //window.$('.modal-CompraImporta').modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()

                        //console.log(tipoDoc_descri, '..')
                        props.carregaDemanda()

                        if (tipoDoc_descri == 'Assinaturas') {

                            props.setPossuiAnexoAssinatura(true)
                            //window.$('[href="#tabs-4-tab-5k"]').tab('show');                            
                            window.$('[href="#tabs-4-tab-3k"]').tab('show');

                            window.$('#accordion').find('#collapseOne').collapse('hide')
                            window.$('#accordion').find('#collapseTwo').collapse('hide')
                            window.$('#accordion').find('#collapseThree').collapse('hide')
                            window.$('#accordion').find('#collapseFour').collapse('hide')
                            window.$('#accordion').find('#collapseFive').collapse('show')
                            window.$('#accordion').find('#collapseSix').collapse('hide')


                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'blue',
                                title: 'Aviso',
                                content: 'Por favor, complemente as informações da assinatura!',
                                buttons: {
                                    Ok: function () {

                                    },
                                },

                            });

                            

                        }

                        if (tipoDoc_descri == 'Reserva de Recursos') {

                            props.setPossuiReservaRecursos(true)
                            //window.$('[href="#tabs-4-tab-5k"]').tab('show');                            
                            window.$('[href="#tabs-4-tab-3k"]').tab('show');

                            window.$('#accordion').find('#collapseOne').collapse('hide')
                            window.$('#accordion').find('#collapseTwo').collapse('hide')
                            window.$('#accordion').find('#collapseThree').collapse('hide')
                            window.$('#accordion').find('#collapseFour').collapse('hide')
                            window.$('#accordion').find('#collapseFive').collapse('hide')
                            window.$('#accordion').find('#collapseSix').collapse('show')

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'blue',
                                title: 'Aviso',
                                content: 'Por favor, complemente as informações da Reserva de Recursos!',
                                buttons: {
                                    Ok: function () {

                                    },
                                },

                            });

                            

                        }


                        if (tipoDoc_descri == 'Mapa de Homologação') {

                            props.setPossuiAnexoMapaHomologacao(true)
                            //window.$('[href="#tabs-4-tab-5k"]').tab('show');                            
                            window.$('[href="#tabs-4-tab-5k"]').tab('show');

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'blue',
                                title: 'Aviso',
                                content: 'Por favor, complemente as informações do Mapa de Homologação!',
                                buttons: {
                                    Ok: function () {

                                    },
                                },

                            });

                            

                        }
                        
                        setTipoDoc_id('')
                        setFile('')
                        setTipoDoc_descri('')
                        setDestinoAnexo('')
                        carregaAnexos()
                        props.totalizaAnexos()
                        props.carregaHistoricoDemanda()
                        props.setDemandaStatus_id(response.data.demandaStatus_id)
                        props.setDemandaStatusAnterior_id(response.data.demandaStatus_id)

                        window.toastr.success('Arquivo anexado com sucesso!')



                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    useEffect(() => {

        setTipoDoc_id('')
        setFile('')
        setTipoDoc_descri('')
        setDestinoAnexo('')

        if (demanda_id) {

            carregaAnexos()

        }

    }, [demanda_id])


    useEffect(() => {

        if (destinoAnexo == 'P') {


            window.$.confirm({
                //icon: 'fa fa-question',
                theme: 'dark',
                closeIcon: true,
                animation: 'scale',
                type: 'orange',
                title: 'Aviso',
                content: `Ao selecionar esta opção este anexo será atribuído a todos os itens do processo <b>${processo}</b>`,
                buttons: {
                    Entendi: function () {

                        

                        

                    },
                
                },

            });


        }


    }, [destinoAnexo])


    useEffect(() => {

        if (processo == '' || processo == null) {

            setDestinoAnexo('')

        }

    }, [processo])

    return (

        <div>

            <div className="row">

                <div className="col-md-8" >
                    <fieldset className="form-group">
                        <label className="form-label">Tipo de documento</label>
                        <select className="form-control" value={tipoDoc_id} onChange={event => {setTipoDoc_id(event.target.value);setTipoDoc_descri(event.target[event.target.selectedIndex].text)}} >
                            <option value="">[Selecione]</option>
                            {resultadoDocumentos}
                        </select>

                    </fieldset>
                </div>

                <div className="col-md-4" >
                    <fieldset className="form-group">
                        <label className="form-label">Anexar no</label>
                        <select className="form-control" value={destinoAnexo} onChange={event => setDestinoAnexo(event.target.value)} >
                            <option value="">[Selecione]</option>
                            <option value="I">Neste item</option>
                            <option value="P" style={{ display: processo != null && processo != '' ? 'block' : 'none'}}>No processo</option>
                        </select>

                    </fieldset>
                </div>

                <div className="col-md-6" >
                    <fieldset className="form-group">
                        <label className="form-label">Arquivo</label>
                        <input type="file" className="form-control" id="fileAnexoPedido" value={file} onChange={event => setFile(event.target.value)} />

                    </fieldset>
                </div>
                <div className="col-md-3" style={{ display: tipoDoc_id == 3 ? 'table-row' : 'none' }} >
                    <fieldset className="form-group">
                        <label className="form-label">N<sup>o</sup> Pedido Protheus</label>
                        <input type="text" className="form-control" value={numeroProtheus} onChange={event => setNumeroProtheus(event.target.value)} maxLength={50} />

                    </fieldset>
                </div>
                <div className="col-md-3" >
                    <fieldset className="form-group">
                        <label className="form-label">&nbsp;</label>
                        <button type="button" className="btn btn-inline btn-info-outline" onClick={() => salvaAnexo()} >Salvar anexo</button>

                    </fieldset>
                </div>
                
                <div className="col-md-12" >


                    <table id="table-sm" class="table table-bordered table-hover table-sm">
                        <thead>
                                <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    
                                    <th>
                                        
                                    Descrição                                 

                                    </th>
                                    <th>
                                        
                                    Anexado no

                                    </th>
                                        
                                    <th>
                                        
                                    Status

                                    </th>
                                    
                                
                                </tr>
                                
                        </thead>
                        <tbody>

                          {resultadoAnexos}

                        </tbody>                       
                        
                        
                    </table>

                </div>

            </div>

            

        </div>

    )

}

export default DemandaAnexos