import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney, sleep } from '../functions/generalFunctions';

import ModalCatalogo from '../modal/ModalCatalogo'


const Catalogo = () => {

    const [listaGrupos, setListaGrupos] = useState()
    const [grupo, setGrupo] = useState('')
    const [resultado, setResultado] = useState([])
    const [item_id, setItem_id] = useState()


    const carregaGrupo = () => {

        api.get('gruposItens').then((result) => {

            const data = result.data

            setListaGrupos(data.map((rs, key) => 
            
                <option key={rs.key} value={rs.grupo}>
                    {rs.grupo}
                </option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao',
            header: 'Siafisico Pregão',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
         
          {
            accessorKey: 'descricao',
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'unidFornecimento',
            header: 'Unidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'unidTempoConsumo',
            header: 'Unid. Tempo Consumo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          {
            accessorKey: 'grupo',
            header: 'Grupo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
         /*
          {
            accessorKey: 'planejado',
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          */
        ],
        [],
      );


    const carregaItens = () => {

        if (grupo != null) {

            var dataPost = {

                grupo: grupo,
    
            }
    
    
            api.post('itens', dataPost).then((result) => {
    
    
                const data = result.data
                
    
                setResultado(data.map((rs) => {
    
    
                    return {
    
                        item_id: rs.item_id,
                        //codigoMestreMV: rs.codigoMestreMV,
                        siafisicoPregao: rs.siafisicoPregao,
                        //codigoFilhoMV: rs.codigoFilhoMV,
                        descricao: rs.descricao,
                        //codigoProtheus: rs.codigoProtheus,
                        unidFornecimento: rs.unidFornecimento,
                        unidTempoConsumo: rs.unidTempoConsumo,
                        grupo: rs.grupo,
                        //subGrupo: rs.subGrupo,
                        //custoMedio: rs.custoMedio != null ? fmtMoney(rs.custoMedio, 2) : '',
                        //planejado: rs.planejado == true ? 'Sim' : 'Não'
    
    
                    }
    
    
                }))
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })


        }

        


    }


  async function exportaExcel() {



          await api.get('exportaExcelTipo').then((result) => {
      
            

            if (result.data.status == 'ok') {

              window.toastr.info('Aguarde, gerando XLSX...')

                sleep(2000).then(() => {

                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    window.open(`https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`)
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                

            }

        }).catch((err) => {

            console.log(err.response)

        })


    }



    useEffect(() => {

        carregaGrupo()

    }, [])


    useEffect(() => {

        carregaItens()

    }, [grupo])


    return (

        <div>


            <button type="button" class="btn btn-inline btn-primary" onClick={() => {window.$('.modal-CatalogoEdit').modal('show');setItem_id()}}>+ Novo</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
            
                    
            <br/><br/>

            <div className="row">
                <div className="col-md-12 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Grupo</label>
                        <select type="text" className="form-control" value={grupo} onChange={event => setGrupo(event.target.value)}>
                            <option value="">Selecione</option>
                            {listaGrupos}
                        </select>
                        
                    </fieldset>
                </div>

            </div>



            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                    window.$('.modal-CatalogoEdit').modal('show');setItem_id(row.original.item_id)

                  },
                  sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                  },
                })}
                
            />




        <ModalCatalogo item_id={item_id} carregaItens={carregaItens} carregaGrupo={carregaGrupo} />
        

        </div>
    )


}

export default Catalogo