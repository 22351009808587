
import { Navigate } from "react-router-dom";


export const ProtectedRoute = ({ children }) => {
  //const { user } = useAuth();
  //const autenticado = false;
  //console.log(sessionStorage.getItem('logado'))
  if (sessionStorage.getItem('logado')) {
    // user is not authenticated
    return children
  } else {

    return <Navigate to="/" />;

  }
  //return children;
};