import { useState, useEffect } from "react"
import api from '../components/api'
import CurrencyInput from '../components/CurrencyInput'
import { useAtualizaContext } from '../context/atualizaContext'


const Modal = (props) => {

    const origem = props.origem

    const movimento_id = props.movimento_id
    const [dataMovimento, setDataMovimento] = useState()
    const [area, setArea] = useState()
    const [fluxoCaixa, setFluxoCaixa] = useState()
    
    const [banco, setBanco] = useState()
    const [agencia, setAgencia] = useState()
    const [contaBanco, setContaBanco] = useState()    
    const [codigoNatureza, setCodigoNatureza] = useState()
    const [natureza, setNatureza] = useState()
    const [documento, setDocumento] = useState()
    const [historico, setHistorico] = useState()
    const [itemConta, setItemConta] = useState()
    const [itemContaDescri, setItemContaDescri] = useState()
    const [entrada, setEntrada] = useState()
    const [saida, setSaida] = useState()
    const [resultado, setResultado] = useState()
    const [validado, setValidado] = useState(false)
    const [titulo, setTitulo] = useState('')
    
    const carregaVinculo = () => {

        if (movimento_id) {

            api.get(`listaMovimentoUm/${movimento_id}`).then((result) => {

                console.log(result.data)
                /*
                Fornecedor: "RH0001"
                ad_new: "26/06/2023 16:06:52"
                ad_upd: "26/06/2023 16:06:52"
                ad_usr: 1
                agencia: "0400"
                ano: 2023
                area: "UTI PEDIATRICA"
                banco: "033"
                beneficiario: "RECURSOS HUMANOS"
                cCusto: ""
                centroCusto: ""
                cliFor: null
                codigoNatureza: "23104"
                contaBanco: "130004075"
                data: "01/06/2023"
                dataMovimento: "01/06/2023"
                datavencimento: "01/01/1900"
                deletado: false
                documento: "GPE-121439076"
                entrada: "0,00"
                fluxoCaixa: "UTI PEDIATRICA"
                fluxoSemana: 1
                historico: "VALOR PAGO S /TITULO"
                idMovimento: ""
                itemConta: "06001042"
                itemContaDescri: "CONVENIO138 2022  U.T.I. PEDIATRIA"
                mes: 6
                movimento_id: 25086
                natureza: "FERIAS"
                nota: ""
                numero: ""
                numeroProcesso: ""
                resultado: "-3.866,44"
                saida: "3.866,44"
                tipo: "DESPESAS"
                validado: true
                */
    
                var data = result.data[0]
    
                setDataMovimento(data.data)
                setArea(data.area)
                setFluxoCaixa(data.fluxoCaixa)

                setBanco(data.banco)
                setAgencia(data.agencia)
                setContaBanco(data.contaBanco)
                setCodigoNatureza(data.codigoNatureza)
                setNatureza(data.natureza)
                setDocumento(data.documento)
                setHistorico(data.historico)
                setItemConta(data.itemConta)
                setItemContaDescri(data.itemContaDescri)
                setEntrada(data.entrada)
                setSaida(data.saida)
                setResultado(data.resultado)
                setValidado(data.validado)
                setTitulo(data.titulo)
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })
    

        }

    }

    const handleChange = (e) => {
        e.preventDefault();
    }



    const apagar = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {

                    
                    api.delete(`movimento/${movimento_id}`).then((result) => {
                        
                        //console.log(result.data)

                        window.toastr.success('Registro apagado com sucesso!')
                        window.$(`.modal-MovimentoEdit${origem}`).modal('hide')
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }


    const salvar = () => {


        const dataPost = {

            titulo: titulo,
            dataMovimento: dataMovimento,
            area: area,
            fluxoCaixa: fluxoCaixa,
            banco: banco,
            agencia: agencia,
            contaBanco: contaBanco,
            codigoNatureza: codigoNatureza,
            natureza: natureza,
            itemConta: itemConta,
            itemContaDescri: itemContaDescri,
            documento: documento,
            historico: historico,
            entrada: entrada,
            saida: saida,
            resultado: resultado,
            validado: validado,


        }


        console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {

                    

                    if (movimento_id) {

                        var dataPost = {



                        }

                        api.put(`movimento/${movimento_id}`, dataPost).then((result) => {

                            console.log(result.data)

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    } else {


                        var dataPost = {


                        }

                        api.post('movimento', dataPost).then((result) => {

                            console.log(result.data)

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    }

                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }



    useEffect(() => {

        carregaVinculo()

    }, [movimento_id])



    return (

        <div>

                <div class={`modal fade modal-MovimentoEdit${origem}`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Movimento - Editar [{movimento_id}]</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Data</label>
                                        <input type="text" className="form-control" value={dataMovimento} onChange={event => setDataMovimento(event.target.value)} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Área</label>
                                        <input type="text" className="form-control" value={area} onChange={event => setArea(event.target.value)} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Fluxo de Caixa</label>
                                        <input type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)} style={{ backgroundColor: '#daecf0'}} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Banco</label>
                                        <input type="text" className="form-control" value={banco} onChange={event => setBanco(event.target.value)}  />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Agência</label>
                                        <input type="text" className="form-control"  value={agencia} onChange={event => setAgencia(event.target.value)}  />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Conta bancária</label>
                                        <input type="text" className="form-control" value={contaBanco} onChange={event => setContaBanco(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Natureza</label>
                                        <input type="text" className="form-control" value={codigoNatureza} onChange={event => setCodigoNatureza(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Natureza</label>
                                        <input type="text" className="form-control" value={natureza} onChange={event => setNatureza(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Item Contábil</label>
                                        <input type="text" className="form-control" value={itemConta} onChange={event => setItemConta(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Descr. Item Contábil</label>
                                        <input type="text" className="form-control" value={itemContaDescri} onChange={event => setItemContaDescri(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Documento</label>
                                        <input type="text" className="form-control" value={documento} onChange={event => setDocumento(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Histórico</label>
                                        <input type="text" className="form-control" value={historico} onChange={event => setHistorico(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Entrada</label>
                                        <CurrencyInput type="text" className="form-control" value={entrada} onChange={event => setEntrada(event.target.value)}  
                                        style={{ backgroundColor: '#eaeddd' }}
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                        />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Saída</label>
                                        <CurrencyInput type="text" className="form-control" value={saida} onChange={event => setSaida(event.target.value)}  
                                        style={{ backgroundColor: '#eaeddd' }}
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Resultado</label>
                                        <CurrencyInput type="text" className="form-control" value={resultado} onChange={event => setResultado(event.target.value)} 
                                        disabled
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Título</label>
                                        <input type="text" className="form-control" value={titulo} onChange={event => setTitulo(event.target.value)}  />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Validado</label>
                                        <select className="form-control" value={validado} onChange={event => setValidado(event.target.value)} >
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </fieldset>
                                </div>
                            </div>


                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apagar()} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()} >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Modal