import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'

const ItemDetalhe = (props) => {

    const item_id = props.item_id


    const [custoMedio, setCustoMedio] = useState()
    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [siafisicoATA, setSiafisicoATA] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [grupo, setGrupo] = useState('')
    const [subGrupo, setSubGrupo] = useState('')
    
    const [codigoProtheus, setCodigoProtheus] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')


    const limpa = () => {

        setCodigoMestreMV('')
        setSiafisicoPregao('')
        setSiafisicoATA('')
        setDescricao('')
        setUnidFornecimento('')
        setGrupo('')
        setSubGrupo('')
        
        setCustoMedio()
        setCodigoProtheus('')
        setCodigoFilhoMV('')

    }


    const carrega = () => {


        if (item_id) {


            api.get(`item/${item_id}`).then((result) => {

                var data = result.data[0]

                setCodigoMestreMV(data.codigoMestreMV)
                setCodigoFilhoMV(data.codigoFilhoMV == null ? '' : data.codigoFilhoMV)
                setCodigoProtheus(data.codigoProtheus == null ? '' : data.codigoProtheus)
                setSiafisicoPregao(data.siafisicoPregao)
                setSiafisicoATA(data.siafisicoATA)
                setDescricao(data.descricao)
                setUnidFornecimento(data.unidFornecimento)
                setGrupo(data.grupo)
                setSubGrupo(data.subGrupo)
                
                setCustoMedio(data.custoMedio == null ? '' : fmtMoney(data.custoMedio, 2))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }


    const salvar = () => {

        if (siafisicoPregao == '') {

            window.toastr.error('<b>Siafisico</b> não pode ficar em branco!')
            return false

        }


        if (unidFornecimento == '') {

            window.toastr.error('<b>Unidade Fornecimento</b> não pode ficar em branco!')
            return false

        }


        const dataPost = {

            //custoMedio: custoMedio != null ? parseFloat(custoMedio.replaceAll('.', '').replaceAll(',', '.').replaceAll('R$', '')) : null,
            codigoMestreMV: codigoMestreMV == null ? '' : codigoMestreMV,
            codigoFilhoMV: codigoFilhoMV == null ? '' : codigoFilhoMV,
            siafisicoPregao: siafisicoPregao,
            codigoProtheus: codigoProtheus == null ? '' : codigoProtheus,
            descricao: descricao,
            unidFornecimento: unidFornecimento,
            grupo: grupo,
            subGrupo: subGrupo,
            

        }


        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar o item?',
		    buttons: {
		        Sim: function () {


                    if (item_id) {


                        api.put(`item/${item_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            window.$('.modal-CatalogoEdit').modal('hide')
                            window.toastr.success('Registro salvo com sucesso!')
                            props.carregaGrupo()
                            props.carregaItens()
                            limpa()

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    } else {


                        api.post('item', dataPost).then((result) => {

                            console.log(result.data)
                            var data = result.data[0]
                            //setItem_id(data.item_id)
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-CatalogoEdit').modal('hide')
    
                            props.carregaGrupo()
                            props.carregaItens()
                            limpa()
    
                        }).catch((err) => {
    
                            console.log(err)
    
                        })


                    }

                    

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }


    const apagar = () => {

            window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {




                    api.delete(`item/${item_id}`).then((result) => {

                        console.log(result.data)
                        window.toastr.success('Registro apagado com sucesso!')
                        window.$('.modal-CatalogoEdit').modal('hide')

                        props.carregaGrupo()
                        props.carregaItens()
                        
                        //setDataAtualiza(Math.random())

                        //props.consultaTotalVinculosPendentes()

                    }).catch((err) => {

                        console.log(err.response)

                    })




                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }



    const handleChange = (e) => {
        e.preventDefault();
    }


    useEffect(() => {

        limpa()
        carrega()

    }, [item_id])



    return (

        <div>


                <div className="row">
                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Siafisico</label>
                            <input type="text" className="form-control" value={siafisicoPregao} onChange={event => setSiafisicoPregao(event.target.value)} maxLength={25} style={{ backgroundColor: '#e0cecc'}} />
                        </fieldset>
                    </div>

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Mestre MV</label>
                            <input type="text" className="form-control" value={codigoMestreMV} onChange={event => setCodigoMestreMV(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Filho MV</label>
                            <input type="text" className="form-control" value={codigoFilhoMV} onChange={event => setCodigoFilhoMV(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                   

                    <div className="col-md-3">
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Protheus</label>
                            <input type="text" className="form-control" value={codigoProtheus} onChange={event => setCodigoProtheus(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>

                    <div className="col-md-4" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Ata</label>
                            <input type="text" className="form-control" value={siafisicoATA} onChange={event => setSiafisicoATA(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <fieldset className="form-group">
                            <label className="form-label">Descrição</label>
                            <textarea className="form-control" rows="5" value={descricao} onChange={event => setDescricao(event.target.value)} maxLength={2000} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Unidade Fornecimento</label>
                            <input type="text" className="form-control" value={unidFornecimento} onChange={event => setUnidFornecimento(event.target.value)} maxLength={25} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Grupo</label>
                            <input type="text" className="form-control" value={grupo} onChange={event => setGrupo(event.target.value)} maxLength={50} />
                        </fieldset>
                    </div>
                    <div className="col-md-4">
                        <fieldset className="form-group">
                            <label className="form-label">Subgrupo</label>
                            <input type="text" className="form-control" value={subGrupo} onChange={event => setSubGrupo(event.target.value)} maxLength={50} />
                        </fieldset>
                    </div>
                    
                    <div className="col-md-6" style={{ display: 'none'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Custo médio R$</label>
                            <CurrencyInput type="text" className="form-control" value={custoMedio} onChange={event => setCustoMedio(event.target.value)}  
                            style={{ backgroundColor: '#eaeddd' }}
                            onCut={handleChange}
                            onCopy={handleChange}
                            onPaste={handleChange}
                            />
                        </fieldset>
                    </div>
                    <div className="col-md-12">
                        <br/>
                        <div align="right">
                            <button type="button" className="btn btn-inline btn-danger-outline" style={{ display: item_id ? 'table-row' : 'none'}} onClick={() => apagar()}>Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()}>Salvar</button>
                        </div>
                    </div>
                                    

                </div>


        </div>
    )

}

export default ItemDetalhe