import { useEffect } from 'react'
import FluxoCaixaVinculosAtivos from '../componentsPages/FluxoCaixaVinculosAtivos'
import FluxoCaixaVinculosPendentes from '../componentsPages/FluxoCaixaVinculosPendentes'
import { useTotalVinculoPendenteContext } from '../context/totalVinculoPendenteContext'
import api from '../components/api'

const FluxoCaixaVinculos = () => {

    const { totalVinculoPendente, setTotalVinculoPendente } = useTotalVinculoPendenteContext()


    const consultaTotalVinculosPendentes = () => {


        api.get('contaVinculoPendente').then((result) => {

            setTotalVinculoPendente(result.data[0].total)

        }).catch((err) => {

            console.log(err.response)

        })



    }


    useEffect(() => {

        consultaTotalVinculosPendentes()

    }, [])


    return (

        <div>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4-tab-1c" role="tab" data-toggle="tab">
                                Ativos
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-2c" role="tab" data-toggle="tab">
                                Pendentes de vinculação&nbsp;<span class="label label-pill label-danger">{totalVinculoPendente}</span>
                                
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4-tab-1c">



                        <FluxoCaixaVinculosAtivos consultaTotalVinculosPendentes={consultaTotalVinculosPendentes} />



                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2c">


                        <FluxoCaixaVinculosPendentes consultaTotalVinculosPendentes={consultaTotalVinculosPendentes} />


                    </div>
                    
                </div>
            </section>

        </div>


    )

}

export default FluxoCaixaVinculos