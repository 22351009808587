import { useEffect, useState } from "react"
import api from "../components/api";
import { validaCPF } from '../functions/generalFunctions'
import InputMask from 'react-input-mask'


const Sigin = () => {

    //const navigate = useNavigate()


    const [cpf, setCPF] = useState('')
    const [senha, setSenha] = useState('')


        
    useEffect(() => {

        window.$(function() {
            window.$('.page-center').matchHeight({
                target: window.$('html')
            });

            window.$(window).resize(function(){
                setTimeout(function(){
                    window.$('.page-center').matchHeight({ remove: true });
                    window.$('.page-center').matchHeight({
                        target: window.$('html')
                    });
                },100);
            });
        });

    }, [])


    




    const login = () => {

        if (!validaCPF(cpf.replaceAll('.', '').replaceAll('-', ''))) {

            window.toastr.error('CPF inválido!')
            return false;

        }

        if (senha == '') {

            window.toastr.error('Por favor, informe a senha!')
            return false;

        }

        var dataPost = {

            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
            senha: senha,
            origem: 'transparenciaFinanceira',

        }
        
        //console.log(dataPost)
        
        api.post('login', dataPost).then((result) => {

            var data = result.data[0]

            //console.log(result.data)
            //return false

            if (data.status == 'logado') {


                sessionStorage.setItem('logado', true)
                sessionStorage.setItem('usuario_id', data.usuario_id)
                sessionStorage.setItem('nome', data.nome.toUpperCase())
                sessionStorage.setItem('perfil', data.perfil)
                sessionStorage.setItem('cpf', data.cpf)
                sessionStorage.setItem('demandaSolicitante_id', data.demandaSolicitante_id)
                sessionStorage.setItem('solicitante', data.solicitante)
                sessionStorage.setItem('gestorArea', data.gestorArea)
                sessionStorage.setItem('assinaReservaRecurso', data.assinaReservaRecurso)
                window.location.href = 'Inicio'


            } else {

                window.toastr.error('Usuário ou senha inválida!')

            }

        }).catch((err) => {

            console.log(err)

        })

        //window.location.href = 'Inicio'
        
        

    }




    return (


        <div>


            <body>

                <div className="page-center">
                    <div className="page-center-in">
                        <div className="container-fluid">
                            <form className="sign-box">
                                <br/>
                                <center>
                                <img src="pics/logoFAJ.png" alt="" width="200px" />
                                </center>
                                <br/>
                                <header className="sign-title">Transparência Financeira</header>
                                <div className="form-group">
                                    <InputMask mask="999.999.999-99" type="text" className="form-control" placeholder="CPF" value={cpf} onChange={event => setCPF(event.target.value)} 
                                    
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            login()                                            
                                        }
                                    }}
                                    
                                    />
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control" placeholder="Senha" value={senha} onChange={event => setSenha(event.target.value)} 
                                    
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            login()                                            
                                        }
                                    }}
                                    
                                    />
                                </div>
                                
                                <button type="button" className="btn btn-rounded" onClick={() => login()}>Ok</button>
                                
                                
                            </form>
                        </div>
                    </div>
                </div>

            </body>


        </div>

    )

}


export default Sigin