import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import FornecedorCadastro from '../componentsPages/FornecedorCadastro'
import FornecedorCertidoes from '../componentsPages/FornecedorCertidoes'
import FornecedorMedicoes from '../componentsPages/FornecedorMedicoes'
import FornecedorContratos from '../componentsPages/FornecedorContratos'
import api from '../components/api'


const Fornecedor = () => {

    const { slug } = useParams()

    

    const [totalMedicoes, setTotalMedicoes] = useState(0)
    const [totalContratos, setTotalContratos] = useState(0)
    const [medicoes, setMedicoes] = useState([])
    const [contratos, setContratos] = useState([])
    

    const carregaMedicoes = () => {

        api.get(`fornecedorMedicoes/${slug}`).then((result) => {

            setTotalMedicoes(result.data.length)
            setMedicoes(result.data)
            //console.log(result.data)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const carregaContratos = () => {

        api.get(`fornecedorContratos/${slug}`).then((result) => {

            setTotalContratos(result.data.length)
            setContratos(result.data)
            //console.log(result.data)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        if (slug) {

            carregaMedicoes()
            carregaContratos()

        }

    }, [slug])

    return (

        <div>

            <h5 className="m-t-lg with-border">Fornecedor</h5>


            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4b-tab-1b" role="tab" data-toggle="tab">
                                Cadastro
                            </a>
                        </li>
                        
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-4b" role="tab" data-toggle="tab">
                                Certidões
                            </a>
                        </li>
                        <li className="nav-item" >
                            <a className="nav-link" href="#tabs-4-tab-6b" role="tab" data-toggle="tab">
                                Contratos&nbsp;<span class="label label-pill label-primary">{totalContratos}</span>
                            </a>
                        </li>
                        <li className="nav-item" >
                            <a className="nav-link" href="#tabs-4-tab-5b" role="tab" data-toggle="tab">
                                Medições&nbsp;<span class="label label-pill label-warning">{totalMedicoes}</span>
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1b">

                        <FornecedorCadastro  slug={slug} />

                    </div>
                   
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-4b">

                        <FornecedorCertidoes fornecedor_id={slug} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-5b">

                        <FornecedorMedicoes fornecedor_id={slug} medicoes={medicoes} carregaMedicoes={carregaMedicoes} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-6b">

                        <FornecedorContratos fornecedor_id={slug} contratos={contratos} carregaContratos={carregaContratos} />

                    </div>
                    
                </div>
            </section>



        </div>

    )

}

export default Fornecedor