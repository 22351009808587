import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney } from '../functions/generalFunctions';
import moment from 'moment'
import ModalPlanejConsumo from '../modal/ModalPlanejConsumo'
import ModalGeraExcel from '../modal/ModalGeraExcel'


const ConsumoPlanejamentoAnual = () => {

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())

    const [planejamento_id, setPlanejamento_id] = useState()
    const [resultado, setResultado] = useState([])
    const [item_id, setItem_id] = useState()


    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    const columns = useMemo(
        () => [
          {
            accessorKey: 'planejamentoInicial', //access nested data with dot notation
            header: 'Planejado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'grupo',
            header: 'Grupo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'ano', //normal accessorKey
            header: 'Ano',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'valorReferencial',
            header: 'Valor Referencial',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'metaConsumo',
            header: 'Meta Consumo',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'estoque',
            header: 'Estoque',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          {
            accessorKey: 'metaCompra',
            header: 'Meta Compra',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },         
          
        ],
        [],
      );




    const carrega = () => {


        api.get(`planejamentoLista/${ano}`).then((result) => {

            var data = result.data

            //console.log(data)

            setResultado(data.map((rs) => {

                return {

                    planejamento_id: rs.planejamento_id,
                    siafisicoPregao: rs.siafisicoPregao,
                    descricao: rs.descricao,
                    item_id: rs.item_id,
                    grupo: rs.grupo,
                    ano: rs.ano,
                    valorReferencial: rs.valorReferencial == null ? 0 : fmtMoney(rs.valorReferencial, 2).replaceAll('R$', ''),
                    metaConsumo: rs.metaConsumo == null ? 0 : fmtMoney(rs.metaConsumo, '0').replaceAll('R$', ''),
                    estoque: rs.estoque == null ? 0 : fmtMoney(rs.estoque, '0').replaceAll('R$', ''),
                    metaCompra: rs.metaCompra == null ? 0 : fmtMoney(rs.metaCompra, '0').replaceAll('R$', ''),
                    planejamentoInicial: rs.planejamentoInicial == ano ? 'Sim' : 'Não',

                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

        carregaAnos()
        carrega()

    }, [ano])


    return (

        <div>

            <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id()}}>+ Novo</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => window.$('.modal-GeraExcel').modal('show')}>Exportar Excel</button>
                    
            <br/><br/>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>

                    </fieldset>
                </div>
               

            </div>

            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                    window.$('.modal-PlanejConsumoEdit').modal('show');setPlanejamento_id(row.original.planejamento_id);setItem_id(row.original.item_id)

                  },
                  sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                  },
                })}
                
            />


            <ModalPlanejConsumo planejamento_id={planejamento_id} carregaPlanej={carrega} item_id={item_id} />
            <ModalGeraExcel />

        </div>
    )

}

export default ConsumoPlanejamentoAnual