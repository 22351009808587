import { useEffect, useState } from 'react'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import moment from 'moment'
import ItemConsumo from '../componentsPages/ItemConsumo'
import ItemProcesso from '../componentsPages/ItemProcesso'

const ItemPlanej = (props) => {


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const item_id = props.item_id
    const [resultado, setResultado] = useState([])



    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const carrega = () => {

        api.get(`planejamentoItem/${item_id}`).then((result) => {

            var data = result.data

            setResultado(data.map((rs) => 
            
                <tr style={{ fontSize: '11px'}}>
                   
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.metaConsumo == null ? '0' : fmtMoney(rs.metaConsumo).replaceAll('R$', '')}

                    </td>
                    
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.estoque == null ? '0' : fmtMoney(rs.estoque).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.metaCompra == null ? '0' : fmtMoney(rs.metaCompra).replaceAll('R$', '')}

                    </td>

                    <td style={{ textAlign: 'right'}}>
                        
                        {rs.valorReferencial == null ? '0,00' : fmtMoney(rs.valorReferencial, 2)}

                    </td>
                    
                    
                </tr>
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

        carregaAnos()

    }, [])

    useEffect(() => {

        carrega()

    }, [item_id])

    return (

        <div>

            <div className="col-md-12">
                <fieldset className="form-group">
                    <label className="form-label" for="exampleError">Ano</label>
                    <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                        {listaAnos}
                    </select>
                    
                </fieldset>
            </div>
            <div className="col-md-12">

            <section class="widget widget-accordion" id="accordion" role="tablist" aria-multiselectable="true">
                                <article class="panel">
                                    <div class="panel-heading" role="tab" id="headingOne">
                                        <a data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne">
                                            Planejado
                                            <i class="font-icon font-icon-arrow-down"></i>
                                        </a>
                                    </div>
                                    <div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
                                        <div class="panel-collapse-in">
                                           


                                            <table id="table-sm" class="table table-bordered table-hover table-sm">
                                                <thead>
                                                        <tr style={{ textAlign: 'center'}}>
                                                            
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Meta Consumo

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Estoque ano anterior

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Meta Compra

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Valor Referencial

                                                            </th>
                                                            
                                                            
                                                        </tr>
                                                        
                                                </thead>
                                                <tbody>
                                                {resultado}
                                                </tbody>
                                            </table>



                                        </div>
                                    </div>
                                </article>
                                <article class="panel">
                                    <div class="panel-heading" role="tab" id="headingTwo">
                                        <a class="collapsed"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo">
                                            Execução
                                            <i class="font-icon font-icon-arrow-down"></i>
                                        </a>
                                    </div>
                                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                        <div class="panel-collapse-in">
                                            

                                            <ItemProcesso item_id={item_id} ano={ano} />


                                        </div>
                                    </div>
                                </article>
                                <article class="panel">
                                    <div class="panel-heading" role="tab" id="headingThree">
                                        <a class="collapsed"
                                        data-toggle="collapse"
                                        data-parent="#accordion"
                                        href="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree">
                                            Empenhos
                                            <i class="font-icon font-icon-arrow-down"></i>
                                        </a>
                                    </div>
                                    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                        <div class="panel-collapse-in">
                                            
                                            <ItemConsumo item_id={item_id} ano={ano} />

                                        </div>
                                    </div>
                                </article>
                            </section>


                

                

            </div>



        </div>
    )
}

export default ItemPlanej