
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';


const Usuarios = () => {

    const navigate = useNavigate()


    const consulta = () => {


        api.get('usuarioListaTodos').then((result) => {

            

            setResultado(result.data.map((rs) => {

                return {
                    
                    cpf: rs.cpf,
                    usuario_id: rs.usuario_id,
                    nome: rs.nome,
                    email: rs.email,
                    numeroAcessos: rs.numeroAcessos,
                    deletado: rs.deletado == true ? 'Sim' : 'Não',
                    ultimoAcesso: rs.ultimoAcesso,
                    perfil: rs.perfil,
                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'nome', //access nested data with dot notation
            header: 'Nome',
           
          },
          {
            accessorKey: 'cpf', //access nested data with dot notation
            header: 'CPF',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          
          {
            accessorKey: 'email', //normal accessorKey
            header: 'E-mail',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'numeroAcessos',
            header: 'Acessos',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'deletado',
            header: 'Bloqueado',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'perfil',
            header: 'Perfil',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'ultimoAcesso',
            header: 'Último acesso',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
        ],
        [],
      );



    useEffect(() => {

        consulta()


    }, [])


    return (


        <div>


            <h5 className="m-t-lg with-border">Administração - Usuários</h5>


            

            <button type="button" class="btn btn-inline btn-success" onClick={() => navigate('/Usuario')}>+ Usuário</button>
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      navigate(`/Usuario/${row.original.usuario_id}`)

                    },
                    sx: {
                      cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                
            />

        
        </div>
        
    )

}


export default Usuarios