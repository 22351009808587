import { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import api from "../components/api"
import { v4 as uuidv4 } from 'uuid';
import ModalDespachoCompra from '../modal/ModalDespachoCompra'
import ModalCancelaPedidoCompra from '../modal/ModalCancelaPedidoCompra'
import ModalDevolvePedidoCompra from '../modal/ModalDevolvePedidoCompra'

import axios from 'axios'
import InputMask from 'react-input-mask'
import moment from 'moment'

import MaskedInput from 'react-maskedinput';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from  "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "../components/styles.css";


const Modal = (props) => {
    

    const slug = props.pedidoCompra_id
    

    const [pedidoCompra_id, setPedidoCompra_id] = useState()

    const perfil = sessionStorage.getItem('perfil')
    const gestorArea = sessionStorage.getItem('gestorArea')

    //console.log(gestorArea)
    
    const [nome, setNome] = useState(sessionStorage.getItem('nome'))


    const [tipoItem, setTipoItem] = useState('')

    const [solicitante, setSolicitante] = useState(sessionStorage.getItem('solicitante'))
    const [resultadoSolicitantes, setResultadoSolicitantes] = useState([])

    
    const [itemDescri, setItemDescri] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')
    const [codigoMestreMV, setCodigoMestreMV] = useState('')
    const [codigoFilhoMV, setCodigoFilhoMV] = useState('')
    
    const [descricaoServico, setDescricaoServico] = useState('')

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [item_id, setItem_id] = useState()

    const [justificativa, setJustificativa] = useState('')

    const [color, setColor] = useState('white')
    const [colorMateriaPrima, setColorMateriaPrima] = useState('white')

    const [quantidade, setQuantidade] = useState('')

    const [itens, setItens] = useState([])
    const [resultadoItens, setResultadoItens] = useState([])

    const [numeroPedido, setNumeroPedido] = useState('')

    const [pedidoCompraStatus_id_anterior, setPedidoCompraStatus_id_anterior] = useState()
    const [pedidoCompraStatus_id, setPedidoCompraStatus_id] = useState()
    const [resultadoStatusPedidoCompra, setResultadoStatusPedidoCompra] = useState([])

    const [totalAnexos, setTotalAnexos] = useState(0)

    const [totalHistorico, setTotalHistorico] = useState(0)
    const [totalItens, setTotalItens] = useState(0)
    const [numeroProcesso, setNumeroProcesso] = useState()
    
    const [autorizado, setAutorizado] = useState(false)
    const [autorizacao, setAutorizacao] = useState('')

    const [resultadoHistorico, setResultadoHistorico] = useState([])
    const [resultadoAnexos, setResultadoAnexos] = useState([])
        
    const [file, setFile] = useState()
    const [descricaoAnexo, setDescricaoAnexo] = useState('')
    const [numeroDespacho, setNumeroDespacho] = useState()

    const [dataAtendimentoF, setDataAtendimentoF] = useState('')
    const [identificacao, setIdentificacao] = useState('')
    const [urgente, setUrgente] = useState(false)


    const [resultadoBuscaMateriaPrima, setResultadoMateriaPrima] = useState('')
    const [materiaPrimaDescri, setMateriaPrimaDescri] = useState('')
    const [materiaPrima_id, setMateriaPrima_id] = useState()

    const [revisaoEC, setRevisaoEC] = useState('')

    const [condicoesEspeciais, setCondicoesEspeciais] = useState()
    const [fornecedorHomologado, setFornecedorHomologado] = useState()
    const [dispensaPesquisa, setDispensaPesquisa] = useState()

    const [centroCusto, setCentroCusto] = useState('')
    const [colorCentroCusto, setColorCentroCusto] = useState('white')
    const [centroCustoDescri, setCentroCustoDescri] = useState('')
    const [resultadoCentroCusto, setResultadoCentroCusto] = useState('')
    const [centroCusto_id, setCentroCusto_id] = useState()

    const [salvo, setSalvo] = useState(false)


    const [colorOpme, setColorOpme] = useState('white')
    const [resultadoOpme, setResultadoOpme] = useState('')
    const [opmeDescri, setOpmeDescri] = useState('')
    const [opme_id, setOpme_id] = useState()
    
    const [recebido, setRecebido] = useState(true)

    const [resultadoCompradores, setResultadoCompradores] = useState()
    const [usuario_id_comprador, setUsuarioId_comprador] = useState()

    const [resultadoModalidades, setResultadoModalidades] = useState()
    const [modalidade_id, setModalidade_id] = useState()

    const [tipoProcesso, setTipoProcesso] = useState('')

    const [origemItem, setOrigemItem] = useState('')


    useEffect(() => {

        limpa()
        setPedidoCompra_id(slug)

    }, [slug])


    const salvaAnexo = () => {




       
        if (file == null || file == '') {


            window.toastr.error('Por favor, selecione o <b>arquivo</b> para anexar')
            return false


        } else {

            
            if (file.substr(file.length - 3).toLowerCase() != 'pdf') {

                window.toastr.error('Por favor, apenas arquivos no formato <b>PDF</b> para anexar!')
                return false


            }

        }

        

        if (descricaoAnexo == '') {

            window.toastr.error('Por favor, informe a descrição do arquivo que deseja anexar')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma anexar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector("#fileAnexoPedido");

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    formData.append("descricao", descricaoAnexo); // string junto ao formData.
                    formData.append("pedidoCompra_id", pedidoCompra_id); // string junto ao formData.
                    
                    //console.log('altera')
                    //return false

                    axios.post(`${process.env.REACT_APP_API}/pedidoCompraUpload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        //window.$('.modal-CompraImporta').modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()
                        
                        carregaAnexos()
                        setDescricaoAnexo('')
                        setFile('')

                        window.toastr.success('Arquivo anexado com sucesso!')



                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }

    const autorizaGestorArea = () => {


        var dataPost = {

            autorizado: autorizado == true ? false : true,
            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),

        }


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
            title: `Autorização`,
            content: 'Confirma autorizar esta solicitação?',
            buttons: {
                OK: function () {

                                

                    api.put(`pedidoAutorizaGestorArea/${pedidoCompra_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        //limpa()
                        //window.toastr.success('Apagado com sucesso!')
                        //window.$('.modal-ComprasPedido').modal('hide')
                        //handleScrollToTOp()
                        carregaPedido()
                        carregaPedidoHistorico()
                        props.carregaPedidos()

                        window.$.confirm({
                            //icon: 'fa fa-question',
                            theme: 'dark',
                            closeIcon: true,
                            animation: 'scale',
                            type: 'green',
                            title: `Autorização`,
                            content: 'Autorizado com sucesso!',
                            buttons: {
                                OK: function () {

                                    //pdfPedido(result.data.pedidoCompra_id)

                                }
                            },
                
                        });

                        

                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })


                }
            },

        });




    }


    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

          //console.log(result.data)

            setResultadoSolicitantes(result.data.map((rs) => 

                <option value={rs.solicitante}>{rs.solicitante}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



  const carregaSituacaoPedido = () => {

    api.get('statusPedidoCompra').then((result) => {

        //console.log(result.data)

        setResultadoStatusPedidoCompra(result.data.map((rs) => 

            <option value={rs.pedidoCompraStatus_id}>{rs.descricao}</option>
        
        ))

    }).catch((err) => {

        console.log(err.response)

    })

}




const handleScrollToTOp = () => {

    window.scrollTo({ top: 0, behavior: 'smooth'})

}



    useEffect(() => {

        carregaSolicitantes()

    }, [])


    const carregaPedido = () => {

        if (pedidoCompra_id) {

            //console.log(`pedidoCompra/${pedidoCompra_id}`)

            api.get(`pedidoCompra/${pedidoCompra_id}`).then((result) => {


                var data = result.data
                //console.log(data.itens, '!!')
                var dataAtendimentoF = ''

                if (data.dataAtendimento) {

                    var dateParts = data.dataAtendimento.split("/");
                    var dataAtendimentoF = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])

                }

                
                setModalidade_id(data.modalidade_id)
                setUsuarioId_comprador(data.usuario_id_comprador)
                setTipoProcesso(data.tipoProcesso)

                setNome(data.nomeSolicitante)
                setJustificativa(data.justificativa)
                setSolicitante(data.solicitante)
                setItens(data.itens)
                setNumeroProcesso(data.numeroProcesso)
                setPedidoCompraStatus_id_anterior(data.pedidoCompraStatus_id)
                setPedidoCompraStatus_id(data.pedidoCompraStatus_id)
                setNumeroPedido(data.numeroPedido == null ? '' : data.numeroPedido)
                setAutorizado(data.autorizado ? data.autorizado : false)
                setIdentificacao(data.identificacao)
                setDataAtendimentoF(dataAtendimentoF)
                setUrgente(data.urgente ? data.urgente : false)
                
                setNumeroDespacho(data.numeroDespacho)

                setCentroCusto_id(data.centroCusto_id)
                setCentroCustoDescri(data.centroCustoDescri)
                setRevisaoEC(data.revisaoEC)
                setFornecedorHomologado(data.fornecedorHomologado)
                setDispensaPesquisa(data.dispensaPesquisa)
                setCondicoesEspeciais(data.condicoesEspeciais)


                if (data.autorizado) {

                    setAutorizacao(`Em ${data.dataAutorizacao} por ${data.cpfAutorizacao} - ${data.nomeAutorizacao} \nChave ${data.chaveAutorizacao}`)

                }
                

                //setItens(data.itens)
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })


        }

    }

    useEffect(() => {

        props.urgentePop(urgente)

    }, [urgente])


    const apagaArquivo = (pedidoArquivoCompra_id) => {

        //console.log(pedidoArquivoCompra_id  )


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar este anexo desta solicitação?',
		    buttons: {
		        Sim: function () {


                                    
                        api.delete(`pedidoCompraUpload/${pedidoArquivoCompra_id}`).then((result) => {

                            //console.log(result.data)
                            carregaAnexos()
                            window.toastr.success('Anexo removido com sucesso!')
                            //props.carregaPedidos()
                            
                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const carregaAnexos = () => {

        if (pedidoCompra_id) {

            api.get(`pedidoCompraListaArquivos/${pedidoCompra_id}`).then((result) => {

                //console.log(result.data)
                setTotalAnexos(result.data.length)

                setResultadoAnexos(result.data.map((rs) =>

                    <tr>
                        
                        <td>{rs.descricao}</td>
                        <td style={{ textAlign: 'center'}}>
                            <button type="button" className="btn btn-inline btn-primary-outline"  onClick={() => window.open(`${process.env.REACT_APP_API}/upload/pedidoCompra/${rs.arquivoNome}`) }>Download</button>
                        </td>
                        <td > 

                            <button style={{ display: (pedidoCompraStatus_id == 7 || pedidoCompraStatus_id == 3) && perfil != 'Administrador' ? 'none' : 'table-row'}} type="button" className="btn btn-inline btn-danger-outline" onClick={() => apagaArquivo(rs.pedidoCompraArquivo_id)}>X</button>

                        </td>
                    </tr>                
                
                ))

            }).catch((err) => {

                console.log(err)

            })

        }


    }

    const carregaPedidoHistorico = () => {


        if (pedidoCompra_id) {


            api.get(`pedidoCompraListaHistorico/${pedidoCompra_id}`).then((result) => {

                //console.log(result.data)
                setTotalHistorico(result.data.length)

                setResultadoHistorico(result.data.map((rs) => 

                    <tr  style={{ fontSize: '11px', textAlign: 'center'}}>
                        <td>
                            {rs.ad_new}
                        </td>
                        <td>
                            {rs.status}
                        </td>
                        <td>
                            {rs.nomeSolicitante}
                        </td>
                        <td>
                            {rs.observacao}
                        </td>
                        
                        
                    </tr>

                ))

            }).catch((err) => {

                console.log(err.response)

            })



        }


    }


    const limpa = () => {

        setJustificativa('')
        setTipoItem('')
        setNumeroPedido('')
        setTotalHistorico(0)
        setTotalItens(0)
        setResultadoHistorico([])
        setPedidoCompraStatus_id(1)
        setAutorizacao('')
        setAutorizado(false)
        setNumeroProcesso()
        setDataAtendimentoF('')
        setIdentificacao('')
        setUrgente(false)
        setSolicitante(sessionStorage.getItem('solicitante'))
        setNome(sessionStorage.getItem('nome'))

        setFornecedorHomologado('')
        setRevisaoEC('')
        setCondicoesEspeciais('')
        setDispensaPesquisa('')
        setCentroCusto('')
        setCentroCustoDescri('')
        //setColorCentroCusto('white')
        setResultadoCentroCusto('')
        setCentroCusto_id(null)
        setModalidade_id('')
        setUsuarioId_comprador('')
        setTipoProcesso('')
        
        setItens([])

    }

    const carregaModalidades = () => {

        api.get('modalidades').then((result) => {

            setResultadoModalidades(result.data.map((rs) => 

                <option value={rs.modalidade_id}>{rs.modalidade}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        //console.log(pedidoCompra_id, '!')
        limpa()
        carregaPedido()
        carregaAnexos()
        carregaPedidoHistorico()
        carregaCompradores()
        carregaModalidades()

        window.$('[href="#tabs-4x-tab-1x"]').tab('show');
        
        
    }, [pedidoCompra_id])


    useEffect(() => {

        if (salvo) {

            props.resetId(pedidoCompra_id)

        }
        

    }, [pedidoCompra_id, salvo])


    useEffect(() => {

        carregaSituacaoPedido()

    }, [])


    const removeItem = (key, id) => {


        if (id) {

            setItens(itens.filter(item => item.itemPedido_id !== key));
            
        } else {

            setItens(itens.filter(item => item.key !== key));

        }
        
        

    }


    const transformarDemanda = () => {


        if (numeroProcesso == '' || numeroProcesso == null) {

            window.toastr.error('<b>Número de processo</b> não pode ficar em branco!')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: 'Confirma transformar os itens em demandas de compras?',
		    buttons: {
		        Sim: function () {

                        var dataPost = {

                            usuario: sessionStorage.getItem('nome'),
                            numeroProcesso: numeroProcesso,

                        }

                        //console.log(dataPost)
                                    
                        api.post(`pedidoCompraDemanda/${pedidoCompra_id}`, dataPost).then((result) => {

                            //console.log(result.data)

                            carregaPedido()
                            props.carregaPedidos()

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: `Demandas criadas`,
                                content: 'Os item(ns) foram criados em demandas de compras.',
                                buttons: {
                                    OK: function () {

                                        //pdfPedido(result.data.pedidoCompra_id)

                                    }
                                },
                    
                            });

                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const autorizar = () => {


        var dataPost = {

            autorizado: autorizado == true ? false : true,
            nome: sessionStorage.getItem('nome'),
            cpf: sessionStorage.getItem('cpf'),
            tipoProcesso: tipoProcesso,

        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
		    title: 'Confirmação',
		    content: `Confirma ${ autorizado ? 'desfazer a autorização' : 'autorizar' }?`,
		    buttons: {
		        Sim: function () {


                                    
                        api.put(`pedidoAutoriza/${pedidoCompra_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            //limpa()
                            //window.toastr.success('Apagado com sucesso!')
                            //window.$('.modal-ComprasPedido').modal('hide')
                            //handleScrollToTOp()
                            carregaPedido()
                            carregaPedidoHistorico()
                            props.carregaPedidos()

                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: `Autorização`,
                                content: `${ autorizado ? 'Solicitação com autorização desfeita' : 'Pedido autorizado' }`,
                                buttons: {
                                    OK: function () {

                                        pdfPedido(result.data.pedidoCompra_id)

                                    }
                                },
                    
                            });

                            

                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    const apagar = () => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {


                                    
                        api.delete(`pedidoCompra/${pedidoCompra_id}`).then((result) => {

                            //console.log(result.data)
                            limpa()
                            window.toastr.success('Apagado com sucesso!')
                            //window.$('.modal-ComprasPedido').modal('hide')
                            

                            props.carregaPedidos()
                            setPedidoCompra_id()
                            
                            
                            
                            props.show(false)
                            props.carregaPedidosDevolvidos()
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })



                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

    }




    const pdfPedido = (pedidoCompra_id) => {


        //console.log(pedidoCompra_id, 'aqui')
        api.get(`pedidoGeraPdf/${pedidoCompra_id}`).then((result) => {

            console.log(result.data)

            window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)

        }).catch((err) => {

            console.log(err.response)

        })

    }
    

    const salvar = () => {

        if (justificativa == '') {

            window.toastr.error('<b>Justificativa</b> não pode ficar em branco!')
            return false

        }

        if (solicitante == '') {

            window.toastr.error('<b>Solicitante</b> não pode ficar em branco!')
            return false

        }

        if ((centroCusto_id == '' || centroCusto_id == null) && (pedidoCompra_id == null || pedidoCompra_id == '')) {

            window.toastr.error('<b>Centro de Custo</b> não pode ficar em branco!')
            return false

        }

        if (itens.length <= 0) {

            window.toastr.error('Por favor, informe ao menos um item para fazer o pedido!')
            return false

        }

        if (pedidoCompraStatus_id == '') {

            window.toastr.error('<b>Situação</b> não pode ficar em branco!')
            return false

        }


        //if (pedidoCompra_id == null) {

            if (dataAtendimentoF != '' && dataAtendimentoF != null) {

                
                if (moment(dataAtendimentoF, "DD/MM/YYYY", true).isValid() == false) {
    
                    window.toastr.error('<b>Data de atendimento</b> inv&aacute;lida!');
                    return false;
    
                } else {
    
                    //console.log('aqui')
                    
                    var dataHoje = moment().format('YYYY-MM-DD');
    
                    
    
                    var dataAtendimentoF_array = moment(dataAtendimentoF).format('YYYY-MM-DD').split('/')
                    var dataAtendimentoF2 = dataAtendimentoF_array[2] + '-' + dataAtendimentoF_array[1] + '-' + dataAtendimentoF_array[0]
    
                    var dataAtendimentoF3 = new Date(dataAtendimentoF2)
    
                    //console.log(dataAtendimentoF3, dataHoje)  
                    if (moment(dataAtendimentoF3, 'YYYY-MM-DD').diff(dataHoje, 'days') < 0) {
                        console.log(moment(dataAtendimentoF3, 'YYYY-MM-DD').diff(dataHoje, 'days') )
                        window.toastr.error('<b>Data de atendimento</b> não pode ser anterior a data atual!');
                        return false;
    
                    }
    
                    // Compare the two dates
                    //var diffInDays = dataAtendimentoF2.diff(dataHoje, 'days'); // returns 2
                    //console.log(diffInDays , 'dias')
                    
    
    
                }
    
            } else {
                //console.log(moment(dataAtendimentoF, "DD/MM/YYYY", true).isValid(), 'aqui')
                window.toastr.error('<b>Data de atendimento</b> inv&aacute;lida!');
                return false;
                
            }
    
    

        //} else {

        //    var dataAtendimentoF2 = ''

        //}
        
        
        

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {


                    if (pedidoCompra_id) {

                        //console.log('aqui!')
                        //return false


                        var dataPost = {

                            solicitante: solicitante,
                            justificativa: justificativa,
                            identificacao: identificacao,
                            nomeSolicitante: nome,
                            pedidoCompraStatus_id: pedidoCompraStatus_id,
                            pedidoCompraStatus_id_anterior: pedidoCompraStatus_id_anterior,
                            centroCusto_id: centroCusto_id,
                            dataAtendimento: dataAtendimentoF2,
                            itens: itens,
                            
                            nomeUsuario: sessionStorage.getItem('nome'),
                                        

                        }

                        //console.log(dataPost)
                        //return false

                        api.put(`pedidoCompra/${pedidoCompra_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            window.toastr.success('Salvo com sucesso!')


                            if (pedidoCompraStatus_id == 2) {

                                
                                window.$('[href="#tabs-4-tab-5x"]').tab('show');


                                window.$.confirm({
                                    //icon: 'fa fa-question',
                                    theme: 'dark',
                                    closeIcon: true,
                                    animation: 'scale',
                                    type: 'blue',
                                    title: 'Ok',
                                    content: 'Por favor complemente as informações de recebimento',
                                    buttons: {
                                        Ok: function () {

                                        },
                                    },

                                });

                            }


                            carregaPedidoHistorico()
                            props.carregaPedidos()
                            setPedidoCompra_id()
                            
                            limpa()
                            
                            props.show(false)
                            props.carregaPedidosDevolvidos()
                            

                        }).catch((err) => {

                            console.log(err.response)

                        })

                    } else {


                                        
                        var dataPost = {

                            solicitante: solicitante,
                            justificativa: justificativa,
                            nomeSolicitante: nome,
                            pedidoCompraStatus_id: pedidoCompraStatus_id,
                            dataAtendimento: dataAtendimentoF2,
                            identificacao: identificacao,
                            urgente: urgente,
                            itens: itens,
                            centroCusto_id: centroCusto_id,
                            revisaoEC: revisaoEC,
                            condicoesEspeciais: condicoesEspeciais,
                            fornecedorHomologado: fornecedorHomologado,
                            dispensaPesquisa: dispensaPesquisa,
                            gestorArea: gestorArea == 'true' ? true : false,
                                        

                        }


                        //console.log(dataPost)
                        //return false

                                    
                        api.post('pedidoCompra', dataPost).then((result) => {

                            //console.log(result.data)
                            

                            
                            var numeroPedido = result.data.numeroPedido
                            setPedidoCompra_id(result.data.pedidoCompra_id)

                            limpa()
                            window.toastr.success('Salvo com sucesso!')
                            
                            props.carregaPedidos()
                            
                            //return false


                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: `Solicitação ${numeroPedido} salvo com sucesso!`,
                                content: `Deseja anexar documentos a este pedido?`,
                                buttons: {
                                    Sim: function () {

                                        //pdfPedido(result.data.pedidoCompra_id)
                                        //props.resetId(result.data.pedidoArquivoCompra_id)
                                        setSalvo(true)
                                        window.$('[href="#tabs-4x-tab-4x"]').tab('show');

                                    },
                                    Nao: function() {

                                        setPedidoCompra_id()
                                        limpa()
                                        
                                        props.show(false)
                                        
                                    }
                    
                                },
                    
                            });

                        }).catch((err) => {

                            console.log(err.response)

                        })




                    }

                     

                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }


    const adicionaItem = () => {


        if (quantidade == '') {

            window.toastr.error('<b>Quantidade</b> não pode ficar em branco!')
            return false

        }

        

        if (tipoItem == 'M') {

            if (origemItem == 'C') {


                if (item_id == '' || item_id == null) {

                    window.toastr.error('<b>Item</b> não pode ficar em branco!')
                    return false
        
                }

            } else {


                if (descricaoServico == '') {

                    window.toastr.error('<b>Descrição do item</b> não pode ficar em branco!')
                    return false

                }

                if (unidFornecimento == '') {

                    window.toastr.error('<b>Unidade de Fornecimento</b> não pode ficar em branco!')
                    return false

                }

            }


        }

        //console.log(materiaPrima_id, 'aqui')
        //return false

        if (tipoItem == 'P') {

            if (materiaPrima_id == '' || materiaPrima_id == null) {

                window.toastr.error('<b>Item</b> não pode ficar em branco!')
                return false
    
            }

        }


        if (tipoItem == 'S') {


            if (descricaoServico == '') {

                window.toastr.error('<b>Descrição</b> não pode ficar em branco!')
                return false
    
            }

        }

        if (tipoItem == 'O') {

            if (opme_id == '' || opme_id == null) {

                window.toastr.error('<b>Item</b> não pode ficar em branco!')
                return false
    
            }

        }


        var dataPush = {

            tipoItem: tipoItem,
            item_id: tipoItem == 'M' || tipoItem == 'P' ? item_id : null,
            origemItem: origemItem,
            materiaPrima_id: tipoItem == 'P' || origemItem == 'C' ? materiaPrima_id : null,
            unidFornecimento: tipoItem == 'M' || tipoItem == 'P' ? unidFornecimento : null,
            itemDescri: itemDescri,
            materiaPrimaDescri: materiaPrimaDescri,
            quantidade: quantidade,
            siafisicoPregao: siafisicoPregao,
            descricaoServico: tipoItem == 'S' || tipoItem == 'I' || (tipoItem == 'M' && origemItem == 'F') ? descricaoServico : null,

            opmeDescri: opmeDescri,

            revisaoEC: revisaoEC,
            condicoesEspeciais: condicoesEspeciais,
            fornecedorHomologado: fornecedorHomologado,
            dispensaPesquisa: dispensaPesquisa,
            unidFornecimento: unidFornecimento,
            opme_id: opme_id,
            
            key: uuidv4(),

        }

        //console.log(dataPush)
        //return false
        
        setItem_id()
        setItemDescri('')
        setMateriaPrimaDescri('')
        setMateriaPrima_id()
        setDescricaoServico('')
        setUnidFornecimento('')
        setOrigemItem('')
        setQuantidade('')
        setRevisaoEC('')
        setCondicoesEspeciais('')
        setFornecedorHomologado('')
        setDispensaPesquisa('')
        setUnidFornecimento('')
        
        setOpme_id('')
        setOpmeDescri('')
        //setTipoItem('')
        
        setItens([...itens, dataPush])

        window.toastr.info('Item adicionado ao pedido!')

    }


    const carregaCompradores = () => {

        api.get('compradores').then((result) => {

            setResultadoCompradores(result.data.map((rs) => 

                <option value={rs.usuario_id}>{rs.nome}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }




    useEffect(() => {

        //console.log(itens, '!!')

        if (itens) {


            setResultadoItens(itens.map((rs) =>

            <tr>
                <td style={{ textAlign: 'center'}}>
                    {rs.tipoItem == 'O' ? 'OPME' : rs.tipoItem == 'S' ? 'Serviço' : rs.tipoItem == 'M' ? 'Material' : rs.tipoItem == 'P' ? 'Matéria-prima' : 'Investimento'}
                </td>
                <td>
                    {rs.tipoItem == 'O' ? rs.opmeDescri : rs.tipoItem == 'S' || rs.tipoItem == 'I' || (rs.tipoItem == 'M' && rs.origemItem == 'F') ? rs.descricaoServico : rs.tipoItem == 'P' ? rs.materiaPrimaDescri : rs.siafisicoPregao + ' - ' + rs.itemDescri}
                </td>
                <td style={{ textAlign: 'center'}}>
                    {rs.tipoItem == 'S' || rs.tipoItem == 'I' ? 'N/A' : ((rs.tipoItem == 'M' && rs.origemItem == 'F') || rs.tipoItem == 'P' || rs.tipoItem == 'O')  && pedidoCompra_id ? rs.unidFornecimentoMateriaPrima : rs.unidFornecimento}
                    
                </td>
                <td style={{ textAlign: 'right'}}>
                    {rs.quantidade.toLocaleString('pt-BR')}
                </td>
                <td style={{ textAlign: 'center'}}>
                    <button type="button" className="btn btn-inline-sm btn-danger" style={{ display: pedidoCompra_id && pedidoCompraStatus_id != 11 ? 'none' : 'table-row'}}  onClick={() => removeItem(rs.key ? rs.key : rs.itemPedido_id)}>X</button>
                </td>
            </tr>
        
        ))

        }

        setTotalItens(itens.length)

    }, [itens])


    useEffect(() => {

        if (item_id) {

            setColor('orange')

        } else {

            setColor('white')

        }
        

    }, [item_id])


    useEffect(() => {

        if (centroCusto_id) {

            setColorCentroCusto('orange')

        } else {

            setColorCentroCusto('white')

        }
        

    }, [centroCusto_id])

    useEffect(() => {

        if (materiaPrima_id) {

            setColorMateriaPrima('orange')

        } else {

            setColorMateriaPrima('white')

        }
        

    }, [materiaPrima_id])



    useEffect(() => {

        if (opme_id) {

            setColorOpme('orange')

        } else {

            setColorOpme('white')

        }
        

    }, [opme_id])


    useEffect(() => {

        if (itemDescri != '') {

            var dataPost = {

                itemDescri: itemDescri,

            }

            api.post('buscaItem', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setItem_id(rs.item_id);setSiafisicoPregao(rs.siafisicoPregao);setDescricao(rs.descricao);setUnidFornecimento(rs.unidFornecimento);setCodigoMestreMV(rs.codigoMestreMV);setCodigoFilhoMV(rs.codigoFilhoMV);setUnidFornecimento(rs.unidFornecimento);setItemDescri(`${rs.siafisicoPregao} - ${rs.descricao}`)}}><font color='purple'>[{rs.siafisicoPregao}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemDescri])



    useEffect(() => {

        if (opmeDescri != '') {

            var dataPost = {

                opmeDescri: opmeDescri,

            }

            api.post('buscaOPME', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoOpme(result.data.map((rs) =>
                
                    <Link onClick={() => {setOpme_id(rs.opme_id);setOpmeDescri(`${rs.codigo} - ${rs.descricao}`)}}><font color='purple'>[{rs.codigo}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

                setUnidFornecimento('Unidade')

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [opmeDescri])


    useEffect(() => {

        //console.log(pedidoCompra_id, pedidoCompraStatus_id)

        //if (centroCustoDescri != '' && ((pedidoCompra_id == null || pedidoCompra_id == '') && pedidoCompraStatus_id == 11)) {
        if (centroCustoDescri != '' && centroCusto_id == null) {

            var dataPost = {

                centroCustoDescri: centroCustoDescri,

            }

            api.post('buscaCentroCusto', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoCentroCusto(result.data.map((rs) =>
                
                    <Link onClick={() => {setResultadoCentroCusto('');setCentroCusto_id(rs.centroCusto_id);setCentroCusto(rs.centroCusto);setCentroCustoDescri(`${rs.centroCusto} - ${rs.descricao}`)}}><font color='purple'>[{rs.centroCusto}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [centroCustoDescri])




    useEffect(() => {

        if (materiaPrimaDescri != '') {

            var dataPost = {

                descricao: materiaPrimaDescri,

            }

            api.post('buscaMateriaPrima', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoMateriaPrima(result.data.map((rs) =>
                
                    <Link onClick={() => {setMateriaPrima_id(rs.materiaPrima_id);setMateriaPrimaDescri(`${rs.codigo} - ${rs.descricao}`)}}><font color='purple'>[{rs.codigo}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [materiaPrimaDescri])



    const geraDespacho = () => {

        //window.$('#modalDespacho').modal('show')


        if (pedidoCompra_id) {

            window.$.confirm({
                //icon: 'fa fa-question',
                theme: 'dark',
                closeIcon: true,
                animation: 'scale',
                type: 'blue',
                title: 'Confirmação',
                content: numeroDespacho == null ? 'Confirma gerar o despacho?' : 'Confirma download do despacho?',
                buttons: {
                    OK: function () {

                        //pdfPedido(result.data.pedidoCompra_id)


                        api.post(`/pedidoCompraDespachoPDF/${pedidoCompra_id}`).then((result) => {

                            //console.log(result.data)
                            window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)
                            //window.$('#modalDespacho').modal('hide')

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    },
                    Cancelar: function () {


                    }
                },
    
            });


        }


    }



    const salvaDadosRecebimento = () => {

        if (usuario_id_comprador == '')  {

            window.toastr.error('<b>Comprador</b> não pode ficar em branco!')
            return false

        }

        if (modalidade_id == '')  {

            window.toastr.error('<b>Modalidade</b> não pode ficar em branco!')
            return false

        }

        if (tipoProcesso == '')  {

            window.toastr.error('<b>Tipo de processo</b> não pode ficar em branco!')
            return false

        }

        var dataPost = {

            usuario_id_comprador: usuario_id_comprador,
            modalidade_id: modalidade_id,
            tipoProcesso: tipoProcesso,
            nome: sessionStorage.getItem('nome'),
            autorizado: autorizado,

        }

        //console.log(dataPost)


        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'blue',
            title: 'Confirmação',
            content: `Confirma salvar os dados de recebimento?`,
            buttons: {
                OK: function () {

                    //pdfPedido(result.data.pedidoCompra_id)

                    api.put(`pedidoComprasRecebimento/${pedidoCompra_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        carregaPedido()
                        props.carregaPedidos()
                        window.toastr.success('Registro salvo com sucesso!')
        
        
                    }).catch((err) => {
        
                        console.log(err.response)
        
                    })

                },
                Cancelar: function () {


                }
            },

        });




    }


    const salvaProcesso = () => {


        if (pedidoCompra_id) {

            var dataPost = {

                numeroProcesso: numeroProcesso,

            }

            window.$.confirm({
                //icon: 'fa fa-question',
                theme: 'dark',
                closeIcon: true,
                animation: 'scale',
                type: 'blue',
                title: 'Confirmação',
                content: `Confirma a autuação do processo?`,
                buttons: {
                    OK: function () {

                        //pdfPedido(result.data.pedidoCompra_id)

                        api.put(`pedidoCompraProcesso/${pedidoCompra_id}`, dataPost).then((result) => {

                            //console.log(result.data)
                            carregaPedido()
                            props.carregaPedidos()
                            
                            window.$.confirm({
                                //icon: 'fa fa-question',
                                theme: 'dark',
                                closeIcon: true,
                                animation: 'scale',
                                type: 'green',
                                title: 'Processo salvo',
                                content: 'Deseja salvar o PDF da capa do processo?',
                                buttons: {
                                    Sim: function () {
            
                                        //pdfPedido(result.data.pedidoCompra_id)
                                        capaProcesso()

            
                                    },
                                    Cancelar: function () {



                                    }
                                },
                    
                            });
            
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })

                    },
                    Cancelar: function () {


                    }
                },
    
            });


        }

    }


    const ValidaDataUrgente = async () => {



        if (dataAtendimentoF != null && dataAtendimentoF != '' && (pedidoCompra_id == null || pedidoCompra_id == '')) {
            

            if (moment(dataAtendimentoF, "DD/MM/YYYY", true).isValid() == true) {

                
                var dataHoje = moment().format('YYYY-MM-DD');
                

                var dataAtendimentoF_array = moment(dataAtendimentoF).format('YYYY-MM-DD').split('/')
                

                var dataAtendimentoF2 = dataAtendimentoF_array[2] + '-' + dataAtendimentoF_array[1] + '-' + dataAtendimentoF_array[0]

                var dataAtendimentoF3 = new Date(dataAtendimentoF2)

                //console.log(dataAtendimentoF3, 'aquii')
                //return false

                //console.log(dataAtendimentoF3, dataHoje)  
                if (moment(dataAtendimentoF3, 'YYYY-MM-DD').diff(dataHoje, 'days') < 0) {
                    //console.log(moment(dataAtendimentoF3, 'YYYY-MM-DD').diff(dataHoje, 'days') )
                    window.toastr.error('<b>Data de atendimento</b> não pode ser anterior a data atual!');
                    return false;

                } else {

                    if (moment(dataAtendimentoF3, 'YYYY-MM-DD').diff(dataHoje, 'days') <= 3) {

                        setUrgente(true)

                    } else {

                        setUrgente(false)

                    }

                }

                


            } else {

                //window.toastr.error('<b>Data de atendimento</b> inválida!');

            }


        }



    }

    const capaProcesso = () => {


        api.get(`pedidoCompraCapaPDF/${pedidoCompra_id}`).then((result) => {

            //console.log(result.data)
            window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
            //window.$('#modalDespacho').modal('hide')

        }).catch((err) => {

            console.log(err.response)

        })



    }

    useEffect(() => {

        ValidaDataUrgente()
        
    }, [dataAtendimentoF])


    return (


        <div>

                

                        <div>
                            <section className="tabs-section">
                                <div className="tabs-section-nav tabs-section-nav-inline">
                                    <ul className="nav" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" href="#tabs-4x-tab-1x" role="tab" data-toggle="tab">
                                                Detalhes
                                            </a>
                                        </li>

                                        <li className="nav-item" style={{ display: (perfil == 'Finanças' || perfil == 'Administrador' || perfil == 'Compras') && pedidoCompra_id ? 'table-row' : 'none' }}>
                                            <a className="nav-link" href="#tabs-4x-tab-5x" role="tab" data-toggle="tab">
                                                
                                                Recebimento

                                            </a>
                                        </li>
                                            

                                        <li className="nav-item" style={{ display: pedidoCompra_id ? 'table-row' : 'none'  }}>
                                            <a className="nav-link" href="#tabs-4x-tab-4x" role="tab" data-toggle="tab">
                                                Anexos&nbsp;<span class="label label-pill label-warning">{totalAnexos}</span>
                                                
                                            </a>
                                        </li>

                                        <li className="nav-item" style={{ display: pedidoCompraStatus_id == 4 || numeroProcesso != null ? 'table-row' : 'none' }}>
                                            <a className="nav-link" href="#tabs-4x-tab-3x" role="tab" data-toggle="tab">
                                                Processo
                                                
                                            </a>
                                        </li>

                                        <li className="nav-item" style={{ display: pedidoCompra_id ? 'table-row' : 'none' }}>
                                            <a className="nav-link" href="#tabs-4x-tab-2x" role="tab" data-toggle="tab">
                                                Hist&oacute;rico&nbsp;<span class="label label-pill label-info">{totalHistorico}</span>
                                                
                                            </a>
                                        </li>

                                        
                                        
                                        
                                    </ul>
                                </div>

                                <div className="tab-content">
                                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4x-tab-1x">


                                        <div className="row">
                                            <div className="col-md-12" style={{ display: pedidoCompra_id ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Número pedido</label>
                                                    <input type="text" className="form-control" value={numeroPedido} disabled style={{ backgroundColor: '#cdcbf2'}} />
                                                </fieldset>
                                            </div>

                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label">Solicitante</label>
                                                    <input type="text" className="form-control" value={nome} disabled />
                                                </fieldset>
                                            </div>
                                        
                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Área solicitante *</label>
                                                    <select className="form-control" value={solicitante} disabled={ pedidoCompra_id ? true : perfil == 'Administrador' || perfil == 'Compras' ? false : true} onChange={event => setSolicitante(event.target.value)} >
                                                        <option value="">[Selecione]</option>
                                                        {resultadoSolicitantes}
                                                    
                                                    </select>
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Identificação</label>
                                                    <input className="form-control" value={identificacao} maxlength={25} disabled={ pedidoCompra_id && pedidoCompraStatus_id != 11 ? true : false} onChange={event => setIdentificacao(event.target.value)} />
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Data atendimento *</label>
                                                    
                                                    <DatePicker   selected={dataAtendimentoF || null} disabled={ pedidoCompra_id  && pedidoCompraStatus_id != 11 ? true : false} onChange={(date) => setDataAtendimentoF(date)} className="form-control" locale="ptBR" dateFormat="dd/MM/yyyy" placeHolder="DD/MM/AAAA" 
                                                    customInput={ <MaskedInput mask="11/11/1111" placeholder="mm/dd/yyyy" style={{ backgroundColor: pedidoCompra_id && pedidoCompraStatus_id != 11 ? '#EFEFEF' : '#FFF'}} /> } 
                                                    />
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            


                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Justificativa *</label>
                                                    <textarea  className="form-control" rows="4" maxlength="500" value={justificativa} onChange={event => setJustificativa(event.target.value)} disabled={ pedidoCompra_id && pedidoCompraStatus_id != 11 ? true : false} />
                                                    
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-12" >
                                                
                                                
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Centro de Custo *</label>
                                                    <textarea type="text" placeholder='Busque pelo Centro de Custo...' className="form-control" style={{ backgroundColor: colorCentroCusto }} value={centroCustoDescri} onChange={event => setCentroCustoDescri(event.target.value)} onFocus={() => {setCentroCusto_id();setCentroCustoDescri('');setCentroCusto('')}} disabled={ pedidoCompra_id && pedidoCompraStatus_id != 11 ? true : false }/>
                                                    {resultadoCentroCusto}
                                                    
                                                </fieldset>

                                            </div>  

                                            

                                            <div className="col-md-12" style={{ display: pedidoCompra_id ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Situação</label>
                                                    <select className="form-control" value={pedidoCompraStatus_id} onChange={event => setPedidoCompraStatus_id(event.target.value)} disabled={ pedidoCompraStatus_id == 7 || pedidoCompraStatus_id == 3 ? true : perfil == 'Administrador' || perfil == 'Compras' ? false : true}>
                                                        <option value="">[Selecione]</option>
                                                        {resultadoStatusPedidoCompra}
                                                    
                                                    </select>
                                                        
                                                    
                                                </fieldset>
                                            </div>


                                            <div className="col-md-12" style={{ display: autorizado == true ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Autorizado</label>
                                                    <textarea rows="2" className="form-control" value={autorizacao} disabled style={{ backgroundColor: '#e9f2cb'}}  />
                                                </fieldset>
                                            </div>



                                            <div className="col-md-12" style={{ display: pedidoCompra_id && pedidoCompraStatus_id != 11 ? 'none' : 'table-row'}}>
                                                
                                                <br/><br/>
                                                <fieldset className="form-group">
                                                    <label className="form-label" >Tipo de item</label>
                                                    <select class="form-control" value={tipoItem} onChange={event => setTipoItem(event.target.value)}>

                                                        <option value="">[Selecione]</option>
                                                        <option value="M">Material de Consumo</option>
                                                        <option value="S">Serviço</option>
                                                        <option value="I">Investimento</option>
                                                        <option value="O">OPME</option>
                                                        <option value="P" style={{ display: solicitante == 'Bioengenharia' ? 'table-row' : 'none'}}>Matéria-prima</option>

                                                    </select>
                                                    
                                                        
                                                    
                                                </fieldset>
                                            </div>



                                            <div className="col-md-12" style={{ display: tipoItem == 'M' ? 'block' : 'none'}}>


                                                <fieldset className="form-group">
                                                    <label className="form-label" >Tipo de item</label>
                                                    <select class="form-control" value={origemItem} onChange={event => setOrigemItem(event.target.value)}>

                                                        <option value="">[Selecione]</option>
                                                        <option value="C">Item do catálogo</option>
                                                        <option value="F">Item fora do catálogo</option>
                                                        
                                                    </select>
                                                    
                                                        
                                                    
                                                </fieldset>


                                            </div>


                                            <div className="col-md-12" style={{ display: tipoItem == 'S' || tipoItem == 'I' || (tipoItem == 'M' && origemItem == 'F') ? 'block' : 'none'}}>


                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Descrição</label>
                                                    <textarea type="text" className="form-control" value={descricaoServico} onChange={event => setDescricaoServico(event.target.value)} />
                                                    {resultadoBusca}
                                                    
                                                </fieldset>


                                            </div>

                                            <div className="col-md-12" style={{ display: tipoItem == 'P' ? 'block' : 'none'}}>
                                                
                                                
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Item</label>
                                                    <textarea type="text" placeholder='Busque pelo item...' className="form-control" style={{ backgroundColor: colorMateriaPrima }} value={materiaPrimaDescri} onChange={event => setMateriaPrimaDescri(event.target.value)} onFocus={() => {setMateriaPrimaDescri('');setMateriaPrima_id()}} />
                                                    {resultadoBuscaMateriaPrima}
                                                    
                                                </fieldset>

                                            </div>

                                            <div className="col-md-12" style={{ display: tipoItem == 'O' ? 'block' : 'none'}}>
                                                
                                                
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Item</label>
                                                    <textarea type="text" placeholder='Busque pelo item...' className="form-control" style={{ backgroundColor: colorOpme }} value={opmeDescri} onChange={event => setOpmeDescri(event.target.value)} onFocus={() => {setOpmeDescri('');setOpme_id()}} />
                                                    {resultadoOpme}

                                                    
                                                    
                                                </fieldset>

                                            </div>

                                            <div className="col-md-12" style={{ display: tipoItem == 'M' && origemItem == 'C'  ? 'block' : 'none'}}>
                                                
                                                
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Item</label>
                                                    <textarea type="text" placeholder='Busque pelo item...' className="form-control" style={{ backgroundColor: color }} value={itemDescri} onChange={event => setItemDescri(event.target.value)} onFocus={() => {setUnidFornecimento('');setItemDescri('');setItem_id()}} />
                                                    {resultadoBusca}
                                                    
                                                </fieldset>

                                            </div>
                                            <div className="col-md-3" style={{ display: tipoItem == 'P' ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Revisão da EC</label>
                                                    <input className="form-control" value={revisaoEC} maxlength={25} disabled={ pedidoCompra_id ? true : false} onChange={event => setRevisaoEC(event.target.value)} />
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-9" style={{ display: tipoItem == 'P' ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Espec. e condições especiais de fornecimento</label>
                                                    <select className="form-control" value={condicoesEspeciais} disabled={ pedidoCompra_id ? true : false} onChange={event => setCondicoesEspeciais(event.target.value)} >
                                                        <option value={null}>[Selecione]</option>
                                                        <option value={true}>Sim</option>
                                                        <option value={false}>Não</option>
                                                    </select>
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6" style={{ display: tipoItem == 'P' ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Fornecedor Homologado</label>
                                                    <select className="form-control" value={fornecedorHomologado} disabled={ pedidoCompra_id ? true : false} onChange={event => setFornecedorHomologado(event.target.value)} >
                                                        <option value={null}>[Selecione]</option>
                                                        <option value={true}>Sim</option>
                                                        <option value={false}>Não</option>
                                                    </select>
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6" style={{ display: tipoItem == 'P' ? 'table-row' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Dispensa de pesquisa de mercado</label>
                                                    <select className="form-control" value={dispensaPesquisa} disabled={ pedidoCompra_id ? true : false} onChange={event => setDispensaPesquisa(event.target.value)} >
                                                        <option value={null}>[Selecione]</option>
                                                        <option value={true}>Sim</option>
                                                        <option value={false}>Não</option>
                                                    </select>
                                                        
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-6" style={{ display: tipoItem == 'M' || tipoItem == 'P' || tipoItem == 'O' ? 'block' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Unidade Fornecimento</label>
                                                    <input type="text" className="form-control" value={unidFornecimento} disabled={ tipoItem == 'P' || tipoItem == 'O' || (tipoItem == 'M' && origemItem == 'F' ) ? false : true} onChange={event => setUnidFornecimento(event.target.value)} />
                                                    
                                                        
                                                    
                                                </fieldset>
                                            </div>
                                            <div className="col-md-6" style={{ display: tipoItem == 'M' || tipoItem == 'S' || tipoItem == 'P' || tipoItem == 'O' || tipoItem == 'I' ? 'block' : 'none'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Quantidade</label>
                                                    <input type="text" className="form-control" value={quantidade} onChange={event => setQuantidade(event.target.value)} 
                                                    
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }} 
                                                    
                                                    
                                                    />
                                                    
                                                        
                                                    
                                                </fieldset>
                                            </div>
                                            <div className="col-md-12" style={{ display: tipoItem == 'M' || tipoItem == 'P' || tipoItem == 'S' || tipoItem == 'I' || tipoItem == 'O' ? 'block' : 'none'}}>
                                                <div align="right">
                                                <button type="button" class="btn btn-inline btn-info" onClick={() => adicionaItem()} >+ Item</button>
                                                </div>
                                            </div>



                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" for="exampleError">Itens <span class="label label-pill label-primary">{totalItens}</span></label>


                                                    <table className="table table-hover">
                                                        <thead>
                                                            <tr>
                                                            <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Tipo

                                                            </th>
                                                            <th style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                                
                                                                Descrição                                

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                                
                                                                Unid. Fornecimento

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                                                
                                                                Qtde.

                                                            </th>
                                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'right'}}>
                                                                
                                                                &nbsp;

                                                            </th>
                                                            
                                                        </tr>
                                                                
                                                        </thead>
                                                        <tbody>

                                                            {resultadoItens}
                                                            
                                                        </tbody>
                                                    
                                                    </table>
                                            
                                                        
                                                    
                                                </fieldset>
                                            </div>


                                        </div>


                                    </div>
                                    <div role="tabpanel" className="tab-pane fade" id="tabs-4x-tab-2x">

                                        <table id="table-sm" class="table table-bordered table-hover table-sm">
                                            <thead>
                                                    <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                        
                                                        <th>
                                                            
                                                        Data                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Situação                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Usuário                                 

                                                        </th>
                                                        <th>
                                                            
                                                        Obs.

                                                        </th>
                                                      
                                                    </tr>
                                                    
                                            </thead>
                                            {resultadoHistorico}
                                            
                                        </table>

                                    </div>
                                    <div role="tabpanel" className="tab-pane fade" id="tabs-4x-tab-3x">

                                        <div className="row">
                                            
                                            <div className={ pedidoCompraStatus_id == 7 ? 'col-md-9' : 'col-md-6'} >
                                                <fieldset className="form-group">
                                                    <label className="form-label">Número processo (Ano/Número)</label>
                                                    <input type="text" className="form-control" value={numeroProcesso} maxLength={50} onChange={event => setNumeroProcesso(event.target.value)} disabled={ (perfil == 'CDOC' || perfil == 'Administrador') && tipoProcesso != 'D' && pedidoCompraStatus_id != 7 ? false : true} />
                                                </fieldset>
                                            </div>
                                            <div className="col-md-3" style={{ display: pedidoCompraStatus_id == 7 ? 'none' : 'table-row'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">&nbsp;</label>
                                                    <button type="button" className="btn btn-inline btn-info-outline" onClick={() => salvaProcesso()} style={{ display: (perfil == 'CDOC' || perfil == 'Administrador') && tipoProcesso != 'D'  ? 'table-row' : 'none'}} >Salvar processo</button>
                                                    

                                                </fieldset>
                                            </div>
                                            <div className="col-md-3" style={{ display: pedidoCompraStatus_id == 7 ? 'none' : 'table-row'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">&nbsp;</label>
                                                    <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => capaProcesso()} >PDF Capa</button>
                                                    

                                                </fieldset>
                                            </div>
                                            
                                        </div>

                                    </div>


                                    <div role="tabpanel" className="tab-pane fade" id="tabs-4x-tab-5x">

                                        
                                        <div className="row">
                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label">Comprador</label>
                                                    <select className="form-control" value={usuario_id_comprador} onChange={event => setUsuarioId_comprador(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false }>
                                                        <option value="">[Selecione]</option>
                                                        {resultadoCompradores}
                                                    
                                                    </select>

                                                </fieldset>
                                            </div>

                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" >Modalidade</label>
                                                    <select className="form-control" value={modalidade_id} onChange={event => setModalidade_id(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false } >
                                                        <option value="">[Selecione]</option>
                                                        {resultadoModalidades}
                                                    
                                                    </select>
                                                        
                                                    
                                                </fieldset>
                                            </div>

                                            <div className="col-md-12">
                                                <fieldset className="form-group">
                                                    <label className="form-label" >Tipo de processo</label>
                                                    <select className="form-control" value={tipoProcesso} onChange={event => setTipoProcesso(event.target.value)} disabled={ perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? true : false } >
                                                        <option value="">[Selecione]</option>
                                                        <option value="F">Físico</option>
                                                        <option value="D">Digital</option>
                                                    
                                                    </select>
                                                        
                                                    
                                                </fieldset>
                                            </div>


                                            <div className="col-md-12" >
                                                <fieldset className="form-group">

                                                    <div align="right">
                                                        <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvaDadosRecebimento() } >Salvar dados recebimento</button>
                                                    </div>
                                                    

                                                </fieldset>
                                            </div>

                                        </div>


                                    </div>

                                        

                                    <div role="tabpanel" className="tab-pane fade" id="tabs-4x-tab-4x">

                                        <div className="row">
                                            <div className="col-md-12" style={{ display: (pedidoCompraStatus_id == 7 || pedidoCompraStatus_id == 3) && perfil != 'Administrador' ? 'none' : 'table-row'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Descrição do anexo</label>
                                                    <input type="text" className="form-control" value={descricaoAnexo} maxLength={500} onChange={event => setDescricaoAnexo(event.target.value)} disabled={ pedidoCompraStatus_id == 7 ? false : false} />
                                                </fieldset>

                                            </div>
                                            <div className="col-md-9" style={{ display: (pedidoCompraStatus_id == 7 || pedidoCompraStatus_id == 3) && perfil != 'Administrador' ? 'none' : 'table-row'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">Arquivo</label>
                                                    <input type="file" className="form-control" id="fileAnexoPedido" value={file} onChange={event => setFile(event.target.value)} />

                                                </fieldset>
                                            </div>
                                            <div className="col-md-3" style={{ display: (pedidoCompraStatus_id == 7 || pedidoCompraStatus_id == 3) && perfil != 'Administrador' ? 'none' : 'table-row'}}>
                                                <fieldset className="form-group">
                                                    <label className="form-label">&nbsp;</label>
                                                    <button type="button" className="btn btn-inline btn-info-outline" onClick={() => salvaAnexo()} >Salvar anexo</button>

                                                </fieldset>
                                            </div>
                                            <div className="col-md-12" >

                                                <table id="table-sm" className="table table-hover">
                                                    <thead>
                                                            <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                             
                                                                <th>
                                                                    
                                                                Descrição                                 

                                                                </th>
                                                                   
                                                                <th>
                                                                    
                                                                Arquivo                                 

                                                                </th>
                                                                <th >
                                                                    
                                                                &nbsp;                                 

                                                                </th>
                                                            
                                                            </tr>
                                                            
                                                    </thead>
                                                    {resultadoAnexos}
                                                    
                                                </table>

                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </section>

                            
                           

                        </div>
                        <div className="modal-footer">
                            
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  onClick={() => props.show(false)}>Fechar</button>

                            <button type="button" className="btn btn-inline btn-info-outline" onClick={() => autorizaGestorArea()} style={{ display: pedidoCompra_id && pedidoCompraStatus_id == 9 && gestorArea == 'true' ? 'table-row' : 'none'}} >Autorizar Gestor</button>

                            <button type="button" className="btn btn-inline btn-info-outline" onClick={() => autorizar()} style={{ display: autorizado ? 'none' : pedidoCompra_id && pedidoCompraStatus_id != 3 && pedidoCompraStatus_id != 6 && pedidoCompraStatus_id != 7 & pedidoCompraStatus_id != 9 && (perfil == 'Administrador' || perfil == 'Gestor') ? 'table-row' : 'none' }} >{ autorizado ? 'Desfazer autorização' : 'Autorizar' }</button>

                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => window.$('#modalCancelaPedido').modal('show')} style={{ display: pedidoCompra_id && pedidoCompraStatus_id != 7 && pedidoCompraStatus_id != 3 ? 'table-row' : 'none'}} >Cancelar</button>
                            
                            <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => window.$('#modalDevolveSolicitacao').modal('show')} style={{ display: (perfil == 'Compras' || perfil == 'Administrador') && pedidoCompra_id && pedidoCompraStatus_id != 7 && pedidoCompraStatus_id != 11 && pedidoCompraStatus_id != 3 ? 'table-row' : 'none'}} >Devolver ao solicitante</button>

                            <button type="button" className="btn btn-inline btn-warning-outline" onClick={() => apagar()} style={{ display: pedidoCompra_id && pedidoCompraStatus_id != 7 && perfil == 'Administrador' ? 'table-row' : 'none'}} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => pdfPedido(pedidoCompra_id)} style={{ display: pedidoCompra_id  && pedidoCompraStatus_id != 3 ? 'table-row' : 'none'}} >PDF Solicit.</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => geraDespacho()} style={{ display: pedidoCompra_id && autorizado  && pedidoCompraStatus_id != 3 ? 'table-row' : 'none'}} >PDF Desp.</button>
                            <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => transformarDemanda()} style={{ display: pedidoCompraStatus_id == 6 && (perfil == 'Compras' || perfil == 'Administrador') ? 'table-row' : 'none'}} >Transf. demandas</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()} style={{ display: pedidoCompraStatus_id == 7 || (perfil != 'Compras' && perfil != 'Administrador' && pedidoCompra_id) ? 'none' : pedidoCompraStatus_id == 3  ? 'none' : 'table-row'}}  >{ pedidoCompraStatus_id == 11 ? 'Reenviar solicitação' : 'Salvar' }</button>
                            
                        </div>
                    
                

            <ModalDespachoCompra pedidoCompra_id={pedidoCompra_id} />
            <ModalCancelaPedidoCompra pedidoCompra_id={pedidoCompra_id} carregaPedido={carregaPedido} carregaPedidos={props.carregaPedidos} show={props.show} />
            <ModalDevolvePedidoCompra pedidoCompra_id={pedidoCompra_id} carregaPedido={carregaPedido} carregaPedidos={props.carregaPedidos} show={props.show} carregaPedidosDevolvidos={props.carregaPedidosDevolvidos} />

        </div>


    )

}

export default Modal