import { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { ExportToCsv } from 'export-to-csv';
import { fmtMes, sleep } from "../functions/generalFunctions";

import api from '../components/api'

import ModalMovimentoImporta from '../modal/ModalImportaMovimento'
import ModalMovimentoEdit from '../modal/ModalMovimentoEdit'


const FluxoCaixaMovimento = () => {



    const anoAtual = moment().year()
    const mesAtual = moment().month() + 1


    const consultaSemanaAtual = () => {

      let dataHoje = moment()
      let weekOfMonth = moment(dataHoje);
      let semana = (Math.ceil((weekOfMonth.date() + weekOfMonth.startOf('month').day()) / 7) -1);

      return semana

    }

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()
    const [listaSemanas, setListaSemanas] = useState()

    const [fluxoSemana, setFluxoSemana] = useState(consultaSemanaAtual())
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState(mesAtual.toString())
    const [fluxoCaixa, setFluxoCaixa] = useState()
    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState()

    const [movimento_id, setMovimento_id] = useState()


    function carregaAnos() {

        var x_array = []

        for (var x = 2020; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }


    const carregaSemanas = () => {

        var x_array = []

        for (var x = 1; x <= 6; x++) {

            x_array.push(x)

        }

        setListaSemanas(x_array.map((semana) => 
        
            <option value={semana}>{semana}</option>
        
        ))


    }


    const carregaFluxoCaixa = () => {

      api.get('/fluxoCaixa').then((result) => {

        //console.log(result.data)
        setResultadoFluxoCaixa(result.data.map((rs) =>
      
          <option value={rs.fluxoCaixa}>{rs.fluxoCaixa}</option>
        
        ))

      }).catch((err) => {

        console.log(err)

      })

    }



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'data', //access nested data with dot notation
            header: 'Data',
          },
          {
            accessorKey: 'area',
            header: 'Área',
          },
          {
            accessorKey: 'titulo',
            header: 'Título',
          },
          {
            accessorKey: 'fluxoCaixa', //normal accessorKey
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'area', //normal accessorKey
            header: 'Área',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'banco',
            header: 'Banco',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'agencia',
            header: 'Agência',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'contaBanco',
            header: 'Conta Bancária',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'codigoNatureza',
            header: 'Cód. Natureza',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'itemConta',
            header: 'Item Contábil',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'documento',
            header: 'Documento',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'entrada',
            header: 'Entrada',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'saida',
            header: 'Saída',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'resultado',
            header: 'Resultado',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
        ],
        [],
      );

    /*
      const options = {
        // ...
        rowStyle: (rowData) => {
          return {
            fontFamily: "Mulish-Regular",
            backgroundColor: rowBackgroundColors[rowData.priority] ?? "#fff",
          };
        },
        // ...
      };

    */


    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = () => {
      csvExporter.generateCsv(resultado);
    };
  




    const consulta = () => {

        

        var dataPost = {

            ano: ano.toString(),
            mes: mes.toString(),
            fluxoSemana: fluxoSemana.toString(),
            fluxoCaixa: fluxoCaixa,

        }

        api.post('listaMovimento', dataPost).then((result) => {

            //console.log(result.data)
            //return false

            setResultado(result.data.map((rs) => {

                return {

                    data: rs.data,
                    area: rs.area,
                    banco: rs.banco,
                    fluxoCaixa: rs.fluxoCaixa,
                    agencia: rs.agencia,
                    contaBanco: rs.contaBanco,
                    codigoNatureza: rs.codigoNatureza,
                    documento: rs.documento,
                    entrada: rs.entrada,
                    saida: rs.saida,
                    resultado: rs.resultado,
                    movimento_id: rs.movimento_id,
                    itemConta: rs.itemConta,
                    titulo: rs.titulo,
                    

                }

            }))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    async function exportaExcel() {


      //return false

      window.toastr.info('Aguarde, gerando XLSX...')

      var dataPost = {

        ano: ano.toString(),
        mes: mes.toString(),
        //fluxoSemana: fluxoSemana.toString(),
        fluxoCaixa: fluxoCaixa,

    }

      await api.post('movimentoXLSX', dataPost).then((result) => {

          //console.log(result.data)


          if (result.data.status == 'ok') {

              sleep(1000).then(() => {

                  //console.log(result.data[0].fileNameCompleto, '!!')
                  //window.location.href = result.data.arquivo
                  //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                  //window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                  window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                  
                  //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
              })
              

          }

      }).catch((err) => {

          console.log(err.response)

      })


    }



    useEffect(() => {
        
        carregaAnos()
        carregaMeses()
        carregaSemanas()
        carregaFluxoCaixa()

    }, [])


    useEffect(() => {

        consulta()

    }, [mes, ano, fluxoSemana, fluxoCaixa])



    return (


        <div>

            

                <button type="button" class="btn btn-inline btn-info" onClick={() => {window.$('.modal-MovimentoImporta').modal('show')}}>+ Importar movimento</button>
                <button type="button" class="btn btn-inline btn-secondary" style={{ display: 'none'}} onClick={handleExportRows}>Exportar CSV</button>
                <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()}>Exportar Excel</button>

                    
                    <br/><br/>
        
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Ano</label>
                                <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                    {listaAnos}
                                </select>
                                
                            </fieldset>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError2">Mês</label>
                                <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                    <option value="">[Todos]</option>
                                    {listaMeses}
                                </select>
                            </fieldset>
                        </div>
                        <div className="col-md-3 col-sm-6" style={{ display: 'none' }}>
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError3">Fluxo semana</label>
                                <select type="text" className="form-control" value={fluxoSemana} onChange={event => setFluxoSemana(event.target.value)}>
                                    <option value="">[Selecione]</option>
                                    {listaSemanas}
                                </select>
                                
                            </fieldset>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError3">Fluxo de caixa</label>
                                <select type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)}>
                                    <option value="">[Todos]</option>
                                    {resultadoFluxoCaixa}
                                </select>
                                
                            </fieldset>
                        </div>
                    </div>
        
        
        
                    <MaterialReactTable 
                        
                        columns={columns} 
                        data={resultado} 
                        localization={MRT_Localization_PT_BR}
                        //rowsPerPage={20}
                        //options={options}
                        initialState={{ density: 'compact' }}
                        
                        muiTableHeadCellProps={{
                            //easier way to create media queries, no useMediaQuery hook needed.
                            sx: {
                              fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                              },
                            },
                          }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontSize: {
                                  xs: '8px',
                                  sm: '9px',
                                  md: '10px',
                                  lg: '11px',
                                  xl: '12px',
                                },
                              },
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                          onClick: (event) => {
                            //console.info(event, row.original.usuario_id);
                            //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                            window.$('.modal-MovimentoEdit').modal('show');setMovimento_id(row.original.movimento_id)
      
                          },
                          sx: {
                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                          },
                        })}
                    />


            <ModalMovimentoImporta />
            <ModalMovimentoEdit movimento_id={movimento_id} origem="" />

        </div>
    )

}


export default FluxoCaixaMovimento