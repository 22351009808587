import { useEffect, useState } from 'react'
import api from '../components/api'
import { useAtualizaContext } from '../context/atualizaContext'
import CurrencyInput from '../components/CurrencyInput'
import { fmtMoney } from '../functions/generalFunctions'

const Modal = (props) => {

    const { setDataAtualiza } = useAtualizaContext()

    const origem = props.origem
    const vinculo_id = props.vinculo_id

    const ad_usr = sessionStorage.getItem('usuario_id')

    const [natureza, setNatureza] = useState('')
    const [codigoNatureza, setCodigoNatureza] = useState('')
    const [itemContabil, setItemContabil] = useState('')
    const [itemContabilDescri, setItemContabilDescri] = useState('')
    const [tipo, setTipo] = useState('')
    const [area, setArea] = useState('')
    const [fluxoCaixa, setFluxoCaixa] = useState('')
    const [validado, setValidado] = useState(false)
    const [entrada, setEntrada] = useState()
    const [saida, setSaida] = useState()
    const [dataMovimento, setDataMovimento] = useState()


    const consulta = () => {

        if (vinculo_id != null) {

            api.get(`listaVinculo/${vinculo_id}`).then((result) => {

                var data = result.data[0]
                //console.log(data)

                setCodigoNatureza(data.codigoNatureza == null ? '' : data.codigoNatureza)
                setNatureza(data.natureza == null ? '' : data.natureza)
                setItemContabil(data.itemContabil == null ? '' : data.itemContabil)
                setItemContabilDescri(data.itemContabilDescri == null ? '' : data.itemContabilDescri)

                setTipo(data.tipo == null ? '' : data.tipo)                
                setArea(data.area == null ? '' : data.area)                
                setFluxoCaixa(data.fluxoCaixa == null ? '' : data.fluxoCaixa)                
                setValidado(data.validado == null ? false : data.validado)                
                setEntrada(data.entrada == null ? '' : fmtMoney(data.entrada, 2).replaceAll('R$', ''))
                
                setSaida(data.saida == null ? '' : fmtMoney(data.saida, 2).replaceAll('R$', ''))
                setDataMovimento(data.dataMovimento)


            }).catch((err) => {
    
                console.log(err.response)
    
            })
    
        }

    }


    const salva = () => {


        if (codigoNatureza == '') {

            window.toastr.error('<b>Cód. Natureza</b> não pode ficar em branco!')
            return false

        }


        if (natureza == '') {

            window.toastr.error('<b>Natureza</b> não pode ficar em branco!')
            return false

        }

        if (fluxoCaixa == '') {

            window.toastr.error('<b>Fluxo de caixa</b> não pode ficar em branco!')
            return false

        }

        if (area == '') {

            window.toastr.error('<b>Área</b> não pode ficar em branco!')
            return false

        }

        if (itemContabil == '') {

            window.toastr.error('<b>Cód. Ítem Contábil</b> não pode ficar em branco!')
            return false

        }

        if (itemContabilDescri == '') {

            window.toastr.error('<b>Ítem Contábil</b> não pode ficar em branco!')
            return false

        }

        if (tipo == '') {

            window.toastr.error('<b>Tipo</b> não pode ficar em branco!')
            return false

        }





        var dataPost = {

            codigoNatureza: codigoNatureza,
            natureza: natureza,
            itemContabil: itemContabil,
            itemContabilDescri: itemContabilDescri,
            tipo: tipo,
            area: area,
            fluxoCaixa: fluxoCaixa,
            validado: validado,
            ad_usr: ad_usr,

        }




        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma os dados?',
		    buttons: {
		        Sim: function () {



                    if (vinculo_id) {


                        api.put(`vinculo/${vinculo_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$(`.modal-VinculoEdit${origem}`).modal('hide')
                            
                            setDataAtualiza(Math.random())

                            props.consultaTotalVinculosPendentes()

                        }).catch((err) => {

                            console.log(err.response)

                        })




                    } else {


                        api.post('vinculo', dataPost).then((result) => {

                            console.log(result.data)
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$(`.modal-VinculoEdit${origem}`).modal('hide')
                            setDataAtualiza(Math.random())
                            
                            props.consultaTotalVinculosPendentes()

                        }).catch((err) => {

                            console.log(err.response)
                            

                        })



                    }

                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }


    const apaga = () => {

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {




                    api.delete(`vinculo/${vinculo_id}`).then((result) => {

                        console.log(result.data)
                        window.toastr.success('Registro apagado com sucesso!')
                        window.$(`.modal-VinculoEdit${origem}`).modal('hide')
                        
                        setDataAtualiza(Math.random())

                        props.consultaTotalVinculosPendentes()

                    }).catch((err) => {

                        console.log(err.response)

                    })




                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }


    const consultaNatureza = () => {

        if (codigoNatureza != '') {

            api.get(`listaNatureza/${codigoNatureza}`).then((result) => {

                console.log(result.data)

                setNatureza(result.data[0].natureza)

            }).catch((err) => {

                console.log(err.response)

            })

        }        

    }


    const consultaItemContabil = () => {

        if (itemContabil != '') {

            api.get(`listaItemContabil/${itemContabil}`).then((result) => {

                console.log(result.data)

                setItemContabilDescri(result.data[0].itemContabilDescri)

            }).catch((err) => {

                console.log(err.response)

            })

        }        

    }


    const limpa = () => {

        setCodigoNatureza('')
        setNatureza('')
        setItemContabil('')
        setItemContabilDescri('')
        setTipo('')
        setArea('')
        setFluxoCaixa('')
        setValidado(false)

    }


    useEffect(() => {

        limpa()
        consulta()
        
    }, [vinculo_id])


    const handleChange = (e) => {
        e.preventDefault();
    }


    return (

        <div>

                <div class={`modal fade modal-VinculoEdit${origem}`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Vínculo</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Natureza *</label>
                                        <input type="text" className="form-control" value={codigoNatureza} onBlur={() => consultaNatureza()} onChange={event => setCodigoNatureza(event.target.value)} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Natureza *</label>
                                        <input type="text" className="form-control" value={natureza} onChange={event => setNatureza(event.target.value)}  style={{ textTransform: 'uppercase'}}  />
                                            
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Cód. Item Contábil *</label>
                                        <input type="text" className="form-control" value={itemContabil} onBlur={() => consultaItemContabil()} onChange={event => setItemContabil(event.target.value)} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Item Contábil *</label>
                                        <input type="text" className="form-control" value={itemContabilDescri} onChange={event => setItemContabilDescri(event.target.value)} style={{ textTransform: 'uppercase'}}  />
                                            
                                        
                                    </fieldset>
                                </div>


                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Tipo *</label>
                                        <select className="form-control" value={tipo} onChange={event => setTipo(event.target.value)}>

                                            <option value="">[Selecione]</option>
                                            <option value="RECEITAS">RECEITAS</option>
                                            <option value="DESPESAS">DESPESAS</option>
                                            <option value="APLICAÇÕES">APLICAÇÕES</option>
                                            <option value="TRANSFERENCIAS">TRANSFERENCIAS</option>

                                        </select>
                                        
                                    </fieldset>
                                </div>
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Área *</label>
                                        <input type="text" className="form-control" value={area} onChange={event => setArea(event.target.value)} style={{ textTransform: 'uppercase'}}  />
                                            
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">FluxoCaixa *</label>
                                        <input type="text" className="form-control" value={fluxoCaixa} onChange={event => setFluxoCaixa(event.target.value)} style={{ textTransform: 'uppercase'}}  />
                                            
                                        
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Data Movimento</label>
                                        <input type="text" className="form-control" value={dataMovimento} onChange={event => setDataMovimento(event.target.value)} style={{ textTransform: 'uppercase'}} disabled />
                                            
                                        
                                    </fieldset>
                                </div>
                               
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Entrada</label>
                                        <input type="text" className="form-control" value={entrada} onChange={event => setEntrada(event.target.value)}  
                                        style={{ backgroundColor: '#eaeddd' }}
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                        disabled
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label">Saída</label>
                                        <input type="text" className="form-control" value={saida} onChange={event => setSaida(event.target.value)}  
                                        style={{ backgroundColor: '#eaeddd' }}
                                        onCut={handleChange}
                                        onCopy={handleChange}
                                        onPaste={handleChange}
                                        disabled
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-4">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Validado</label>
                                        <select type="text" className="form-control" value={validado} onChange={event => setValidado(event.target.value)}>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                            
                                        
                                    </fieldset>
                                </div>

                            


                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apaga()} style={{ display: vinculo_id ? 'table-row' : 'none'}} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Modal