import { useEffect, useState } from "react"
import { fmtMes, fmtMoney } from "../functions/generalFunctions"
import moment from 'moment'
import api from "../components/api";
import { BarChart, Bar, LabelList, Legend, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer  } from "recharts";


const ConsumoIndicadores = () => {

    const [indicador, setIndicador] = useState('')
    const [detalhes, setDetalhes] = useState()

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState('')
    const [listaMeses, setListaMeses] = useState()

    const [data, setData] = useState([])


    const renderCustomizedLabel = (props) => {
        //const { x, y, width, height, value } = props;
        const {x, y, fill, value} = props;
        const radius = 10;
      
        return (
          <g>
        
            <text 
               x={x} 
               y={y} 
               dy={-4} 
               fontSize='16' 
               fontFamily='sans-serif'
               fill={fill}  
               textAnchor="right">
                
               </text>
          </g>
        );
      };
  
  
      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="customTooltip">
              <p><font color="brown">{fmtMoney(payload[0]?.payload?.valorTotal, 2)}</font><br/> <br/>             
              <font color="gray">Quantidade:</font> {`${fmtMoney(payload[0]?.payload?.quantidade, 0).replaceAll('R$', '')}`}<br/>
              <font color="purple">Siafisico:</font> {`${payload[0]?.payload?.name}`}<br/>
              <font color="blue">Descrição</font>: {`${payload[0]?.payload?.descricao}`}
              </p>
            </div>
          );
        }
      
        return null;
      };
  
  
  


    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }



    const carregaMeses = () => {

      var x_array = []

      for (var x = 1; x <= 12; x++) {

          x_array.push(x)

      }

      setListaMeses(x_array.map((mes) => 
      
          <option value={mes}>{fmtMes(mes)}</option>
      
      ))


    }


    const geraIndicador = () => {

        if (indicador) {


            var dataPost = {

                indicador: indicador,
                mes: mes,
                ano: ano,
    
            }
    
            api.post('empenhoIndicador', dataPost).then((result) => {
    
                //console.log(result.data)
    
                setData(result.data.map((rs) => {
               
                    return {
    
                        name: rs.siafisicoPregao,
                        valorTotal: rs.valorTotal,
                        descricao: rs.descricao,
                        quantidade: rs.quantidade,
                        
    
                    }
    
                                   
    
                }))
    
                
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        


    }


    useEffect(() => {

        carregaAnos()
        carregaMeses()

    }, [])

    useEffect(() => {

        geraIndicador()

    }, [indicador, mes, ano])


    return (

        <div>
            
            <div className="row">

                <div className="col-md-6 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Indicador</label>
                        <select type="text" className="form-control" value={indicador} onChange={event => setIndicador(event.target.value)}>
                            <option value="">[Selecione]</option>
                            <option value="topValorEmp">Top 10 por valores empenhados</option>
                            <option value="topQtdeEmp">Top 10 por quantidades empenhadas</option>
                        </select>

                    </fieldset>
                </div>

                <div className="col-md-3 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>

                    </fieldset>
                </div>
                <div className="col-md-3 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">Mês</label>
                        <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                            <option value="">[Todos]</option>
                            {listaMeses}
                        </select>
                    </fieldset>
                </div>
               

            </div>
            
            <div style={{ display: indicador != '' ? 'block' : 'none'}}>

                <ResponsiveContainer width="100%" height={200} >

                    <BarChart
                    
                    width={500}
                    height={350}
                    data={data}
                    margin={{top: 5, right: 0, left: 0, bottom: 25}}
                    
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name"
                        fontFamily="sans-serif"
                        tickSize
                        dy='25'
                        textAnchor="middle"
                        label={{ position: 'top' }} />
                    <YAxis hide />
                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                    
                    
                    <Bar dataKey={indicador == 'topValorEmp' ? 'valorTotal' : 'quantidade'} position="middle"  fill="#8884d8"  onClick={(data)=> { setDetalhes(data) }} style={{ cursor: 'pointer'}}>
                        <LabelList dataKey={indicador == 'topValorEmp' ? 'valorTotal' : 'quantidade'} content={renderCustomizedLabel} label={{ position: 'top' }} />
                        
                    </Bar>
                            
                    
                    </BarChart>


                </ResponsiveContainer>

            </div>

        </div>
    )

}

export default ConsumoIndicadores