import { useState, useEffect, useMemo } from "react"
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import Modal from 'react-bootstrap/Modal'
import Compra from '../componentsPages/Compra'



const FornecedorContratos = (props) => {

    const [show, setShow] = useState(false)
    const [demanda_id, setDemanda_id] = useState()

    const fornecedor_id = props.fornecedor_id
    const contratos = props.contratos


  const columns = useMemo(
    () => [


      {
        accessorKey: 'processo', //access nested data with dot notation
        header: 'Processo',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ renderedCellValue, row }) => (
            
            <span class={`label label-primary`}>{renderedCellValue}</span>
       
        ),

      },
      {
        accessorKey: 'descricaoDemanda', //access nested data with dot notation
        header: 'Demanda',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },

      },
      {
        accessorKey: 'dataCadastro', //access nested data with dot notation
        header: 'Cadastro em',
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },

      },
      {
        accessorKey: 'numeroContrato', //access nested data with dot notation
        header: 'Contrato',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },

      },
      {
        accessorKey: 'valor', //access nested data with dot notation
        header: 'Valor',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },

      },

    ],
    [],
  );

    return (

        <div>

        
                <MaterialReactTable

                    columns={columns}
                    data={contratos}
                    localization={MRT_Localization_PT_BR}
                    //rowsPerPage={20}
                    //options={options}
                    initialState={{ density: 'compact' }}
                    muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                    }}
                    muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                    }}

                    muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                        //console.info(event, row.original.usuario_id);
                        //navigate(`/Fornecedor/${row.original.fornecedor_id}`)
                        //window.open(`${process.env.REACT_APP_API}/upload/demandaCompra/${row.original.arquivoNome}`)
                        setShow(true);setDemanda_id(row.original.demanda_id);

                    },
                    sx: {
                        cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                    })}

                />



            <Modal
                show={show}
                dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
                contentClassName="modal-content-custom" // Se precisar de mais personalização
                onHide={() => setShow(false)}
                aria-labelledby="example-custom-modal-styling-title"
                
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Compra {demanda_id}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    
                    <Compra demanda_id={demanda_id} carregaDemandas={props.carregaContratos} show={setShow} />


                </Modal.Body>
            </Modal>

        </div>

    )


}


export default FornecedorContratos