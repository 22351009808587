
import { useEffect, useState } from 'react'
import SolicitacoesLista from "../componentsPages/SolicitacoesLista"
import SolicitacoesDevolvidas from '../componentsPages/SolicitacoesDevolvidas'
import api from '../components/api'


const Solicitacoes = () => {

    const perfil = sessionStorage.getItem('perfil')
    const [totalDevolucoes, setTotalDevolucoes] = useState(0)
    const [solicitacoesDevolvidas, setSolicitacoesDevolvidas] = useState([])
    //const assinaReservaRecurso = sessionStorage.getItem('assinaReservaRecurso')
    const [solicitante, setSolicitante] = useState(perfil == 'ConsultaCompras' ? sessionStorage.getItem('solicitante') : '')

    //console.log(assinaReservaRecurso)


    const carregaPedidosDevolvidos = () => {

        var dataPost = {
  
          solicitante: solicitante,
  
        }
  
        api.post('pedidoCompraDevolvidoLista', dataPost).then((result) => {
  
          //console.log(result.data)
  
          setTotalDevolucoes(result.data.length)
          setSolicitacoesDevolvidas(result.data)
  
        }).catch((err) => {
  
          console.log(err.response)
  
        })
  
  
      }




    useEffect(() => {

        carregaPedidosDevolvidos()
  
    }, [])


    return (

        <div>


            <h5 className="m-t-lg with-border">Solicitações de Compras</h5>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4c-tab-1b" role="tab" data-toggle="tab">
                                Solicitações
                            </a>
                        </li>

                        <li className="nav-item" >
                            <a className="nav-link " href="#tabs-4c-tab-5b" role="tab" data-toggle="tab">
                                Devoluções&nbsp;<span class="label label-pill label-danger">{totalDevolucoes}</span>
                            </a>
                        </li>

                    </ul>
                </div>

                <div className="tab-content" >
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4c-tab-1b">

                        <SolicitacoesLista carregaPedidosDevolvidos={carregaPedidosDevolvidos} />

                    </div>
                    <div role="tabpanel" className="tab-pane fade in show" id="tabs-4c-tab-5b">

                        <SolicitacoesDevolvidas carregaPedidosDevolvidos={carregaPedidosDevolvidos} solicitacoesDevolvidas={solicitacoesDevolvidas} />

                    </div>
                   
                 

                </div>

               
                    

            </section>


            
                
                  

        </div>
    )


}


export default Solicitacoes