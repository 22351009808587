import { useState, useEffect } from "react"
import moment from 'moment'
import { sleep } from "../functions/generalFunctions"

import api from "../components/api"

const Modal = () => {

    const anoAtual = moment().year()

    const [ano, setAno] = useState('')
    const [listaAnos, setListaAnos] = useState()


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    const geraExcel = () => {

        if (ano == '') {

            window.toastr.error('Por favor, informe o ano!')
            return false

        }

        api.get(`itensExcel/${ano}`).then((result) => {

            //console.log(result.data)


            if (result.data.alerta == 'ok') {

                sleep(1000).then(() => {

                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    //window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                    window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                    

                })

                
            }


        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

        carregaAnos()

    }, [])


    return (

        <div>

                <div class="modal fade modal-GeraExcel"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Exportar Planejamento</h4>
                        </div>
                        <div class="modal-body">


                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Ano planejamento</label>
                                        <select className="form-control" value={ano} onChange={event => setAno(event.target.value)} >
                                            <option value="">[Selecione]</option>
                                            {listaAnos}
                                        </select>
                                                        
                                    </div>
                                </div>
                                
                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => geraExcel()}>Extrair excel</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Modal