import ContasAPagar from "./ContasAPagar"
import ContasAReceber from "./ContasAReceber"
import DemonstrativoFinanceiroMensal from './DemonstrativoFinanceiroMensal'


const DemonstrativoFinanceiro = () => {

    return (

        <div>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4-tab-1ck" role="tab" data-toggle="tab">
                                Demonstrativo mensal
                                
                            </a>
                        </li>
                       
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-3ck" role="tab" data-toggle="tab">
                                
                                Contas a Pagar
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-2ck" role="tab" data-toggle="tab">
                                Contas a Receber
                                
                            </a>
                        </li>
                        
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4-tab-1ck">


                        
                        <DemonstrativoFinanceiroMensal />



                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2ck">


                        <ContasAReceber />


                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-3ck">


                        <ContasAPagar />


                    </div>
                   
                    
                </div>
            </section>

        </div>

    )

}


export default DemonstrativoFinanceiro