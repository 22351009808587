import { useState, useEffect } from "react"
import axios from 'axios'
import { useAtualizaContext } from '../context/atualizaContext'
import { useNavigate } from "react-router"
import { fmtMes } from "../functions/generalFunctions"
import moment from 'moment'

const Modal = (props) => {

    const navigate = useNavigate()

    var tipo = props.tipo

    //console.log(tipo)

    //const linkAPI = 'http://172.16.1.253:3378/'
    //const linkAPI = 'http://192.168.255.165:3378/'
    //const linkAPI = 'https://apiportaltransp.fajsaude.com.br/'
    const linkAPI = process.env.REACT_APP_API
    //const linkAPI = 'https://api-faj.koderia.com.br/'


    const anoAtual = moment().year()
    const mesAtual = moment().month() + 1

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState(mesAtual.toString())


    const [file, setFile] = useState()
    const { setDataAtualiza } = useAtualizaContext()



    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }



    useEffect(() => {
        
        carregaAnos()
        carregaMeses()        
        setFile('')

    }, [])


    async function salvar() {


        if (file == null || file == '') {


            window.toastr.error('Por favor, informe o <b>arquivo</b> para importação')
            return false


        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma importar o arquivo?',
		    buttons: {
		        Sim: function () {

                    

                    const formData = new FormData();
                    const imagefile = document.querySelector(`#fileCompras${tipo}`);

                    

                    // 'fileimage' é o campo que o 'multer' procura o arquivo de imagem.
                    formData.append("arquivo", imagefile.files[0]);
                    //formData.append("sobrescrever", '1'); // string junto ao formData.
                    formData.append("mes", mes); // string junto ao formData.
                    formData.append("ano", ano); // string junto ao formData.
                    
                    //console.log(imagefile.files[0])
                    //return false

                    axios.post(`${linkAPI}/${tipo}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'x-access-token': '!nf0$@ud#2',
                    }
                    }).then(response => {

                        //console.log(response.data)
                        //window.$(".MovimentoImporta").modal('hide');
                        window.$(`.modal-${tipo}`).modal('hide')
                        //setImagem(response.data.imagem)
                        //setFile('')
                        //props.carregaAnexos()
                        setDataAtualiza(Math.random())

                        window.$.confirm({
                            //icon: 'fa fa-question',
                            theme: 'dark',
                            closeIcon: true,
                            animation: 'scale',
                            type: 'orange',
                            title: 'Importaçao de arquivo',
                            content: 'Seu arquivo está em importação, e ísto pode demorar um pouco. Deseja acompanhar a importação?',
                            buttons: {
                                Sim: function () {

                                    navigate('/Importacoes')

                                },
                                Cancelar: function () {
                                    //$.alert('Canceled!');
                                }
                
                            },
                
                        });

                        //window.toastr.success('Salvo com sucesso!')



                    }).catch((err) => {

                        console.log(err)
                        console.log(err.response)

                    })


                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }


    

    return (

        <div>

                <div class={`modal fade modal-${tipo}`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">{tipo == 'uploadArquivoCP' ? 'Contas a Pagar' : 'Contas a Receber'} - Importar</h4>
                        </div>
                        <div class="modal-body">

                            <div className="row">

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Ano</label>
                                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                            {listaAnos}
                                        </select>
                                        
                                                        
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mês</label>
                                        <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                            {listaMeses}
                                        </select>
                                        
                                                        
                                    </div>
                                </div>
                                
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label>Arquivo</label>
                                        <input type="file" className="form-control" id={`fileCompras${tipo}`} value={file} onChange={event => setFile(event.target.value)} />
                                                        
                                    </div>
                                </div>
                            
                            </div>
                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()}>Importar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )

}

export default Modal