
import { useEffect, useMemo, useState } from 'react'
//import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalFluxoCaixaEdit from '../modal/ModalFluxoCaixaEdit'

const FluxoCaixaAdm = () => {

    //const navigate = useNavigate()

    const [fluxoCaixaOrdem_id, setFluxoCaixaOrdem_id] = useState()


    const consulta = () => {


        api.get('listaFluxoCaixaOrdemTodos').then((result) => {

            //console.log(result.data)

            setResultado(result.data.map((rs) => {

                return {
                    
                    fluxoCaixaOrdem_id: rs.fluxoCaixaOrdem_id,
                    fluxoCaixa: rs.fluxoCaixa,
                    ordem: rs.ordem,
                    tipo: rs.tipo,
                    classe: rs.classe,
                    ordemClasse: rs.ordemClasse,
                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [

        {
            accessorKey: 'classe',
            header: 'Classe',
            muiTableHeadCellProps: {
                align: 'center',
                },
            muiTableBodyCellProps: {
                align: 'center',
                },
            
            },
            {
            accessorKey: 'ordemClasse',
            header: 'Ordem Classe',
            muiTableHeadCellProps: {
                align: 'center',
                },
            muiTableBodyCellProps: {
                align: 'center',
                },
            
            },
          {
            accessorKey: 'ordem', //access nested data with dot notation
            header: 'Ordem',
           
          },
          {
            accessorKey: 'fluxoCaixa', //access nested data with dot notation
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
           
          },
          
          {
            accessorKey: 'tipo', //normal accessorKey
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          
          
          
        ],
        [],
      );



    useEffect(() => {

        consulta()


    }, [])


    return (


        <div>


            <h5 className="m-t-lg with-border">Fluxo de Caixa - Administração</h5>


            

            <button type="button" class="btn btn-inline btn-success" onClick={() => {window.$('.modal-FluxoCaixaEdit').modal('show');setFluxoCaixaOrdem_id()}}>+ Fluxo de Caixa</button>
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      //navigate(`/Usuario/${row.original.usuario_id}`)
                      window.$('.modal-FluxoCaixaEdit').modal('show');setFluxoCaixaOrdem_id(row.original.fluxoCaixaOrdem_id)

                    },
                    sx: {
                      cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                
            />


            <ModalFluxoCaixaEdit fluxoCaixaOrdem_id={fluxoCaixaOrdem_id} listaFluxosCaixa={consulta}/>

        
        </div>
        
    )

}


export default FluxoCaixaAdm