import { useEffect, useState } from "react"
import api from '../components/api'
import { Link } from 'react-router-dom'


const Modal = (props) => {


    const usuario_id = sessionStorage.getItem('usuario_id')
    const usuario = sessionStorage.getItem('nome')


    const [demandaStatus_id, setDemandaStatus_id] = useState()
    const [resultadoStatus, setResultadoStatus] = useState([])
    const [totalItens, setTotalItens] = useState(0)
    

    const [processo, setProcesso] = useState('')
    const [processoBusca, setProcessoBusca] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')

    const [numeroProtheus, setNumeroProtheus] = useState('')

    const [color, setColor] = useState('')

    const carregaStatus = () => {

        api.get('statusDemanda').then((result) => {

            setResultadoStatus(result.data.map((rs) => 

                <option value={rs.demandaStatus_id}>{rs.fase}</option>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

        carregaStatus()

    }, [])





    useEffect(() => {

        if (processoBusca != '') {

            var dataPost = {

                processo: processoBusca,

            }

            api.post('buscaProcesso', dataPost).then((result) => {

                //console.log(result.data)
                
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setProcesso(rs.processo);setTotalItens(rs.totalItens);setProcessoBusca(`${rs.processo} - ${rs.totalItens} item(ns) `)}}><font color='purple'>[{rs.processo}]</font> [<font color='brown'>{rs.totalItens}</font>],&nbsp;</Link>
                
                ))
                

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [processoBusca])




    useEffect(() => {

        if (processo) {

            setColor('orange')

        } else {

            setColor('white')

        }
        

    }, [processo])



    const salva = () => {


        if (processo == '') {

            window.toastr.error('Por favor, selecione um processo!')
            return false

        }


        if (demandaStatus_id == null || demandaStatus_id == '') {

            window.toastr.error('Por favor, indique a fase que deseja atribuir a este processo')
            return false

        }

        if (demandaStatus_id == 8 && numeroProtheus == '') {

            window.toastr.error('Por favor, informe o número do <b>Pedido Protheus</b> para este processo!')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: `Confirma alterar a situação ${totalItens > 1 ? `das ${totalItens} demandas de compras vinculadas` : 'da demanda de compra vinculada' } a este processo?`,
		    buttons: {
		        Sim: function () {

                    
                    var dataPost = {

                        processo: processo,
                        demandaStatus_id: demandaStatus_id,
                        usuario_id: usuario_id,
                        usuario: usuario,
                        numeroProtheus: numeroProtheus,

                    }

                    api.post('alteraSituacaoProcesso', dataPost).then((result) => {

                        console.log(result.data)


                        window.toastr.success('Registro salvo com sucesso!')
                        window.$('#modalAlteraSituacaoCompra').modal('hide')
                        props.carregaDemandas()
                        
                        setProcesso('')
                        setNumeroProtheus('')
                        setProcessoBusca('')
                        setDemandaStatus_id()
                        

                    }).catch((err) => {

                        console.log(err.response)

                    })
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }

    return (

        <div>


                <div className="modal fade modal-AlteraSituacaoCompras"
                    id="modalAlteraSituacaoCompra"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content"  >
                        <div className="modal-header" >
                            <button type="button" className="modal-close" data-dismiss="modal" aria-label="Close">
                                <i className="font-icon-close-2"></i>
                            </button>
                            <h4 className="modal-title" id="myModalLabel">Alterar situação</h4>
                        </div>
                        <div className="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Processo</label>
                                        <textarea  style={{ backgroundColor: color }}  type="text" className="form-control" value={processoBusca} onChange={event => setProcessoBusca(event.target.value)}  onFocus={() => {setProcesso('');setProcessoBusca('')}} />
                                        {resultadoBusca}
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Fase</label>
                                        <select type="text" className="form-control" value={demandaStatus_id} onChange={event => setDemandaStatus_id(event.target.value)}>
                                            <option value="">[Selecione]</option>
                                            {resultadoStatus}
                                        </select>
                                        
                                    </fieldset>
                                </div>

                                <div className="col-md-12" style={{ display: demandaStatus_id == 8 ? 'table-row' : 'none'}}>
                                    <fieldset className="form-group">
                                        <label className="form-label">Número do Pedido Protheus</label>
                                        <input style={{ backgroundColor: '#d3f2ee'}} type="text" className="form-control" value={numeroProtheus} onChange={event => setNumeroProtheus(event.target.value)} maxLength={50}/>
                                            
                                        
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div className="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default Modal