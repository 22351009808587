import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import moment from 'moment'

const ConsumoExecucaoItem = () => {

    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()
    const [ano, setAno] = useState(anoAtual.toString())

    const [itemDescri, setItemDescri] = useState('')
    const [resultadoBusca, setResultadoBusca] = useState('')

    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [unidFornecimento, setUnidFornecimento] = useState('')
    const [item_id, setItem_id] = useState()

    const [resultado, setResultado] = useState([])
    const [resultadoProcessos, setResultadoProcessos] = useState([])

    

    const [totalQtde, setTotalQtde] = useState(0)
    const [mediaValorUnit, setMediaValorUnit] = useState(0)
    const [valorTotal, setValorTotal] = useState(0)

    const [resultadoEmpenhos, setResultadoEmpenhos] = useState([])
    
    const [consumoJan, setConsumoJan] = useState(0)
    const [consumoFev, setConsumoFev] = useState(0)
    const [consumoMar, setConsumoMar] = useState(0)
    const [consumoAbr, setConsumoAbr] = useState(0)
    const [consumoMai, setConsumoMai] = useState(0)
    const [consumoJun, setConsumoJun] = useState(0)
    const [consumoJul, setConsumoJul] = useState(0)
    const [consumoAgo, setConsumoAgo] = useState(0)
    const [consumoSet, setConsumoSet] = useState(0)
    const [consumoOut, setConsumoOut] = useState(0)
    const [consumoNov, setConsumoNov] = useState(0)
    const [consumoDez, setConsumoDez] = useState(0)
    const [totalConsumo, setTotalConsumo] = useState(0)


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carrega = () => {

        if (item_id) {

            api.get(`planejamentoItem/${item_id}`).then((result) => {

                //console.log(result.data, '!!')

                var data = result.data
                
                
    
                setResultado(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px'}}>

                        <td style={{ textAlign: 'center'}}>
                            
                            {rs.ano}
    
                        </td>
                       
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaConsumo == null ? '0' : fmtMoney(rs.metaConsumo).replaceAll('R$', '')}
    
                        </td>
                        
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.estoque == null ? '0' : fmtMoney(rs.estoque).replaceAll('R$', '')}
    
                        </td>
                        
    
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.valorReferencial == null ? '0,00' : fmtMoney(rs.valorReferencial, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaFinanceira == null ? '0,00' : fmtMoney(rs.metaFinanceira, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaCompra == null ? '0' : fmtMoney(rs.metaCompra).replaceAll('R$', '')}
    
                        </td>

                        
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaExecutada == null ? '0' : fmtMoney(rs.metaExecutada).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            
                            {rs.metaExecutar == null ? '0' : fmtMoney(rs.metaExecutar).replaceAll('R$', '')}
    
                        </td>
                        <td style={{ textAlign: 'center', backgroundColor: rs.conclusao == 'Meta superada' ? '#d0f1f5' : rs.conclusao == 'Meta executada' ? '#d0e3f5' : '#e9f5d0' }}>
                            
                            {rs.conclusao}
    
                        </td>

                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

        }

        

    }

    const carregaProcessos = () => {

        if (item_id && ano) {


            api.get(`itemProcessos/${item_id}/${ano}`).then((result) => {

                //var dataTotais = result.data[1][0]
                
                //setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
                //setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
                //setValorTotal(fmtMoney(dataTotais.valorTotal, 2))
    
                //console.log(result.data)
    
                var data = result.data
                
    
                setResultadoProcessos(data.map((rs) => 
                
                    <tr style={{ fontSize: '11px', fontSize: '11px', cursor: 'pointer'}} >
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.processo}

                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}
                            
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.unidFornecimento}

                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorPesqPreco == null ? '0' : fmtMoney(rs.valorPesqPreco, 2)}
    
                        </td>
                        <td style={{ textAlign: 'right', fontSize: '11px'}}>
                            
                            {rs.valorTotalPrevisto == null ? '0' : fmtMoney(rs.valorTotalPrevisto, 2)}
    
                        </td>
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            {rs.dataInicio}
    
                        </td>
                       
                        <td style={{ textAlign: 'center', fontSize: '11px'}}>
                            
                            <span class="label label-primary" style={{ fontSize: '11px'}}>{rs.modalidade}</span>
                            &nbsp;em {rs.modalidade == 'Pregão' ? rs.dataPregao : ''}
    
                        </td>
                      
                        
                    </tr>
                
                ))
    
    
            }).catch((err) => {
    
                console.log(err.response)
    
            })

            

        }

        

    }



    const carregaConsumo = () => {


        if (ano && item_id) {


            api.get(`itemConsumo/${item_id}/${ano}`).then((result) => {

                //console.log(result.data[1][0].totalConsumido)

                setTotalConsumo(result.data[1][0].totalConsumido ? fmtMoney(result.data[1][0].totalConsumido, 0).toString().replaceAll('R$', '') : 0)

                result.data[0].map((rs) => {


                    switch (rs.mes) {

                        case 1:
                            setConsumoJan(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 2:
                            setConsumoFev(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 3:
                            setConsumoMar(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 4:
                            setConsumoAbr(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 5:
                            setConsumoMai(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 6:
                            setConsumoJun(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 7:
                            setConsumoJul(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 8:
                            setConsumoAgo(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 9:
                            setConsumoSet(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 10:
                            setConsumoOut(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 11:
                            setConsumoNov(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                        case 12:
                            setConsumoDez(rs.valor ? fmtMoney(rs.valor, 0).toString().replaceAll('R$', '') : 0)
                            break

                    }


                })

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }



    const carregaEmpenhos = () => {

        api.get(`empenhoItem/${item_id}/${ano}`).then((result) => {

            var dataTotais = result.data[1][0]
            
            setTotalQtde(fmtMoney(dataTotais.totalQtde, 0).replaceAll('R$', ''))
            setMediaValorUnit(fmtMoney(dataTotais.mediaValorUnit, 2))
            setValorTotal(fmtMoney(dataTotais.valorTotal, 2))


            var data = result.data[0]

            setResultadoEmpenhos(data.map((rs) => 
            
                <tr style={{ fontSize: '11px'}}>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.dataEmissao}
                        
                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        
                        <span class="label label-info" style={{ fontSize: '11px'}}>{rs.empenho}</span>
                        
                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.quantidade == null ? '0' : fmtMoney(rs.quantidade, 0).replaceAll('R$', '')}

                    </td>
                    <td style={{ textAlign: 'center', fontSize: '11px'}}>
                        
                        {rs.unidFornecimento}
                        
                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.valorUnit == null ? '0' : fmtMoney(rs.valorUnit, 2)}

                    </td>
                    <td style={{ textAlign: 'right', fontSize: '11px'}}>
                        
                        {rs.valorTotal == null ? '0' : fmtMoney(rs.valorTotal, 2)}

                    </td>
                   
                    
                </tr>
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })


    }
    


    useEffect(() => {

        if (itemDescri != '') {

            var dataPost = {

                itemDescri: itemDescri,

            }

            api.post('buscaItem', dataPost).then((result) => {

                //console.log(result.data)
                setResultadoBusca(result.data.map((rs) =>
                
                    <Link onClick={() => {setItem_id(rs.item_id);setSiafisicoPregao(rs.siafisicoPregao);setDescricao(rs.descricao);setUnidFornecimento(rs.unidFornecimento)}}><font color='purple'>[{rs.siafisicoPregao}]</font> - {rs.descricao}&nbsp;</Link>
                
                ))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }, [itemDescri])



    const consulta = () => {


        //console.log(siafisicoPregao)
        setResultadoBusca('')
        setItemDescri('')
        
        //api.get()


    }


    useEffect(() => {

        carregaAnos()

    }, [])


    useEffect(() => {

        setConsumoJan(0)
        setConsumoFev(0)
        setConsumoMar(0)
        setConsumoAbr(0)
        setConsumoMai(0)
        setConsumoJun(0)
        setConsumoJul(0)
        setConsumoAgo(0)
        setConsumoSet(0)
        setConsumoOut(0)
        setConsumoNov(0)
        setConsumoDez(0)
        setTotalConsumo(0)

        if (siafisicoPregao != '') {

            consulta()
            carrega()
            //carregaProcessos()
            //carregaEmpenhos()
            //carregaConsumo()

        } else {

            setResultadoBusca('')

        }


    }, [siafisicoPregao])

    useEffect(() => {
        
        carregaProcessos()
        carregaEmpenhos()
        carregaConsumo()

    }, [siafisicoPregao, ano])

    return (

        <div>

            <div className="row">

                    <div className="col-md-12 col-sm-6">
                        <fieldset className="form-group">

                            <input type="text" placeholder='Busque pelo item...' className="form-control" value={itemDescri} onChange={event => setItemDescri(event.target.value)} />
                            {resultadoBusca}

                        </fieldset>
                    </div>

                    

                    <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Cód. Siafisico</label>
                            <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} 
                            />
                        </fieldset>
                    </div>
                    
                    <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Descrição</label>
                            <textarea type="text" className="form-control" value={descricao}  rows="2" disabled={true} />
                        </fieldset>
                    </div>

                    <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                        <fieldset className="form-group">
                            <label className="form-label">Unidade Fornecimento</label>
                            <input type="text" className="form-control" value={unidFornecimento} style={{ backgroundColor: '#e0d1e8'}} 
                            />
                        </fieldset>
                    </div>


                  

                    <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                        <fieldset className="form-group">
                            <label className="form-label" >Ano</label>
                            <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                {listaAnos}
                            </select>
                            
                        </fieldset>
                    </div>

                    <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                    <fieldset className="form-group">
                            <label className="form-label" for="exampleError">Planejamento</label>
                    

                            <table id="table-sm" class="table table-bordered table-hover table-sm">
                                <thead>
                                        <tr style={{ textAlign: 'center'}}>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Ano

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Meta Consumo

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Estoque ano anterior

                                            </th>
                                            
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Valor Referencial

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Meta Financeira

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Meta Compra

                                            </th>
                                            
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Meta Executada

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Meta Executar

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Situação

                                            </th>
                                            
                                            
                                        </tr>
                                        
                                </thead>
                                <tbody>
                                {resultado}
                                </tbody>
                            </table>
                        </fieldset>
                    </div>


                    


            </div>



            <div class="row"  >
	            <div class="col-xl-6 dahsboard-column">
	                <section class="box-typical box-typical-dashboard panel panel-default scrollable" style={{ display: siafisicoPregao == '' ? 'none' : 'block', backgroundColor: '#f7f2eb'}}>
	                    <header class="box-typical-header panel-heading">
	                        <h3 class="panel-title">Fase 1 - Processos em andamento</h3>
	                    </header>
	                    <div class="box-typical-body panel-body">
	                        <table class="tbl-typical">
	                            <tr >
	                                <th style={{ fontSize: '11px'}}><div >Processo</div></th>
	                                <th style={{ fontSize: '11px'}}><div>Qtde</div></th>
                                    <th style={{ fontSize: '11px'}}><div>Unid.</div></th>
	                                <th style={{ fontSize: '11px'}} align="center"><div>Pesq. Preço</div></th>
	                                <th style={{ fontSize: '11px'}} align="center"><div>Total Previsto</div></th>
                                    <th style={{ fontSize: '11px'}} align="center"><div>Data autuação</div></th>
                                    <th style={{ fontSize: '11px'}} align="center"><div>Modalidade</div></th>
                                    
	                            </tr>
	                            {resultadoProcessos}
	                        </table>
	                    </div>
	                </section>
	                
	            </div>
	            <div class="col-xl-6 dahsboard-column">
	                <section class="box-typical box-typical-dashboard panel panel-default scrollable" style={{ display: siafisicoPregao == '' ? 'none' : 'block', backgroundColor: '#ebf4f7'}}>
	                    <header class="box-typical-header panel-heading">
	                        <h3 class="panel-title">Fase 2 - Empenhados</h3>
	                    </header>
	                    <div class="box-typical-body panel-body">
	                        <table class="tbl-typical">
	                            <tr>
	                                <th style={{ fontSize: '11px'}}><div>Data emissão</div></th>
	                                <th style={{ fontSize: '11px'}}><div>Empenho</div></th>
	                                <th style={{ fontSize: '11px'}} align="center"><div>Qtde</div></th>
                                    <th style={{ fontSize: '11px'}}><div>Unid.</div></th>
	                                <th style={{ fontSize: '11px'}} align="center"><div>Valor Unit.</div></th>
                                    <th style={{ fontSize: '11px'}} align="center"><div>Valor Total</div></th>
                                    
	                            </tr>
	                            
                                {resultadoEmpenhos}

                                <tfoot>
                                    <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                        <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}}>
                                            Total
                                        </th>
                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}}>
                                            {totalQtde}
                                        </th>
                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}} >
                                            &nbsp;
                                        </th>
                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}}>
                                            {mediaValorUnit}
                                        </th>
                                        <th style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}}>
                                            {valorTotal}
                                        </th>
                                        <th colspan="2" style={{ fontSize: '11px', backgroundColor: '#f7f2e4'}}>
                                            &nbsp;
                                        </th>
                                    </tr>
                                </tfoot>
	                            
	                        </table>
	                    </div>
	                </section>
	                
	            </div>
                <div className="col-md-12" style={{ display: siafisicoPregao == '' ? 'none' : 'table-row'}}>
                    <fieldset className="form-group">
                            <label className="form-label" for="exampleError">Consumo</label>
                    

                            <table id="table-sm" class="table table-bordered table-hover table-sm">
                                <thead>
                                        <tr style={{ textAlign: 'center'}}>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Jan

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Fev

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                               Mar

                                            </th>
                                            
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Abr

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Mai

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Jun

                                            </th>
                                            
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Jul

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Ago

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Set

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Out

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Nov

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Dez

                                            </th>
                                            <th style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                                
                                                Total

                                            </th>
                                            
                                            
                                            
                                        </tr>
                                        
                                </thead>
                                <tbody>
                                    <tr style={{ fontSize: '11px', textAlign: 'right'}}>
                                        <td>
                                            {consumoJan}
                                        </td>
                                        <td>
                                            {consumoFev}
                                        </td>
                                        <td>
                                            {consumoMar}
                                        </td>
                                        <td>
                                            {consumoAbr}
                                        </td>
                                        <td>
                                            {consumoMai}
                                        </td>
                                        <td>
                                            {consumoJun}
                                        </td>
                                        <td>
                                            {consumoJul}
                                        </td>
                                        <td>
                                            {consumoAgo}
                                        </td>
                                        <td>
                                            {consumoSet}
                                        </td>
                                        <td>
                                            {consumoOut}
                                        </td>
                                        <td>
                                            {consumoNov}
                                        </td>
                                        <td>
                                            {consumoDez}
                                        </td>
                                        <td>
                                            <b>{totalConsumo}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </fieldset>
                    </div>
	
	        </div>


                


        </div>

    )

}

export default ConsumoExecucaoItem