import { useState, useMemo, useEffect } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { sleep } from '../functions/generalFunctions';
import moment from 'moment'


import PedidoCompra from '../componentsPages/PedidoCompra'
import Modal from 'react-bootstrap/Modal'


const SolicitacoesDevolvidas = (props) => {

    const [show, setShow] = useState(false);

    const [pedidoCompra_id, setPedidoCompra_id] = useState()
    //const [resultado, setResultado] = useState([])
    const resultado = props.solicitacoesDevolvidas
    const [urgente, setUrgente] = useState(false)
    

    const columns = useMemo(
        () => [


          {
            accessorKey: 'dataObservacao',
            header: 'Devolvido em',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },


          {
            accessorKey: 'numeroPedido',
            header: 'Nr Pedido',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
         
          
          {
            accessorKey: 'solicitante',
            header: 'Área solicitante',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

         

          {
            accessorKey: 'nomeSolicitante',
            header: 'Solicitado por',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },



          {
            accessorKey: 'observacao',
            header: 'Motivo Devolução',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

         
         
        ],
        [],
      );




    return (

        <div>

            <MaterialReactTable 
                  
                  columns={columns} 
                  data={resultado} 
                  localization={MRT_Localization_PT_BR}
                  //rowsPerPage={20}
                  //options={options}
                  initialState={{ density: 'compact' }}
                  muiTableHeadCellProps={{
                      //easier way to create media queries, no useMediaQuery hook needed.
                      sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                    }}
                  muiTableBodyCellProps={{
                      sx: {
                          fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                          },
                        },
                  }}

                  muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                      //window.$('.modal-ComprasPedido').modal('show');setPedidoCompra_id(row.original.pedidoCompra_id)
                      setShow(true);setPedidoCompra_id(row.original.pedidoCompra_id)

                    },
                    sx: {
                      cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                  
              />

          

          <Modal
            show={show}
            
            dialogClassName="modal-fullscreen-custom" // Aplica o estilo ao dialog
            contentClassName="modal-content-custom" // Se precisar de mais personalização
            onHide={() => setShow(false)}
            autoFocus={false}            
            aria-labelledby="example-custom-modal-styling-title"
            
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                Solicitação de Compra {pedidoCompra_id} <div style={{ display: urgente == true ? 'inline-block' : 'none'}}><span className="label label-danger">URGENTE</span></div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
                  
                  <PedidoCompra pedidoCompra_id={pedidoCompra_id} carregaPedidosDevolvidos={props.carregaPedidosDevolvidos} show={setShow} urgentePop={setUrgente}  />


            </Modal.Body>
          </Modal>

        </div>


    )
}


export default SolicitacoesDevolvidas