import { useState, useEffect } from "react"
import api from "../components/api"
import ModalAnexo from '../modal/ModalFornecedorAnexo'

/*

1.⁠ ⁠CNPJ
2.⁠ ⁠Divida Ativa - data validade
3.⁠ ⁠FGTS - data validade
4.⁠ ⁠JUCESP

*/

const FornecedorCertidoes = (props) => {

    const fornecedor_id = props.fornecedor_id
    const [resultado, setResultado] = useState([])
    const [descricaoAnexo, setDescricaoAnexo] = useState('')
    const [certidao_id, setCertidao_id] = useState('')
    const [validade, setValidade] = useState()

    const [fornecedorCertidao_id, setFornecedorCertidao_id] = useState()

    const listaCertidoes = () => {



        const download = (arquivo) => {


            if (arquivo) {

                window.open(`${process.env.REACT_APP_API}/upload/certidoes/${arquivo}`)
                
            }
            
            
        }


        api.get(`fornecedorCertidoes/${fornecedor_id}`).then((result) => {

            //console.log(result.data)

            setResultado(result.data.map((rs) => 
            

                <tr style={{  fontSize: '11px' }}>

                    <td style={{ cursor: rs.arquivo ? 'pointer' : 'auto'}} onClick={() => download(rs.arquivo)}>
                        <font color={ rs.arquivo ? 'blue' : ''}>{rs.descricao}</font>
                    </td>
                    <td style={{ textAlign: 'center'}}>
                        { rs.validade == false ? 'N/A' : rs.dataValidade}
                    </td>
                    <td style={{ textAlign: 'center', cursor: 'pointer', backgroundColor: rs.status == 'Anexado' ? '#ceedd8' : '#f0e7e6' }} onClick={() => {setFornecedorCertidao_id(rs.fornecedorCertidao_id);setDescricaoAnexo(rs.descricao);setValidade(rs.validade);setCertidao_id(rs.certidao_id);window.$('#FornecedorAnexo').modal('show')} } >
                        {rs.status}
                    </td>

                </tr>

            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        listaCertidoes()

    }, [fornecedor_id])


    return (

        <div>

        

                <table id="table-sm" class="table table-bordered table-hover table-sm">
                    <thead>
                            <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                
                                <th>
                                    
                                Descrição                                 

                                </th>
                                <th>
                                    
                                Validade

                                </th>
                                    
                                <th>
                                    
                                Status

                                </th>
                                
                            
                            </tr>
                            
                    </thead>
                    <tbody>

                        {resultado}

                    </tbody>                       
                    
                    
                </table>



                <ModalAnexo fornecedor_id={fornecedor_id} descricaoAnexo={descricaoAnexo} certidao_id={certidao_id} validade={validade} fornecedorCertidao_id={fornecedorCertidao_id} listaCertidoes={listaCertidoes} />

        </div>

    )


}


export default FornecedorCertidoes