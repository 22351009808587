import FluxoCaixaMensalPrevia from '../componentsPages/FluxoCaixaMensalPrevia'
import FluxoCaixaMensalValidado from '../componentsPages/FluxoCaixaMensalValidado'
import FluxoCaixaMensalMeta from '../componentsPages/FluxoCaixaMensalMeta'
import FluxoCaixaMensalArea from '../componentsPages/FluxoCaixaMensalArea'
import FluxoCaixaMensalAno from '../componentsPages/FluxoCaixaMensalAno'

const FluxoCaixaMensal = () => {

    return (

        <div>


            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4b-tab-1b" role="tab" data-toggle="tab">
                                Fluxo de Caixa
                            </a>
                        </li>
                        <div style={{ display: sessionStorage.getItem('perfil') == 'Gestor' ? 'none' : 'block' }}>
                            <li className="nav-item" >
                                <a className="nav-link" href="#tabs-4-tab-2b" role="tab" data-toggle="tab">
                                    Validados
                                </a>
                            </li>
                            <li className="nav-item" style={{ display: sessionStorage.getItem('perfil') == 'Gestor' ? 'none' : 'block' }}>
                                <a className="nav-link" href="#tabs-4-tab-3b" role="tab" data-toggle="tab">
                                    Orçamento
                                </a>
                            </li>
                        </div>
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-4b" role="tab" data-toggle="tab">
                                Área
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#tabs-4-tab-5b" role="tab" data-toggle="tab">
                                Anual
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1b">

                        <FluxoCaixaMensalPrevia />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2b">

                        <FluxoCaixaMensalValidado />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-3b">

                        <FluxoCaixaMensalMeta />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-4b">

                        <FluxoCaixaMensalArea />

                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-5b">

                        <FluxoCaixaMensalAno />

                    </div>
                    
                </div>
            </section>


        </div>
    )

}

export default FluxoCaixaMensal