import { useEffect, useState } from "react"
import api from '../components/api'
import moment from 'moment'
import { fmtMoney, fmtMes } from "../functions/generalFunctions"



const FluxoCaixaMensalArea = () => {

    const anoAtual = moment().year()
    const mesAtual = moment().month()
    
    const [resultadoReceitas, setResultadoReceitas] = useState()
    const [resultadoReceitasT, setResultadoReceitasT] = useState()

    const [resultadoDespesas, setResultadoDespesas] = useState()
    const [resultadoDespesasT, setResultadoDespesasT] = useState()

    const [resultadoG, setResultadoG] = useState()

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()

    const [mes, setMes] = useState(mesAtual.toString())
    const [ano, setAno] = useState(anoAtual.toString())



    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }


    const consulta = () => {

        if (mes && ano) {
            
            

            api.get(`fluxoCaixaValidaAno/${ano}/${mes}`).then((result) => {

                //console.log(result.data)

                var dataReceitas = result.data[0]
                var dataReceitasT = result.data[2]

                var dataDespesas = result.data[1]
                var dataDespesasT = result.data[3]

                var dataResultado = result.data[4]

            
                setResultadoReceitas(dataReceitas.map((rs) =>
                
                    <tr style={{  backgroundColor: '#edf8fa'}}>
                        <td  style={{ textAlign: 'left'}}>
                            <b>
                            {rs.fluxoCaixa}
                            </b>
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJan)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalFev)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMar)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAbr)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMai)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJun)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJul)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAgo)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalSet)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalOut)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalNov)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalDez)}
                        </td>
                        <th style={{ textAlign: 'right'}}>
                            <b>{fmtMoney(rs.total)}</b>
                        </th>
                        
                    </tr>
                
                ))


            setResultadoG(dataResultado.map((rs) => 

                <tr style={{ backgroundColor: '#f7e1c1'}}>
                    <th style={{ textAlign: 'left'}}>
                        Resultado
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalJan < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalJan)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalFev < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalFev)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalMar < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalMar)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalAbr < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalAbr)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalMai < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalMai)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalJun < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalJun)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalJul < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalJul)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalAgo < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalAgo)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalSet < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalSet)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalOut < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalOut)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalNov < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalNov)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.totalDez < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.totalDez)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.total < 0 ? 'red' : 'blue'}}>
                        <b>{fmtMoney(rs.total)}</b>
                    </th>

                </tr>            
            
            ))


                setResultadoReceitasT(dataReceitasT.map((rs) => 

                    <tr style={{  backgroundColor: '#EFEFEF'}}>
                        <th style={{ textAlign: 'left'}}>
                            Total
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJan)}
                        </th>
                        <th  style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalFev)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMar)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAbr)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMai)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJun)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJul)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAgo)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalSet)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalOut)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalNov)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalDez)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            <b>{fmtMoney(rs.total)}</b>
                        </th>
                        

                    </tr>            
                
                ))


                setResultadoDespesasT(dataDespesasT.map((rs) => 

                    <tr style={{  backgroundColor: '#EFEFEF'}}>
                        <th style={{ textAlign: 'left'}}>
                            Total
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJan)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalFev)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMar)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAbr)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMai)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJun)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJul)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAgo)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalSet)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalOut)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalNov)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalDez)}
                        </th>
                        <th style={{ textAlign: 'right'}}>
                            <b>{fmtMoney(rs.total)}</b>
                        </th>

                    </tr>            
                
                ))
                
                


                setResultadoDespesas(dataDespesas.map((rs) =>
                
                    <tr style={{ backgroundColor: '#faefed'}}>
                        <td  style={{ textAlign: 'left'}}>
                            <b>
                            {rs.fluxoCaixa}
                            </b>
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJan)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalFev)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMar)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAbr)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalMai)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJun)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalJul)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalAgo)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalSet)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalOut)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalNov)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.totalDez)}
                        </td>
                        <th style={{ textAlign: 'right'}}>
                            <b>{fmtMoney(rs.total)}</b>
                        </th>
                        
                    </tr>
                
                ))


            }).catch((err) => {

                //console.log('!')
                console.log(err.response)

            })

        }


    }

    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }



    async function geraPDF() {


        await api.get(`fluxoCaixaValidaAnoPDF/${ano}/${mes}`).then((result) => {

            if (result.data) {

                //window.open(`https://api-faj.koderia.com.br/pdf/${result.data.arquivo}`)
                //window.open(`http://192.168.255.165:3378/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                

            }

            
        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

        carregaMeses()
        carregaAnos()

    }, [])


    useEffect(() => {

        consulta()

    }, [ano, mes])


    return (

        <div>

            <button type="button" class="btn btn-inline btn-danger" onClick={() => geraPDF()}>Exportar PDF</button>

            <br/><br/>

            <div className="row">
                <div className="col-md-9 col-sm-9">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>
                        
                    </fieldset>
                </div>
                <div className="col-md-3 col-sm-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Mês limite</label>
                        <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                            {listaMeses}
                        </select>
                        
                    </fieldset>
                </div>
            </div>

            <table id="table-sm" class="table table-bordered table-hover table-sm">

                <thead>

                    <tr style={{ textAlign: 'center', fontSize: '11px'}}>
                        <th>
                            Tipo
                        </th>
                        <th>
                            Jan
                        </th>
                        <th>
                            Fev
                        </th>
                        <th>
                            Mar
                        </th>
                        <th>
                            Abr
                        </th>
                        <th>
                            Mai
                        </th>
                        <th>
                            Jun
                        </th>
                        <th>
                            Jul
                        </th>
                        <th>
                            Ago
                        </th>
                        <th>
                            Set
                        </th>
                        <th>
                            Out
                        </th>
                        <th>
                            Nov
                        </th>
                        <th>
                            Dez
                        </th>
                        <th>
                            Total
                        </th>
                        
                    </tr>

                </thead>
                <tbody style={{ fontSize: '11px'}}>
                    <tr>
                        <td colspan="14" style={{ textAlign: 'center', backgroundColor: '#afd8e0'}}>

                            <b>Receitas</b>
                        </td>
                    </tr>
                    {resultadoReceitas}
                    {resultadoReceitasT}

                    <tr>
                        <td colspan="14" style={{ textAlign: 'center', backgroundColor: '#e0baaf'}}>

                            <b>Despesas</b>
                        </td>
                    </tr>
                    {resultadoDespesas}
                    {resultadoDespesasT}
                    {resultadoG}
                </tbody>
                

            </table>

        </div>
    )

}


export default FluxoCaixaMensalArea