import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { fmtMoney, fmtMes } from '../functions/generalFunctions';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { sleep } from '../functions/generalFunctions';
import moment from 'moment'
import ModalImportaContas from '../modal/ModalImportaContas'

const ContasAPagar = () => {

    const tipo = 'uploadArquivoCP'

    const anoAtual = moment().year()
    const mesAtual = moment().month() + 1

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()

    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState(mesAtual.toString())

    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'fluxoCaixa', //access nested data with dot notation
            header: 'Fluxo Caixa',
           
          },
          {
            accessorKey: 'titulo', //access nested data with dot notation
            header: 'Título',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          
          {
            accessorKey: 'parcela', //normal accessorKey
            header: 'Parcela',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'tipo',
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'codigoNatureza',
            header: 'Natureza',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'codigoFornecedor',
            header: 'Cód. fornecedor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'nomeFornecedor',
            header: 'Fornecedor',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'dataEmissao',
            header: 'Emissão',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'dataVencimento',
            header: 'Vencimento',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'cCusto',
            header: 'Centro de Custo',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

          {
            accessorKey: 'itemConta',
            header: 'Item Contábil',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },

         
          {
            accessorKey: 'saldo',
            header: 'Saldo',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          
          
        ],
        [],
      );



    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2024; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }


    const carregaDados = () => {

        var dataPost = {

            ano: ano,
            mes: mes,


        }

        api.post('listaTodosCP', dataPost).then((result) => {

            //console.log(result.data)


            setResultado(result.data.map((rs) => {

                return {
                    
                    mes: rs.mes,
                    ano: rs.ano,
                    contaPagar_id: rs.contaPagar_id,
                    fluxoCaixa: rs.fluxoCaixa,
                    titulo: rs.titulo,
                    parcela: rs.parcela,
                    tipo: rs.tipo,
                    saldo: fmtMoney(rs.saldo).toString().replaceAll('R$', ''),
                    codigoNatureza: rs.codigoNatureza,
                    codigoFornecedor: rs.codigoFornecedor,
                    nomeFornecedor: rs.nomeFornecedor,
                    dataEmissao: rs.dataEmissao,
                    dataVencimento: rs.dataVencimento,
                    itemConta: rs.itemConta,
                    cCusto: rs.cCusto,
                }


            }))


            

        }).catch((err) => {

            console.log(err)

        })

    }


    async function exportaExcel() {

        window.toastr.info('Aguarde, gerando XLSX...')

  
        await api.get('exportaExcelCP').then((result) => {
  
            //console.log(result.data)
  
            if (result.data.status == 'ok') {

              
  
                sleep(3000).then(() => {
  
                    //console.log(result.data[0].fileNameCompleto, '!!')
                    //window.location.href = result.data.arquivo
                    //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                    window.location.href = `https://apiportaltransp.fajsaude.com.br/${result.data.arquivo}`
                    //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
                })
                
  
            }
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
      


    }


    useEffect(() => {

        carregaAnos()
        carregaMeses()

    }, [])


    useEffect(() => {

        carregaDados()

    }, [mes, ano])


    return (

        <div>

            
            <button type="button" class="btn btn-inline btn-info" onClick={() => {window.$(`.modal-${tipo}`).modal('show')}}>+ Importar</button>
            <button type="button" class="btn btn-inline btn-success" style={{ display: sessionStorage.getItem('perfil') == 'Gestor' ? 'none' : 'table-row' }} onClick={() => exportaExcel()} >Exportar Excel</button>
            
                    
                    <br/><br/>
        
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Ano</label>
                                <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                    {listaAnos}
                                </select>
                                
                            </fieldset>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError2">Mês</label>
                                <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                    {listaMeses}
                                </select>
                            </fieldset>
                        </div>
                    </div>

            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                      //console.info(event, row.original.usuario_id);
                      //navigate(`/Usuario/${row.original.usuario_id}`)

                    },
                    sx: {
                      //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                  })}
                
            />


            <ModalImportaContas tipo={tipo} />

        </div>

    )

}

export default ContasAPagar