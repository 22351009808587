import React, { createContext, useState, useContext } from "react";

const totalMovimentoPendenteContext = createContext();

export default function AmbienteProvider({ children }) {
  const [totalMovimentoPendente, setTotalMovimentoPendente] = useState();

  return (
    <totalMovimentoPendenteContext.Provider value={{ totalMovimentoPendente, setTotalMovimentoPendente }} >
      {children}
    </totalMovimentoPendenteContext.Provider>
  );
}

export function useTotalMovimentoPendenteContext() {
  const context = useContext(totalMovimentoPendenteContext);
  //if (!context) throw new Error("useCount must be used within a CountProvider");
  const { totalMovimentoPendente, setTotalMovimentoPendente } = context;
  return { totalMovimentoPendente, setTotalMovimentoPendente };
}

