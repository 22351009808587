
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalMateriaPrima from '../modal/ModalMateriaPrima'


const MateriaPrima = () => {



    const [materiaPrima_id, setMateriaPrima_id] = useState()

    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'codigo', //access nested data with dot notation
            header: 'Código',
           
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            
          },
          
        ],
        [],
      );


    const consultaTodos = () => {


        api.get('materiaPrimaListaTodos').then((result) => {

            //console.log(result.data)

            setResultado(result.data)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const exportaExcel = () => {


    }



    useEffect(() => {

        consultaTodos()


    }, [])



    return (
        
        <div>

            <h5 className="m-t-lg with-border">Matéria Prima</h5>

            <button type="button" class="btn btn-inline btn-primary" onClick={() => { window.$('.modal-MateriaPrima').modal('show');setMateriaPrima_id()}} >+ Novo registro</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} disabled>Exportar Excel</button>


            <div className="row">

                <div className="col-md-12 col-sm-12">


                    <MaterialReactTable 
                        
                        columns={columns} 
                        data={resultado} 
                        localization={MRT_Localization_PT_BR}
                        //rowsPerPage={20}
                        //options={options}
                        initialState={{ density: 'compact' }}
                        muiTableHeadCellProps={{
                            //easier way to create media queries, no useMediaQuery hook needed.
                            sx: {
                                fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                                },
                            },
                            }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                        }}

                        muiTableBodyRowProps={({ row }) => ({
                            onClick: (event) => {
                            //console.info(event, row.original.usuario_id);
                            //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                            window.$('.modal-MateriaPrima').modal('show');setMateriaPrima_id(row.original.materiaPrima_id)

                            },
                            sx: {
                            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                            },
                        })}
                        
                    />

                </div>


            </div>

            <ModalMateriaPrima materiaPrima_id={materiaPrima_id} consultaTodos={consultaTodos} />

        </div>

    )

}

export default MateriaPrima