import { useEffect, useState } from "react"
import api from "../components/api"


const Modal = (props) => {

    const pedidoCompra_id = props.pedidoCompra_id

    const [numeroDespacho, setNumeroDespacho] = useState('')
    

    const salva = () => {

        
        if (numeroDespacho == '') {

            window.toastr.error('<b>Número do despacho</b> não pode ficar em branco!')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'orange',
		    title: 'Confirmação',
		    content: 'Confirma gerar o despacho?',
		    buttons: {
		        Sim: function () {

                    var dataPost = {

                        numeroDespacho: numeroDespacho,

                    }

                    api.post(`/pedidoCompraDespachoPDF/${pedidoCompra_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)
                        window.$('#modalDespacho').modal('hide')

                    }).catch((err) => {

                        console.log(err.response)

                    })

                    /*

                                
                    api.put(`usuarioSenha/${usuario_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        limpa()
                        window.toastr.success('Senha alterada com sucesso!')
                        window.$('.modal-AlteraSenha').modal('hide')
                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })


                    */
                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }

    const limpa = () => {


        setNumeroDespacho('')

    }

    useEffect(() => {

        limpa()

    }, [pedidoCompra_id])


    return (


        <div>

                <div class={`modal fade modal-Despacho`}
                    id="modalDespacho"
                    tabindex="-1"
                    role="dialog"
                    style={{ zIndex: 1060 }}
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-sm" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Gerar Despacho</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Número do despacho</label>
                                        <input type="text" className="form-control" value={numeroDespacho}  onChange={event => setNumeroDespacho(event.target.value)} maxLength={25} />
                                    </fieldset>
                                </div>
                            
                               

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Gerar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal