import { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import moment from 'moment'
import api from '../components/api'


const Orcamento = () => {


    const [dataAbertura, setDataAbertura] = useState()
    const [dataFechamento, setDataFechamento] = useState()


    const salva = () => {

        //console.log(dataAbertura, 'aqui')

        if (moment(dataAbertura, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data de abertura</b> inv&aacute;lida!');
			return false;

		}


        if (moment(dataFechamento, "DD/MM/YYYY", true).isValid() == false) {

			window.toastr.error('<b>Data de fechamento</b> inv&aacute;lida!');
			return false;

		}


        var dataAbertura_array = dataAbertura.split('/')
        var dataFechamento_array = dataFechamento.split('/')

        var dataAbertura_UTC = dataAbertura_array[2] + '-' + dataAbertura_array[1] + '-' + dataAbertura_array[0]
        var dataFechamento_UTC = dataFechamento_array[2] + '-' + dataFechamento_array[1] + '-' + dataFechamento_array[0]

        var dataPost = {

            dataAbertura: dataAbertura_UTC,
            dataFechamento: dataFechamento_UTC,

        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {


                    api.put('dataAjusteOrcamento', dataPost).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Registro salvo com sucesso!')
                        return false
            
                    }).catch((err) => {
            
                        console.log(err.response)
            
                    })
            

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


      

    }

    const consulta = () => {

        api.get('dataAjusteOrcamento').then((result) => {

            var data = result.data[0]

            setDataAbertura(data.dataAbertura)
            setDataFechamento(data.dataFechamento)

        }).catch((err) => {

            console.log(err.response)

        })

    }

        
    useEffect(() => {


        consulta()


    }, [])


    return (


        <div>

            <div class='modal fade modal-AjusteOrcamento'
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                    <div class="modal-dialog modal-lg" >
                        <div class="modal-content"  >
                            <div class="modal-header" >
                                <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                    <i class="font-icon-close-2"></i>
                                </button>
                                <h4 class="modal-title" id="myModalLabel">Orçamento - Ajustes</h4>
                            </div>
                            <div class="modal-body">


                                <div className="row">
                                    
                                    <div className="col-md-12">
                                        <fieldset className="form-group">
                                            <label className="form-label">Data abertura para informe</label>
                                            <InputMask mask="99/99/9999" type="text" className="form-control" value={dataAbertura} onChange={event => setDataAbertura(event.target.value)} />
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12">
                                        <fieldset className="form-group">
                                            <label className="form-label">Data fechamento para informe</label>
                                            <InputMask mask="99/99/9999" type="text" className="form-control" value={dataFechamento} onChange={event => setDataFechamento(event.target.value)} />
                                        </fieldset>
                                    </div>

                                </div>


                           

                             </div>
                            <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-primary-outline" onClick={() => salva()} >Salvar</button>
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
        


    )

}

export default Orcamento