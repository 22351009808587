import { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import Inputmask from 'react-input-mask'
import api from '../components/api'
import { validaCPF } from '../functions/generalFunctions'
import ModalAlteraSenhaAdm from '../modal/ModalAlteraSenhaAdm'

const Usuario = () => {


    const navigate = useNavigate()

    let { slug } = useParams()
    
    const [usuario_id, setUsuario_id] = useState(slug)
    const [cpf, setCPF] = useState('')
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [deletado, setDeletado] = useState(false)
    const [perfil, setPerfil] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    const [resultadoFluxoCaixa, setResultadoFluxoCaixa] = useState([])
    const [resultadoSolicitante, setResultadoSolicitante] = useState([])

    const [demandaSolicitante_id, setDemandaSolicitante_id] = useState('')

    const [gestorArea, setGestorArea] = useState('')
    const [assinaReservaRecurso, setAssinaReservaRecurso] = useState('')
    const [autorizaCompra, setAutorizaCompra] = useState('')
    const [gestorContrato, setGestorContrato] = useState('')

    
    const carregaSolicitantes = () => {

        api.get('solicitantes').then((result) => {

            setResultadoSolicitante(result.data.map((rs) => 

                <option value={rs.demandaSolicitante_id}>{rs.solicitante}</option>
                
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }

    useEffect(() => {

        carregaSolicitantes()

    }, [])

    

    const consulta = () => {

        
        api.get(`usuarioListaUm/${usuario_id}`).then((result) => {

            var data = result.data[0]
            var dataPerfil = result.data[1]
            //console.log(data)

            setCPF(data.cpf)
            setNome(data.nome)
            setEmail(data.email)
            setDeletado(data.deletado)
            setGestorArea(data.gestorArea == null ? '' : data.gestorArea)
            setAssinaReservaRecurso(data.assinaReservaRecurso == null ? '' : data.assinaReservaRecurso)
            setAutorizaCompra(data.autorizaCompra == null ? '' : data.autorizaCompra)
            setGestorContrato(data.gestorContrato == null ? '' : data.gestorContrato)
            setDemandaSolicitante_id(data.demandaSolicitante_id)
            setPerfil(data.perfil == null ? '' : data.perfil)


        }).catch((err) => {

            console.log(err.response)

        })

    }


    function salvar() {


        if (!validaCPF(cpf.replaceAll('.', '').replaceAll('-', ''))) {

            window.toastr.error('CPF inválido!')
            return false;

        }

        if (nome == '') {

            window.toastr.error('<b>Nome</b> não pode ficar em branco!')
            return false;

        }

        if (email == '') {

            window.toastr.error('<b>E-mail</b> não pode ficar em branco!')
            return false;

        }

        
        if (gestorArea == null || gestorArea.toString() == '') {
            
            window.toastr.error('<b>Gestor da Área</b> não pode ficar em branco!')
            return false;

        }

        if (autorizaCompra == null || autorizaCompra.toString() == '') {
            
            window.toastr.error('<b>Autoriza Compra</b> não pode ficar em branco!')
            return false;

        }

        if (gestorContrato == null || gestorContrato.toString() == '') {
            
            window.toastr.error('<b>Gestor de Contrato</b> não pode ficar em branco!')
            return false;

        }

        if (assinaReservaRecurso == null || assinaReservaRecurso.toString() == '') {
            
            window.toastr.error('<b>Assina Reserva de Recursos</b> não pode ficar em branco!')
            return false;

        }


        if (perfil == '') {

            window.toastr.error('<b>Perfil</b> não pode ficar em branco!')
            return false;

        }

        if (perfil == 'ConsultaCompras' && demandaSolicitante_id == '') {

            window.toastr.error('<b>Área solicitante</b> não pode ficar em branco!')
            return false;

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma os dados?',
		    buttons: {
		        Sim: function () {



                    if (usuario_id) {



                        var dataPost = {

                            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                            nome: nome.toUpperCase(),
                            email: email.toLocaleLowerCase(),
                            deletado: deletado,
                            ad_usr: sessionStorage.getItem('usuario_id'),
                            perfil: perfil,
                            demandaSolicitante_id: demandaSolicitante_id,
                            gestorArea: gestorArea,
                            assinaReservaRecurso: assinaReservaRecurso,
                            autorizaCompra: autorizaCompra,
                            gestorContrato: gestorContrato,

                        }

                        

                        api.put(`usuario/${usuario_id}`, dataPost).then((result) => {

                            //console.log(result.data)
    
                            window.toastr.success('Registro salvo com sucesso!')
                            
    
                        }).catch((err) => {
    
                            console.log(err.response)
    
                        })


                    } else {

                        
                        if (senha == '') {

                            window.toastr.error('<b>Senha</b> não pode ficar em branco!');
                            return false;

                        }

                        if (senha != confirmaSenha) {

                            window.toastr.error('Senhas não conferem!')
                            return false;

                        }




                        var dataPost = {

                            cpf: cpf.replaceAll('.', '').replaceAll('-', ''),
                            nome: nome.toUpperCase(),
                            email: email.toLocaleLowerCase(),
                            //deletado: deletado,
                            usuario_id: usuario_id,
                            senha: senha,
                            perfil: perfil,
                            ad_usr: sessionStorage.getItem('usuario_id'),
                            assinaReservaRecurso: assinaReservaRecurso,
                            autorizaCompra: autorizaCompra,
                            gestorArea: gestorArea,
                            gestorContrato: gestorContrato,

                        }

                        //console.log(dataPost)
                        //return false

                        api.post('usuario', dataPost).then(() => {

                           
                            window.toastr.success('Registro salvo com sucesso!')
                            

    
                        }).catch((err) => {
    
                            console.log(err.response)
    
                        })

                    }

                    
                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const marcaFluxoCaixa = (fluxoCaixa, acao) => {

        var dataPost = {

            usuario_id: usuario_id,
            fluxoCaixa: fluxoCaixa,
            acao: acao,

        }

        //console.log(dataPost)
        //return false

        api.post('usuarioFluxoCaixa', dataPost).then(() => {

            //console.log(result.data)

        }).catch((err) => {

            console.log(err.response)

        })


    }


    const carregaFluxosCaixa = () => {

        api.get(`usuarioFluxoCaixa/${usuario_id}`).then((result) => {

            //console.log(result.data)

            var data = result.data

            setResultadoFluxoCaixa(data.map((rs) =>
            
                <tr>
                    <td style={{ fontSize: '11px', width: '10px', textAlign: 'center'}}>
                        <input type="checkbox" onClick={event => marcaFluxoCaixa(rs.fluxoCaixa, event.target.checked)} defaultChecked={ rs.acao } />
                    </td>
                    <td style={{ fontSize: '11px' }}>
                        {rs.fluxoCaixa} <span style={{ fontSize: '11px'}} className={`label label-${ rs.tipo == 'RECEITAS' ? 'primary' : 'danger' }`}>{rs.tipo}</span>
                    </td>

                </tr>
            
            ))


        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {
        
        consulta()
        carregaFluxosCaixa()

    }, [usuario_id])

    return (

        <div>


            <h5 className="m-t-lg with-border">Administração - Usuário</h5>

                <div className="row">
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">CPF *</label>
                        <Inputmask mask="999.999.999-99" type="text" className="form-control" value={cpf} onChange={event => setCPF(event.target.value)} />
                    </fieldset>
                </div>
            
                <div className="col-md-9">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Nome *</label>
                        <input type="text" className="form-control" value={nome} onChange={event => setNome(event.target.value)} style={{ textTransform: 'uppercase'}}  />
                            
                        
                    </fieldset>
                </div>

                <div className="col-md-12">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">E-mail *</label>
                        <input type="text" className="form-control" value={email} onChange={event => setEmail(event.target.value)} style={{ textTransform: 'lowercase'}}  />
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Gestor da área *</label>
                        <select className="form-control" value={gestorArea} onChange={event => setGestorArea(event.target.value)} >

                            <option value="">[Selecione]</option>
                            <option value={false}>Não</option>
                            <option value={true}>Sim</option>
                            
                            

                        </select>
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Gestor de Contrato *</label>
                        <select className="form-control" value={gestorContrato} onChange={event => setGestorContrato(event.target.value)} >

                            <option value="">[Selecione]</option>
                            <option value={false}>Não</option>
                            <option value={true}>Sim</option>
                            
                            

                        </select>
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Autoriza Compra *</label>
                        <select className="form-control" value={autorizaCompra} onChange={event => setAutorizaCompra(event.target.value)} >

                            <option value="">[Selecione]</option>
                            <option value={false}>Não</option>
                            <option value={true}>Sim</option>
                            
                            

                        </select>
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Assina Reserva de Recurso *</label>
                        <select className="form-control" value={assinaReservaRecurso} onChange={event => setAssinaReservaRecurso(event.target.value)} >

                            <option value="">[Selecione]</option>
                            <option value={false}>Não</option>
                            <option value={true}>Sim</option>
                            
                            

                        </select>
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">Perfil *</label>
                        <select className="form-control" value={perfil} onChange={event => setPerfil(event.target.value)} >

                            <option value="">[Selecione]</option>
                            <option value="ConsultaCompras">Comum</option>
                            <option value="CDOC">CDOC</option>
                            <option value="Contratos">Contratos</option>
                            <option value="Compras">Compras</option>
                            <option value="Financeiro">Financeiro</option>
                            <option value="Orçamento">Orçamento</option>
                            <option value="Planejamento">Planejamento</option>
                            <option value="Gestor">Gestor</option>  
                            
                            <option value="Administrador">Administrador</option>
                            

                        </select>
                    </fieldset>
                </div>
                <div className="col-md-4">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">Bloqueado</label>
                        <select className="form-control" value={deletado} onChange={event => setDeletado(event.target.value)} >

                            <option value={false}>Não</option>
                            <option value={true}>Sim</option>

                        </select>
                    </fieldset>
                </div>

                <div className="col-md-6" style={{ display: usuario_id ? 'none' : 'table-row' }}>
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Senha</label>
                        <input type="password" className="form-control" value={senha} onChange={event => setSenha(event.target.value)}  />
                            
                        
                    </fieldset>
                </div>
                <div className="col-md-6" style={{ display: usuario_id ? 'none' : 'table-row' }}>
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Confirma senha</label>
                        <input type="password" className="form-control" value={confirmaSenha} onChange={event => setConfirmaSenha(event.target.value)}  />
                            
                        
                    </fieldset>
                </div>

                <div className="col-md-12" style={{ display: perfil == 'ConsultaCompras'  ? 'table-row' : 'none' }}>

                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Área solicitante</label>
                        <select className="form-control" value={demandaSolicitante_id} onChange={event => setDemandaSolicitante_id(event.target.value)} >
                            <option value="">[Selecione]</option>
                            {resultadoSolicitante}
                        </select>
                            
                        
                    </fieldset>
                

                </div>

                <div className="col-md-12" style={{ display: usuario_id && perfil == 'Financeiro' ? 'table-row' : 'none' }}>

                    <table id="table-sm" class="table table-bordered table-hover table-sm">
                        <thead>
                                <tr>
                                    
                                    <th colSpan={2} style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                        
                                        Gerência de Fluxos de Caixas

                                    </th>
                                </tr>
                                
                        </thead>
                        {resultadoFluxoCaixa}
                    </table>
                

                </div>

                <div className="col-md-12">

                    <br/><br/>

                    <button type="button" class="btn btn-inline btn-warning" onClick={() => navigate('/Usuarios')}>Voltar</button>
                    <button type="button" class="btn btn-inline btn-info" onClick={() => window.$('.modal-AlteraSenhaAdm').modal('show')}>Alterar senha</button>
                    <button type="button" class="btn btn-inline btn-primary" onClick={() => salvar()}>Salvar</button>
                    

                </div>

               
                
            </div>


        <ModalAlteraSenhaAdm usuario_id={usuario_id} />

        </div>
    )


}


export default Usuario