import { useEffect, useState } from "react"
import moment from 'moment'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'
import { Link } from "react-router-dom"
import { fmtMes, getTotalWeeksInMonth } from '../functions/generalFunctions'

import ModalFluxoCaixaDetalheAno from '../modal/ModalFluxoCaixaDetalheAno'
import ModalResultadoAno from '../modal/ModalResultadoAno'

import { useAtualizaContext } from '../context/atualizaContext'


const FluxoCaixaMensalPrevia = () => {

    const { dataAtualiza, setDataAtualiza } = useAtualizaContext()


    const [totalSemanasMes, setTotalSemanasMes] = useState(0)


    const anoAtual = moment().year()
    const mesAtual = moment().month() + 1

    const [saldoAtual, setSaldoAtual] = useState(0)
    const [saldoAnterior, setSaldoAnterior] = useState(0)

    const [listaAnos, setListaAnos] = useState()
    const [listaMeses, setListaMeses] = useState()
    const [resultadoReceitas, setResultadoReceitas] = useState()
    const [resultadoDespesas, setResultadoDespesas] = useState()
    const [resultadoTotalReceitas, setResultadoTotalReceitas] = useState()
    const [resultadoTotalDespesas, setResultadoTotalDespesas] = useState()
    
    const [resultadoTotalGeral, setResultadoTotalGeral] = useState()
    const [resultado, setResultado] = useState()

    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState(mesAtual.toString())

    const [mes6, setMes6] = useState(false)

    const [fluxoCaixa, setFluxoCaixa] = useState()
    const [tipo, setTipo] = useState()
    const [dataUltimoMovimento, setDataUltimoMovimento] = useState()


    const carregaAnos = () =>    {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {
            
            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>
        
            <option value={ano}>{ano}</option>
        
        ))
        

    }


    const carregaMeses = () => {

        var x_array = []

        for (var x = 1; x <= 12; x++) {

            x_array.push(x)

        }

        setListaMeses(x_array.map((mes) => 
        
            <option value={mes}>{fmtMes(mes)}</option>
        
        ))


    }

    const consultaDataUltimoMovimento = () => {


        api.get('listaDataMovimento').then((result) => {

            //console.log(result.data)      
            setDataUltimoMovimento(result.data[0].dataMovimento)      

        }).catch((err) => {

            console.log(err.response)

        })

    }


    const consultaReceitas = () => {


        var dataPost = {

            ano: ano,
            mes: mes,
            tipo: "RECEITAS",

        }
        
        api.post('listaMensal', dataPost).then((result) => {

            setResultadoReceitas(result.data.map((rs) => {

                return (

                    <tr style={{ fontSize: '11px', backgroundColor: '#edf8fa'}}>
                        <td>
                        <Link onClick={() => {window.$('.modal-FluxoCaixaDetalheAno').modal('show');setFluxoCaixa(rs.fluxoCaixa);setTipo(rs.tipo)}}>{rs.fluxoCaixa}</Link>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana1)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana2)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana3)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana4)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana5)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        {fmtMoney(rs.semana6)}
                        </div>
                    </td>
                    <td>
                        <div align="right">
                        <b>{fmtMoney(parseFloat(rs.semana1) + parseFloat(rs.semana2) + parseFloat(rs.semana3) + parseFloat(rs.semana4) + parseFloat(rs.semana5) + parseFloat(rs.semana6))}</b>
                        </div>
                    </td>
                </tr>
                
                )}
            
            ))
            


        }).catch((err) => {

            console.log(err.response)

        })


    }


    const enviaValidacao = () => {


        var dataPost = {

            mes: mes,
            ano: ano,
            saldoAnterior: saldoAnterior,
            saldoAtual: saldoAtual,
        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma gerar o fluxo de caixa?',
		    buttons: {
		        Sim: function () {


                        api.post('fluxoCaixaValida', dataPost).then((result) => {

                            //console.log(result.data)
                
                            window.toastr.success('Fluxo de Caixa colocado na fila para validação!')
                            setDataAtualiza(Math.random())
                            return false
                
                        }).catch((err) => {
                
                            console.log(err.response)
                
                        })
                        

                    },
                    Cancelar: function () {
                        //$.alert('Canceled!');
                    }
    
                },
    
            });
    
    
        }


    const consultaDespesas = () => {


        var dataPost = {

            ano: ano,
            mes: mes,
            tipo: "DESPESAS",
        }
        
        api.post('listaMensal', dataPost).then((result) => {

            
            setResultadoDespesas(result.data.map((rs) => {

                return (

                    <tr style={{ fontSize: '11px', backgroundColor: '#faefed'}}>
                         <td>
                            <Link onClick={() => {window.$('.modal-FluxoCaixaDetalheAno').modal('show');setFluxoCaixa(rs.fluxoCaixa);setTipo(rs.tipo)}}>{rs.fluxoCaixa}</Link>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana1)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana2)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana3)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana4)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana5)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            {fmtMoney(rs.semana6)}
                            </div>
                        </td>
                        <td>
                            <div align="right">
                            <b>{fmtMoney(parseFloat(rs.semana1) + parseFloat(rs.semana2) + parseFloat(rs.semana3) + parseFloat(rs.semana4) + parseFloat(rs.semana5) + parseFloat(rs.semana6))}</b>
                            </div>
                        </td>
                    </tr>

                )}
            
            ))
            


        }).catch((err) => {

            console.log(err.response)

        })


    }


    const consultaTotalReceitas = () => {

        var dataPost = {

            ano: ano,
            mes: mes,
            tipo: 'RECEITAS',
        }
        
        api.post('listaMensalTotalTipo', dataPost).then((result) => {

            setResultadoTotalReceitas(result.data.map((rs) => {

                

                return (

                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                        <th>
                            <Link onClick={() => {window.$('.modal-FluxoCaixaDetalheAno').modal('show');setFluxoCaixa();setTipo(rs.tipo)}}>TOTAL</Link>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana1)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana2)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana3)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana4)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana5)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana6)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                                <b>{fmtMoney(parseFloat(rs.semana1) + parseFloat(rs.semana2) + parseFloat(rs.semana3) + parseFloat(rs.semana4) + parseFloat(rs.semana5) + parseFloat(rs.semana6))}</b>
                            </div>
                        </th>
                    </tr>
                    
                )


                }
            
            ))
            


        }).catch((err) => {

            console.log(err.response)

        })


    }

    const consultaTotalDespesas = () => {

        var dataPost = {

            ano: ano,
            mes: mes,
            tipo: "DESPESAS",
        }
        
        api.post('listaMensalTotalTipo', dataPost).then((result) => {

            

            var total = 0

            setResultadoTotalDespesas(result.data.map((rs) => {


            
                return (

                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                        <th>
                            <Link onClick={() => {window.$('.modal-FluxoCaixaDetalheAno').modal('show');setFluxoCaixa();setTipo(rs.tipo)}}>TOTAL</Link>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana1)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana2)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana3)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana4)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana5)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                            {fmtMoney(rs.semana6)}
                            </div>
                        </th>
                        <th>
                            <div align="right">
                                <b>{fmtMoney(parseFloat(rs.semana1) + parseFloat(rs.semana2) + parseFloat(rs.semana3) + parseFloat(rs.semana4) + parseFloat(rs.semana5) + parseFloat(rs.semana6))}</b>
                            </div>
                        </th>
                    </tr>

                )}
            
            ))
            


        }).catch((err) => {

            console.log(err.response)

        })


    }

    const consultaResultado = () => {


        api.get(`listaMensalResultado/${ano}/${mes}`).then((result) => {

            //console.log(result.data)
            var data = result.data
            setResultado(data.map((rs) => 

                <tr style={{ fontSize: '11px', backgroundColor: '#faefe3'}}>
                    <th style={{ textAlign: 'left'}}>
                        TOTAL
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana1 < 0 ? 'red' : 'blue'}} >
                        {fmtMoney(rs.semana1)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana2 < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.semana2)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana3 < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.semana3)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana4 < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.semana4)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana5 < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.semana5)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.semana6 < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.semana6)}
                    </th>
                    <th style={{ textAlign: 'right', color: rs.total < 0 ? 'red' : 'blue'}}>
                        {fmtMoney(rs.total)}
                    </th>

                </tr>
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })


    }

    const consultaTotalGeral = () => {

        api.get(`listaMensalTotalGeral/${ano}/${mes}`).then((result) => {

            setSaldoAnterior(result.data[0].saldoAnterior)
            setSaldoAtual(result.data[0].saldoAnterior + result.data[0].resultado)
            

            setResultadoTotalGeral(result.data.map((rs) => 
            
                <tr style={{ textAlign: 'center', fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                    <th>
                        {fmtMoney(rs.saldoAnterior == null ? 0 : rs.saldoAnterior)}
                    </th>
                    <th>
                        <font color='blue'>{fmtMoney(rs.receitas)}</font>
                    </th>
                    <th>
                        <font color='red'>{fmtMoney(rs.despesas)}</font>
                    </th>
                    <th >
                        
                        <Link onClick={() => {window.$('.modal-FluxoCaixaResultadoAno').modal('show')}}>
                            <font style={{ color: rs.resultado >= 0 ? 'blue' : 'red' }}>
                            {fmtMoney(rs.resultado)}
                            </font>
                        </Link>

                    </th>
                    <th>
                        {fmtMoney((rs.saldoAnterior == null ? 0 : rs.saldoAnterior) + rs.resultado)}
                    </th>

                </tr>
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    async function geraPDF() {


        await api.get(`fluxoCaixaPreviaPDF/${ano}/${mes}`).then((result) => {

            if (result.data) {

                //window.open(`https://api-faj.koderia.com.br/pdf/${result.data.arquivo}`)
                //window.open(`http://192.168.255.165:3378/pdf/${result.data.arquivo}`)
                //window.open(`https://apiportaltransp.fajsaude.com.br/pdf/${result.data.arquivo}`)
                window.open(`${process.env.REACT_APP_API}/pdf/${result.data.arquivo}`)
                

            }

            
        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {
        setTotalSemanasMes(getTotalWeeksInMonth(mes, ano))

        //console.log(getTotalWeeksInMonth(mes, ano))

        carregaAnos()
        carregaMeses()
        consultaReceitas()
        consultaDespesas()
        consultaTotalReceitas()
        consultaTotalDespesas()
        consultaTotalGeral()
        consultaResultado()

    }, [ano, mes])



    useEffect(() => {

        
        //console.log(`Total weeks: ${totalWeeks}`);

        consultaDataUltimoMovimento()

    }, [dataAtualiza])


    return (

        <div>

            <button type="button" class="btn btn-inline btn-danger" onClick={() => geraPDF()}>Exportar PDF</button>
            <button type="button" class="btn btn-inline btn-primary" style={{ display: sessionStorage.getItem('perfil') == 'Gestor' ? 'none' : 'table-row' }} onClick={() => enviaValidacao()}>Enviar extrato para validação</button>
            <button type="button" class="btn btn-inline btn-success" style={{ display: sessionStorage.getItem('perfil') == 'Gestor' ? 'none' : 'table-row' }} onClick={() => {setDataAtualiza(Math.random());window.toastr.success('Atualizado com sucesso!')}}>Atualizar</button>
            
                    
                    <br/><br/>
        
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError">Ano</label>
                                <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                                    {listaAnos}
                                </select>
                                
                            </fieldset>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError2">Mês</label>
                                <select type="text" className="form-control" value={mes} onChange={event => setMes(event.target.value)}>
                                    {listaMeses}
                                </select>
                            </fieldset>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <fieldset className="form-group">
                                <label className="form-label" for="exampleError2">Último movimento</label>
                                <input type="text" className="form-control" value={dataUltimoMovimento} disabled style={{ backgroundColor: '#f7e1c1'}} />
                                    
                            </fieldset>
                        </div>
        
                    </div>
        
        
                    <table id="table-sm" class="table table-bordered table-hover table-sm">
                        <thead>
                        <tr style={{ textAlign: 'center'}}>
                            
                            <th>Saldo Anterior: </th>
                            <th>Receitas: </th>
                            <th>Despesas: </th>
                            <th>Resultado: </th>
                            <th>Saldo atual: </th>
                            
                            
                        </tr>
                        </thead>
                        <tbody>
                            {resultadoTotalGeral}
                        </tbody>
                    </table>
                    <br/>
                    <table id="table-sm" class="table table-bordered table-hover table-sm">
        
                        <tr>
                            <th style={{ backgroundColor: '#afd8e0'}} colspan="8"><center>Receitas</center></th>
                        </tr>
                        <tr style={{ textAlign: 'center', fontWeight: 'bold'}}>
                            <td>Semana</td>
                            <td>1</td>
                            <td>2</td>
                            <td>3</td>
                            <td>4</td>
                            <td>5</td>
                            <td>6</td>
                            <td>Total</td>
                        </tr>
                        
                        <tbody>
                            {resultadoReceitas}
                            {resultadoTotalReceitas}
                            <tr>
                                <td colspan="8"><br/></td>
                            </tr>
                            <tr>
                                <th style={{ backgroundColor: '#e0baaf'}} colspan="8"><center>Despesas</center></th>
                            </tr>
                            {resultadoDespesas}
                            {resultadoTotalDespesas}
                            
                            <tr>
                                <td colspan="8"><br/></td>
                            </tr>
                            <tr>
                                <th style={{ backgroundColor: '#ffdab0'}} colspan="8"><center>Resultado</center></th>
                            </tr>
                            {resultado}
                            
                        </tbody>
                    </table>



                <ModalFluxoCaixaDetalheAno fluxoCaixa={fluxoCaixa} ano={ano} tipo={tipo} mes={mes} />
                <ModalResultadoAno ano={ano} mes={mes} />

        </div>

    )

}

export default FluxoCaixaMensalPrevia