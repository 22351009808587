import { useEffect } from 'react'
import FluxoCaixaMovimentoAtivos from '../componentsPages/FluxoCaixaMovimentoAtivos'
import FluxoCaixaMovimentoPendentes from '../componentsPages/FluxoCaixaMovimentoPendentes'
import { useTotalMovimentoPendenteContext } from '../context/totalMovimentoPendenteContext'
import api from '../components/api'

const FluxoCaixaVinculos = () => {

    const { totalMovimentoPendente, setTotalMovimentoPendente } = useTotalMovimentoPendenteContext()


    const consultaTotalMovimentosPendentes = () => {


        api.get('contaMovimentoPendente').then((result) => {

            setTotalMovimentoPendente(result.data[0].total)

        }).catch((err) => {

            console.log(err.response)

        })



    }


    useEffect(() => {

        if (sessionStorage.getItem('perfil') == 'Administrador') {

            consultaTotalMovimentosPendentes()

        }
        
    }, [])


    return (

        <div>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4-tab-1x" role="tab" data-toggle="tab">
                                Ativos
                            </a>
                        </li>
                        <li className="nav-item" style={{ display: 'none'}}>
                            <a className="nav-link" href="#tabs-4-tab-2x" role="tab" data-toggle="tab">
                                Pendentes de vinculação&nbsp;<span class="label label-pill label-warning">{totalMovimentoPendente}</span>
                                
                            </a>
                        </li>
                        
                    </ul>
                </div>

                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4-tab-1x">



                        <FluxoCaixaMovimentoAtivos />



                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="tabs-4-tab-2x" style={{ display: 'none'}}>


                        <FluxoCaixaMovimentoPendentes />

                    </div>
                    
                </div>
            </section>

        </div>


    )

}

export default FluxoCaixaVinculos