import { useEffect, useState } from "react"
import api from "../components/api"


const Modal = (props) => {

    const materiaPrima_id = props.materiaPrima_id
    const [codigo, setCodigo] = useState('')
    const [descricao, setDescricao] = useState('')




    const salva = () => {


        if (codigo == '') {

            window.toastr.error('<b>Código</b> não pode ficar em branco!')
            return false

        }


        if (descricao == '') {

            window.toastr.error('<b>Descrição</b> não pode ficar em branco!')
            return false

        }


        var dataPost = {

            codigo: codigo,
            descricao: descricao,

        }



        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {



                    if (materiaPrima_id) {

                                
                        api.put(`materiaPrima/${materiaPrima_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-MateriaPrima').modal('hide')
                            props.consultaTodos()
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })


                    } else {

                                
                        api.post('materiaPrima', dataPost).then((result) => {

                            console.log(result.data)
                            limpa()
                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-MateriaPrima').modal('hide')
                            props.consultaTodos()
                            return false

                        }).catch((err) => {

                            console.log(err.response)

                        })




                    }
                          
                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }


    const consulta = () => {

        if (materiaPrima_id) {


            api.get(`materiaPrimaListaUm/${materiaPrima_id}`).then((result) => {

                var data = result.data[0]

                setCodigo(data.codigo)
                setDescricao(data.descricao)

            }).catch((err) => {

                console.log(err.response)

            })



        }

    }

    const limpa = () => {

        setCodigo('')
        setDescricao('')

    }

    useEffect(() => {

        limpa()
        consulta()

    }, [materiaPrima_id])


    return (

        <div>

                <div class={`modal fade modal-MateriaPrima`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Matéria Prima</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Código *</label>
                                        <input type="text" className="form-control" value={codigo}  onChange={event => setCodigo(event.target.value)} maxLength={25} />
                                    </fieldset>
                                </div>
                            
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label" for="exampleError">Descrição *</label>
                                        <input type="text" className="form-control" value={descricao} onChange={event => setDescricao(event.target.value)} maxLength={500} />
                                            
                                        
                                    </fieldset>
                                </div>

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )

}

export default Modal