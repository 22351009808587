
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { fmtMoney } from '../functions/generalFunctions';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import moment from 'moment'


const Contratos = () => {

    const navigate = useNavigate()


    const anoAtual = moment().year()
    const [ano, setAno] = useState(anoAtual.toString())
    const [listaAnos, setListaAnos] = useState()



    function carregaAnos() {

      var x_array = []

      for (var x = 2024; x <= anoAtual; x++) {
          
          x_array.push(x)

      }


      setListaAnos(x_array.sort().reverse().map((ano) =>
      
          <option value={ano}>{ano}</option>
      
      ))
      

  }





    const consulta = () => {


        api.get('listaContratoTodos').then((result) => {

            

            setResultado(result.data.map((rs) => {

                return {
                    
                    nome: rs.nome,
                    usuario_id: rs.usuario_id,
                    cnpj: rs.cnpj,
                    grupo: rs.grupo,
                    servico: rs.servico,
                    assinatura: rs.assinatura,
                    baseAtual: fmtMoney(rs.baseAtual).toString().replaceAll('R$', ''),
                    nrContrato: rs.nrContrato,
                    rh: rs.rh,
                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }



    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'nome', //access nested data with dot notation
            header: 'Nome',
           
          },
          {
            accessorKey: 'cnpj', //access nested data with dot notation
            header: 'CNPJ',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          
          {
            accessorKey: 'baseAtual', //normal accessorKey
            header: 'Base atual',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
          },
          {
            accessorKey: 'grupo',
            header: 'Grupo',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'servico',
            header: 'Serviço',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'nrContrato',
            header: 'Contrato',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'assinatura',
            header: 'Assinatura',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'rh',
            header: 'RH',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          
        ],
        [],
      );



    useEffect(() => {

        consulta()
        carregaAnos()

    }, [])


    return (


        <div>


            <h5 className="m-t-lg with-border">Contratos</h5>

            <div className="row">

                <div className="col-md-2 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>
                        
                    </fieldset>
                </div>

                <div className="col-md-12 col-sm-6">
                  


                  <MaterialReactTable 
                      
                      columns={columns} 
                      data={resultado} 
                      localization={MRT_Localization_PT_BR}
                      //rowsPerPage={20}
                      //options={options}
                      initialState={{ density: 'compact' }}
                      muiTableHeadCellProps={{
                          //easier way to create media queries, no useMediaQuery hook needed.
                          sx: {
                            fontSize: {
                              xs: '8px',
                              sm: '9px',
                              md: '10px',
                              lg: '11px',
                              xl: '12px',
                            },
                          },
                        }}
                      muiTableBodyCellProps={{
                          sx: {
                              fontSize: {
                                xs: '8px',
                                sm: '9px',
                                md: '10px',
                                lg: '11px',
                                xl: '12px',
                              },
                            },
                      }}

                      muiTableBodyRowProps={({ row }) => ({
                          onClick: (event) => {
                            //console.info(event, row.original.usuario_id);
                            //navigate(`/Usuario/${row.original.usuario_id}`)

                          },
                          sx: {
                            //cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                          },
                        })}
                      
                  />

                </div>

            </div>

        
        </div>
        
    )

}


export default Contratos