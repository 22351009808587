import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import api from '../components/api'


const Opme = () => {

    const navigate = useNavigate()

    let { slug } = useParams()

    const [opme_id, setOpme_id] = useState(slug)
    const [codigo, setCodigo] = useState('')
    const [descricao, setDescricao] = useState('')

    const salvar = () => {

        var dataPost = {
            codigo: codigo,
            descricao: descricao,
        }

        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
            title: 'Confirmação',
            content: 'Confirma os dados?',
            buttons: {
                Sim: function () {

                    if (opme_id) {

                        api.put(`opme/${opme_id}`, dataPost).then((result) => {

                            console.log(result.data)
                            window.toastr.success('Registro salvo com sucesso!')


                        }).catch((err) => {

                            console.log(err.response)

                        })

                    } else {



                        api.post('opme', dataPost).then((result) => {

                            
                            setOpme_id(result.data[0].opme_id)
                            window.toastr.success('Registro salvo com sucesso!')


                        }).catch((err) => {

                            console.log(err.response)

                        })

                    }


                },
                Cancelar: function () {


                },
            }
        })


    }

    const apagar = () => {

        window.$.confirm({
            //icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
            title: 'Confirmação',
            content: 'Confirma apagar?',
            buttons: {
                Sim: function () {



                    api.delete(`opme/${opme_id}`).then((result) => {

                        //console.log(result.data)
                        window.toastr.success('Registro apagado com sucesso!')
                        navigate('/OPMEs')


                    }).catch((err) => {

                        console.log(err.response)

                    })


                },
                Cancelar: function () {


                },
            }
        })


    }


    const carregaDados = () => {

        api.get(`opmeListaUm/${opme_id}`).then((result) => {

            var data = result.data[0]

            //console.log(data)

            setCodigo(data.codigo)
            setDescricao(data.descricao)

        }).catch((err) => {

            console.log(err.response)

        })

    }


    useEffect(() => {

        carregaDados()

    }, [])

    return (

        <div>


        <h5 className="m-t-lg with-border">OPME</h5>

        <br/>

            <div className="row">
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError2">Código *</label>
                        <input type="text" className="form-control" value={codigo} onChange={event => setCodigo(event.target.value)} />
                    </fieldset>
                </div>

                <div className="col-md-9">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Descrição *</label>
                        <input type="text" className="form-control" value={descricao} onChange={event => setDescricao(event.target.value)} style={{ textTransform: 'uppercase' }} />
                    </fieldset>
                </div>

                <div className="col-md-12">

                    <button type="button" class="btn btn-inline btn-warning" onClick={() => navigate('/Opmes')}>Voltar</button>
                    <button type="button" class="btn btn-inline btn-danger" style={{ display: opme_id ? 'table-row' : 'none' }} onClick={() => apagar()}>Apagar</button>
                    <button type="button" class="btn btn-inline btn-primary" onClick={() => salvar()}>Salvar</button>


                </div>



            </div>


        </div>

    )

}


export default Opme