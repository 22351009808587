
import { useEffect, useState } from 'react'
import CurrencyInput from '../components/CurrencyInput'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'



const PlanejConsumoDetalhe = (props) => {


    const planejamento_id = props.planejamento_id


    const [siafisicoPregao, setSiafisicoPregao] = useState('')
    const [descricao, setDescricao] = useState('')
    const [ano, setAno] = useState('')
    const [estoque, setEstoque] = useState('')
    const [grupo, setGrupo] = useState('')
    const [item_id, setItem_id] = useState('')
    const [metaCompra, setMetaCompra] = useState('')
    const [metaConsumo, setMetaConsumo] = useState('')
    const [valorReferencial, setValorReferencial] = useState('')


    const limpaDados = () => {

        setSiafisicoPregao('')
        setDescricao('')
        setAno('')
        setEstoque('')
        setGrupo('')
        setItem_id('')
        setMetaCompra('')
        setMetaConsumo('')
        setValorReferencial('')

    }


    const consulta = () => {


        if (planejamento_id) {


            api.get(`planejamento/${planejamento_id}`).then((result) => {

                var data = result.data[0]

                setSiafisicoPregao(data.siafisicoPregao)
                setDescricao(data.descricao)
                setAno(data.ano)
                setEstoque(data.estoque)
                setGrupo(data.grupo)
                setItem_id(data.item_id)
                setMetaCompra(data.metaCompra)
                setMetaConsumo(data.metaConsumo)
                setValorReferencial(fmtMoney(data.valorReferencial, 2).replaceAll('R$', ''))

            }).catch((err) => {

                console.log(err.response)

            })


        }


    }


    const salvar = () => {

        if (item_id == '' || item_id == null) {

            window.toastr.error('<b>Cód. Siafisico</b> não pode ficar em branco!')
            return false;

        }

        if (metaCompra == '') {

            window.toastr.error('<b>Meta Compra</b> não pode ficar em branco!')
            return false;

        }

        if (metaConsumo == '') {

            window.toastr.error('<b>Meta Consumo</b> não pode ficar em branco!')
            return false;

        }

        if (valorReferencial == '') {

            window.toastr.error('<b>Valor referencial</b> não pode ficar em branco!')
            return false;

        }

        var dataPost = {

            item_id: item_id,
            estoque: estoque,
            metaCompra: metaCompra,
            metaConsumo: metaConsumo,
            valorReferencial: valorReferencial.replaceAll('.', '').replaceAll(',','.'),

        }

        //console.log(dataPost)


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar?',
		    buttons: {
		        Sim: function () {

                    if (planejamento_id) {


                        api.put(`planejamento/${planejamento_id}`, dataPost).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro salvo com sucesso!')
                                //window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })


                    } else {


                        api.post('planejamento', dataPost).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro salvo com sucesso!')
                                //window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })


                    }
                    
                        
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });



    }

    const apagar = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar o empenho?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`planejamento/${planejamento_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-PlanejConsumoEdit').modal('hide')
                                props.carregaPlanej()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });

        
    }


    const infoItem = () => {

        if (siafisicoPregao != '') {

            api.get(`infoItem/${siafisicoPregao}`).then((result) => {

                if (result.data) {

                    setDescricao(result.data[0].descricao)
                    setItem_id(result.data[0].item_id)

                } else {

                    window.toastr.error('<b>Sisfisico</b> não encontrado!')
                    setDescricao('')
                    setItem_id('')

                }

                

            }).catch((err) => {

                console.log(err.response)
                window.toastr.error('<b>Sisfisico</b> não encontrado!')
                setDescricao('')
                setItem_id('')

            })

        }
        
    }



    useEffect(() => {

        limpaDados()
        consulta()
       

    }, [planejamento_id])


    return (
        <div>
            

            <div className="row">
                <div className="col-md-12">
                    <fieldset className="form-group">
                        <label className="form-label">Cód. Siafisico</label>
                        <input type="text" className="form-control" value={siafisicoPregao} style={{ backgroundColor: '#d7e5f7'}} onChange={event => setSiafisicoPregao(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        onBlur={() => infoItem()}
                        />
                    </fieldset>
                </div>
                
                <div className="col-md-12">
                    <fieldset className="form-group">
                        <label className="form-label">Descrição</label>
                        <textarea type="text" className="form-control" value={descricao}  rows="4" disabled={true} />
                    </fieldset>
                </div>


                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Estoque</label>
                        <input type="text" className="form-control" value={estoque} onChange={event => setEstoque(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Meta Compra</label>
                        <input type="text" className="form-control" value={metaCompra} onChange={event => setMetaCompra(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Meta Consumo</label>
                        <input type="text" className="form-control" value={metaConsumo} onChange={event => setMetaConsumo(event.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                            }
                        }} 
                        />
                    </fieldset>
                </div>
                <div className="col-md-3">
                    <fieldset className="form-group">
                        <label className="form-label">Valor Referencial</label>
                        <CurrencyInput type="text" className="form-control" value={valorReferencial} onChange={event => setValorReferencial(event.target.value)} placeholder="0,00" />
                    </fieldset>
                </div>


                <div className="col-md-12">
                    <br/>
                    <div align="right">
                        <button type="button" className="btn btn-inline btn-danger-outline" style={{ display: planejamento_id ? 'table-row' : 'none' }} onClick={() => apagar()}>Apagar</button>
                        <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salvar()}>Salvar</button>
                    </div>
                </div>
                      
                

            </div>


        </div>
    )

}

export default PlanejConsumoDetalhe