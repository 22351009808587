import { useEffect, useState } from "react"
import api from "../components/api"


const Modal = (props) => {

    const pedidoCompra_id = props.pedidoCompra_id

    const [motivoCancelamento, setMotivoCancelamento] = useState('')
    

    const salva = () => {

        
        if (motivoCancelamento == '') {

            window.toastr.error('<b>Motivo do cancelamento</b> não pode ficar em branco!')
            return false

        }


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma cancelar o pedido?',
		    buttons: {
		        Sim: function () {

                    var dataPost = {

                        motivoCancelamento: motivoCancelamento,
                        usuario: sessionStorage.getItem('nome'),

                    }

                    api.put(`/pedidoCompraCancela/${pedidoCompra_id}`, dataPost).then((result) => {

                        console.log(result.data)
                        //window.open(`${process.env.REACT_APP_API}/${result.data.arquivo}`)
                        props.carregaPedido()
                        props.carregaPedidos()
                        window.$('#modalCancelaPedido').modal('hide')
                        window.$('#modalPedidoCompra').modal('hide')
                        props.show(false)
                        window.toastr.success('Solicitação cancelada com sucesso!')


                    }).catch((err) => {

                        console.log(err.response)

                    })

                    /*

                                
                    api.put(`usuarioSenha/${usuario_id}`, dataPost).then((result) => {

                        //console.log(result.data)
                        limpa()
                        window.toastr.success('Senha alterada com sucesso!')
                        window.$('.modal-AlteraSenha').modal('hide')
                        return false

                    }).catch((err) => {

                        console.log(err.response)

                    })


                    */
                
                

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });




    }

    const limpa = () => {


        setMotivoCancelamento('')

    }

    useEffect(() => {

        limpa()

    }, [pedidoCompra_id])


    return (


        <div>

                <div class={`modal fade modal-modalCancelaPedido`}
                    id="modalCancelaPedido"
                    tabindex="-1"
                    role="dialog"
                    style={{ zIndex: 1060 }}
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Cancelar Pedido</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-12">
                                    <fieldset className="form-group">
                                        <label className="form-label">Motivo do cancelamento</label>
                                        <textarea className="form-control" value={motivoCancelamento}  onChange={event => setMotivoCancelamento(event.target.value)} maxLength={1000} rows="4" />
                                    </fieldset>
                                </div>
                            
                               

                            </div>

                           

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => salva()}  >Salvar cancelamento</button>
                            
                        </div>
                    </div>
                </div>
            </div>


        </div>


    )

}

export default Modal