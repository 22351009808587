export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
 }


export function validaCPF(cpf) {

    var numeros, digitos, soma, i, resultado, digitos_iguais;
    digitos_iguais = 1;
    if (cpf.length < 11)
            return false;
    for (i = 0; i < cpf.length - 1; i++)
            if (cpf.charAt(i) != cpf.charAt(i + 1))
                {
                digitos_iguais = 0;
                break;
                }
    if (!digitos_iguais)
            {
            numeros = cpf.substring(0,9);
            digitos = cpf.substring(9);
            soma = 0;
            for (i = 10; i > 1; i--)
                soma += numeros.charAt(10 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(0))
                return false;
            numeros = cpf.substring(0,10);
            soma = 0;
            for (i = 11; i > 1; i--)
                soma += numeros.charAt(11 - i) * i;
            resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
            if (resultado != digitos.charAt(1))
                return false;
            return true;
            }
    else
        return false;

}

 

export function validaCNPJ(str){
    str = str.replace('.','');
    str = str.replace('.','');
    str = str.replace('.','');
    str = str.replace('-','');
    str = str.replace('/','');
    var cnpj = str;
    var numeros, digitos, soma, i, resultado, pos, tamanho, digitos_iguais;
    digitos_iguais = 1;
    if (cnpj.length < 14 && cnpj.length < 15)
        return false;
    for (i = 0; i < cnpj.length - 1; i++)
        if (cnpj.charAt(i) != cnpj.charAt(i + 1))
    {
        digitos_iguais = 0;
        break;
    }
    if (!digitos_iguais)
    {
        tamanho = cnpj.length - 2
        numeros = cnpj.substring(0,tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--)
        {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0,tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--)
        {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;
        return true;
    }
    else
        return false;
}


    
/*
export function fmtMoney(n, c, d, t){ 
    var m = (c = Math.abs(c) + 1 ? c : 2, d = d || ",", t = t || ".", 
       /(\d+)(?:(\.\d+)|)/.exec(n + "")), x = m[1].length > 3 ? m[1].length % 3 : 0; 

    return (x ? m[1].substr(0, x) + t : "") + m[1].substr(x).replace(/(\d{3})(?=\d)/g, 
       "$1" + t) + (c ? d + (+m[2] || 0).toFixed(c).substr(2) : ""); 
 }

 
*/

export function removeDuplicates(originalArray, prop) {
    
    var newArray = [];
    var lookupObject  = {};

    for(var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}



export function fmtMes(valor) {

    switch (parseInt(valor)) {

        case 1:
            return 'Janeiro'
            break;

        case 2:
            return 'Fevereiro'
            break;

        case 3:
            return 'Março'
            break;

        case 4:
            return 'Abril'
            break;

        case 5:
            return 'Maio'
            break;

        case 6:
            return 'Junho'
            break;

        case 7:
            return 'Julho'
            break;

        case 8:
            return 'Agosto'
            break;

        case 9:
            return 'Setembro'
            break;

        case 10:
            return 'Outubro'
            break;

        case 11:
            return 'Novembro'
            break;

        case 12:
            return 'Dezembro'
            break;



    }

}


export function fmtMoney(valor, centavos) {

    if (centavos == null) { centavos = 0 }

    return valor.toLocaleString(
        'pt-BR', 
            { 
            style: 'currency', 
            currency: 'BRL',
            minimumFractionDigits: centavos,
            maximumFractionDigits: centavos,
            })
}





export function getTotalWeeksInMonth(year, month) {
    const firstOfMonth = new Date(year, month - 1, 1);
    const lastOfMonth = new Date(year, month, 0);
    const daysInMonth = lastOfMonth.getDate();
    
    const startDay = firstOfMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
    const totalDays = daysInMonth + startDay; // Total days including preceding days from previous month
    const totalWeeks = Math.ceil(totalDays / 7); // Round up to the nearest whole week
    
    return totalWeeks;
  }
  
  