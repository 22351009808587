import { useEffect, useState, useMemo } from 'react'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMes, fmtMoney, sleep } from '../functions/generalFunctions';

import moment from 'moment'

import ModalProcesso from '../modal/ModalProcesso'


const Processos = () => {


    const anoAtual = moment().year()
    const [listaAnos, setListaAnos] = useState()   
    const [ano, setAno] = useState(anoAtual.toString())
    const [mes, setMes] = useState('')
    const [listaMeses, setListaMeses] = useState()
    const [empenhado, setEmpenhado] = useState(true)
    
    const [resultado, setResultado] = useState([])
    const [processo_id, setProcesso_id] = useState()


    const carregaAnos = () => {

        var x_array = []

        for (var x = 2023; x <= anoAtual; x++) {

            x_array.push(x)

        }

        setListaAnos(x_array.sort().reverse().map((ano) =>

            <option value={ano}>{ano}</option>

        ))


    }




    async function exportaExcel() {



      await api.get(`exportaExcelProcesso/${ano}/${empenhado}`).then((result) => {

        

        if (result.data.status == 'ok') {

          window.toastr.info('Aguarde, gerando XLSX...')

            sleep(2000).then(() => {

                //console.log(result.data[0].fileNameCompleto, '!!')
                //window.location.href = result.data.arquivo
                window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
            })
            

        }

    }).catch((err) => {

        console.log(err.response)

    })


  }




    const carregaMeses = () => {

      var x_array = []

      for (var x = 1; x <= 12; x++) {

          x_array.push(x)

      }

      setListaMeses(x_array.map((mes) => 
      
          <option value={mes}>{fmtMes(mes)}</option>
      
      ))


  }


    const columns = useMemo(
        () => [
          {
            accessorKey: 'siafisicoPregao', //access nested data with dot notation
            header: 'Siafisico',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'descricao', //access nested data with dot notation
            header: 'Descrição',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'processo',
            header: 'Processo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'dataInicio', //normal accessorKey
            header: 'Data início',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'quantidade',
            header: 'Quantidade',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'valorPesqPreco',
            header: 'Pesq. Preço',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'valorTotalPrevisto',
            header: 'Total Previsto',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          {
            accessorKey: 'modalidade',
            header: 'Modalidade',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          
          
        ],
        [],
      );


      const carregaProcessos = () => {


    
        api.get(`processos/${ano}/${empenhado}`).then((result) => {


            const data = result.data
            //console.log(data)
            

            setResultado(data.map((rs) => {


                return {

                    item_id: rs.item_id,
                    siafisicoPregao: rs.siafisicoPregao,
                    descricao: rs.descricao,
                    processo: rs.processo,
                    dataInicio: rs.dataInicio,                    
                    quantidade: rs.quantidade,
                    processo_id: rs.processo_id,
                    modalidade: rs.modalidade,
                    
                    valorPesqPreco: rs.valorPesqPreco != null ? fmtMoney(rs.valorPesqPreco, 2) : '0,00',
                    valorTotalPrevisto: rs.valorTotalPrevisto != null ? fmtMoney(rs.valorTotalPrevisto, 2) : '0,00',


                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {

      carregaAnos()
      //carregaMeses()

    }, [])


    useEffect(() => {
        
      carregaProcessos()
        

    }, [ano, empenhado])


    return (

        <div>


            <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-Processo').modal('show');setProcesso_id()}}>+ Novo</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()} >Exportar Excel</button>
                    
            <br/><br/>


            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Ano</label>
                        <select type="text" className="form-control" value={ano} onChange={event => setAno(event.target.value)}>
                            {listaAnos}
                        </select>

                    </fieldset>
                </div>
                <div className="col-md-6 col-sm-6">
                    <fieldset className="form-group">
                        <label className="form-label" for="exampleError">Processos</label>
                        <select type="text" className="form-control" value={empenhado} onChange={event => setEmpenhado(event.target.value)}>
                            <option value={true}>Somente processos sem empenho</option>
                            <option value={false}>Todos os processos abertos</option>
                            
                        </select>

                    </fieldset>
                </div>
                

            </div>



            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                      fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                      },
                    },
                  }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                          xs: '8px',
                          sm: '9px',
                          md: '10px',
                          lg: '11px',
                          xl: '12px',
                        },
                      },
                }}

                muiTableBodyRowProps={({ row }) => ({
                  onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                    window.$('.modal-Processo').modal('show');setProcesso_id(row.original.processo_id)

                  },
                  sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                  },
                })}
                
            />



        
        <ModalProcesso processo_id={processo_id} carregaProcessos={carregaProcessos} />


        </div>
    )


}

export default Processos