import { useEffect, useState, useMemo } from "react"
import moment from 'moment'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { ExportToCsv } from 'export-to-csv';
import ModalMovimentoEdit from '../modal/ModalMovimentoEdit'


import api from '../components/api'


const FluxoCaixaMovimentoPendentes = () => {

    const [movimento_id, setMovimento_id] = useState()
    const [resultado, setResultado] = useState([])
      
      const columns = useMemo(
        () => [
          {
            accessorKey: 'data', //access nested data with dot notation
            header: 'Data',
          },
          {
            accessorKey: 'area',
            header: 'Área',
          },
          {
            accessorKey: 'titulo',
            header: 'Título',
          },
          {
            accessorKey: 'fluxoCaixa', //normal accessorKey
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'banco',
            header: 'Banco',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'agencia',
            header: 'Agência',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'contaBanco',
            header: 'Conta Bancária',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'codigoNatureza',
            header: 'Cód. Natureza',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'itemConta',
            header: 'Item Contábil',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'documento',
            header: 'Documento',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'entrada',
            header: 'Entrada',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'saida',
            header: 'Saída',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          {
            accessorKey: 'resultado',
            header: 'Resultado',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
        ],
        [],
      );

    /*
      const options = {
        // ...
        rowStyle: (rowData) => {
          return {
            fontFamily: "Mulish-Regular",
            backgroundColor: rowBackgroundColors[rowData.priority] ?? "#fff",
          };
        },
        // ...
      };

    */


    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };
    
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = () => {
      csvExporter.generateCsv(resultado);
    };
  


    const consulta = () => {


        api.get('listaMovimentoPendente').then((result) => {

            

            setResultado(result.data.map((rs) => {

                return {

                    data: rs.data,
                    area: rs.area,
                    banco: rs.banco,
                    fluxoCaixa: rs.fluxoCaixa,
                    agencia: rs.agencia,
                    contaBanco: rs.contaBanco,
                    codigoNatureza: rs.codigoNatureza,
                    documento: rs.documento,
                    entrada: rs.entrada,
                    saida: rs.saida,
                    resultado: rs.resultado,
                    movimento_id: rs.movimento_id,
                    itemConta: rs.itemConta,
                    titulo: rs.titulo,

                }

            }))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

        //consulta()
        

    }, [])


    return (

        <div>
            <button type="button" class="btn btn-inline btn-secondary" onClick={handleExportRows}>Exportar CSV</button>


            <MaterialReactTable 
                        
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                    }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                            xs: '8px',
                            sm: '9px',
                            md: '10px',
                            lg: '11px',
                            xl: '12px',
                        },
                        },
                }}
                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                    window.$('.modal-MovimentoEditPend').modal('show');setMovimento_id(row.original.movimento_id)

                    },
                    sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
            />



            <ModalMovimentoEdit movimento_id={movimento_id} origem="Pend" />

        </div>

    )

}

export default FluxoCaixaMovimentoPendentes
