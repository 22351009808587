import ConsumoCatalogo from "../componentsPages/ConsumoCatalogo"
import ConsumoPlanejamentoAnual from '../componentsPages/ConsumoPlanejamentoAnual'
import ConsumoExecucao from '../componentsPages/ConsumoExecucao'
import ConsumoProcesso from '../componentsPages/ConsumoProcesso'
import ConsumoIndicadores from '../componentsPages/ConsumoIndicadores'
import ConsumoExecucaoItem from '../componentsPages/ConsumoExecucaoItem'


const Consumo = () => {

    return (

        <div>


            <h5 className="m-t-lg with-border">Consumo</h5>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-icons" >
                    <div className="tbl">
                        <ul className="nav" role="tablist">
                            <li className="nav-item" >
                                <a className="nav-link active" href="#tabs-1X-tab-1" role="tab" data-toggle="tab">
                                    <span className="nav-link-in">
                                        <i className="font-icon font-icon-cogwheel"></i>
                                        Cadastro
                                    </span>
                                </a>
                            </li>
                            
							<li className="nav-item">
                                <a className="nav-link" href="#tabs-1X-tab-3" role="tab" data-toggle="tab">
                                    <span className="nav-link-in">
                                    <i className="font-icon font-icon-folder"></i>
                                        Consultas 
                                    </span>
                                </a>
                            </li>
                           
                            
                        </ul>
                    </div>
                </div>

                <div class="tab-content">
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-1X-tab-1" >
                        
						<section className="tabs-section">
							<div className="tabs-section-nav tabs-section-nav-inline">
								<ul className="nav" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" href="#tabs-4b-tab-1b" role="tab" data-toggle="tab">
											Catálogo
										</a>
									</li>
									

									<li className="nav-item">
										<a className="nav-link" href="#tabs-4b-tab-3b" role="tab" data-toggle="tab">
											Planejamento
										</a>
									</li>

									<li className="nav-item">
										<a className="nav-link" href="#tabs-4b-tab-4b" role="tab" data-toggle="tab">
											Fase 1
										</a>
									</li>

									<li className="nav-item">
										<a className="nav-link" href="#tabs-4b-tab-5b" role="tab" data-toggle="tab">
											Fase 2
										</a>
									</li>
									
									
									
								</ul>
							</div>

							<div className="tab-content">
								<div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1b">

									<ConsumoCatalogo />

								</div>
								

								<div role="tabpanel" className="tab-pane fade" id="tabs-4b-tab-3b">

									<ConsumoPlanejamentoAnual />

								</div>

								<div role="tabpanel" className="tab-pane fade" id="tabs-4b-tab-4b">

									<ConsumoProcesso />

								</div>
								<div role="tabpanel" className="tab-pane fade" id="tabs-4b-tab-5b">

									
									<ConsumoExecucao />

								</div>
								
								
							</div>
                    </section>
                        
                    
                    </div>
                   
					<div role="tabpanel" className="tab-pane fade" id="tabs-1X-tab-3"  >
                        
                        <section className="tabs-section">
							<div className="tabs-section-nav tabs-section-nav-inline">
								<ul className="nav" role="tablist">
									<li className="nav-item">
										<a className="nav-link active" href="#tabs-4b-tab-1cx" role="tab" data-toggle="tab">
											
										Consultar Item

										</a>
									</li>
									<li className="nav-item">
										<a className="nav-link " href="#tabs-4b-tab-2cx" role="tab" data-toggle="tab">
											
											Indicadores
										</a>
									</li>
									
									
									
									
								</ul>
							</div>

							<div className="tab-content">
								<div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1cx">

									<ConsumoExecucaoItem />

								</div>
								<div role="tabpanel" className="tab-pane fade" id="tabs-4b-tab-2cx">

									
									<ConsumoIndicadores />


								</div>
								
								
								
							</div>
                        </section>
                        

                    </div>
                   
                </div>
            </section>

            


        </div>


    )

}

export default Consumo