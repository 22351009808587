import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Layout from './components/Layout'

import Login from './pages/Login'
import Home from './pages/Home'
import Financeiro from './pages/Financeiro'

import CentroCusto from './pages/CentroCusto'

import Usuarios from './pages/Usuarios'
import Usuario from './pages/Usuario'
import Importacoes from './pages/Importacoes'
import Compras from './pages/Compras'
import Compra from './pages/Compra'
import Consumo from './pages/Consumo'
import Orcamento from './pages/Orcamento'
import Contratos from './pages/Contratos'
import FluxoCaixa from './pages/FluxoCaixa'
import Calendario from './pages/Calendario'
import ComprasPedido from './pages/ComprasPedido'
import FinanceiroAdm from './pages/FinanceiroAdm'
import MateriaPrima from './pages/MateriaPrima'

import Fornecedores from './pages/Fornecedores'
import Fornecedor from './pages/Fornecedor'

import Opmes from './pages/Opmes'
import Opme from './pages/Opme'

import Downloads from './pages/Downloads'
import DownloadsAdm from './pages/DownloadsAdm'

import { ProtectedRoute } from "./components/protected.route";


import AtualizaContext from './context/atualizaContext'
import TotalVinculoPendenteContext from './context/totalVinculoPendenteContext'
import TotalMovimentoPendenteContext from './context/totalMovimentoPendenteContext'

import ScrollIntoView from './components/ScrollIntoView';
import ScrollToTop from "./components/scrollToTop";

import Solicitacoes from './pages/ComprasSolicitacoes';
import Medicoes from './pages/Medicoes';


const Rotas = () => {


    return (

        <BrowserRouter>

                <ScrollToTop />

                <AtualizaContext>
                <TotalVinculoPendenteContext>
                <TotalMovimentoPendenteContext>
                
                    <Routes>
                    

                        <Route element={<Login />} path='/' />

                        <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
                            
                            <Route path="/Inicio" element={<Home />} />                          
                                                        
                            <Route element={<Financeiro />} path='/Financeiro' />                            
                            <Route element={<Compras />} path='/Compras' />
                            <Route element={<Compra />} path='/Compra' />
                            <Route element={<Compra />} path='/Compra/:slug' />
                            <Route element={<Calendario />} path='/Compras/Calendario' />
                            <Route element={<Solicitacoes />} path='/Compras/Solicitacoes' />
                            <Route element={<Medicoes />} path='/Compras/Medicoes' />
                            <Route element={<CentroCusto />} path='/CentroCusto' />                        
                            <Route element={<Consumo />} path='/Planejamento' />
                            <Route element={<Orcamento />} path='/Orcamento' />
                            <Route element={<Contratos />} path='/Contratos' />
                            <Route element={<Usuarios />} path='/Usuarios' />
                            <Route element={<Usuario />} path='/Usuario/:slug' />
                            <Route element={<Usuario />} path='/Usuario' />                            
                            <Route element={<FluxoCaixa />} path='/FluxoCaixa' />
                            <Route element={<Importacoes />} path='/Importacoes' />                            
                            <Route element={<Opmes />} path='/OPMEs' />
                            <Route element={<Opme />} path='/OPME' />
                            <Route element={<Opme />} path='/OPME/:slug' />
                            <Route element={<MateriaPrima />} path='/MateriaPrima' />                            
                            <Route element={<Fornecedores />} path='/Fornecedores' />
                            <Route element={<Fornecedor />} path='/Fornecedor' />
                            <Route element={<Fornecedor />} path='/Fornecedor/:slug' />
                            <Route element={<FinanceiroAdm />} path='/Financeiro/Administracao' />
                            <Route element={<Downloads />} path='/Downloads' />
                            <Route element={<DownloadsAdm />} path='/Downloads/Administracao' />


                            
                        
                        </Route>
                        

                    </Routes>
                </TotalMovimentoPendenteContext>
                </TotalVinculoPendenteContext>            
                </AtualizaContext>

            

        </BrowserRouter>

    )

}


export default Rotas