
import { useEffect, useState, PureComponent } from 'react'
import api from '../components/api'
import { fmtMoney } from '../functions/generalFunctions'

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${payload[0].value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</p>
          
        </div>
      );
    }
  
    return null;
  };

class CustomizedLabel extends PureComponent {
    render() {
      const { x, y, stroke, value } = this.props;
  
      return (
        <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
          
        </text>
      );
    }
  }


const ModalFluxoCaixaDetalheAno = (props) => {

    const tipo = props.tipo
    const ano = props.ano
    const mes = props.mes
    const fluxoCaixa = props.fluxoCaixa

    const [resultado, setResultado] = useState([])
    const [dataChart, setDataChart] = useState([])
    const [totalP, setTotalP] = useState()
    const [totalM, setTotalM] = useState()
    const [tipoTotal, setTipoTotal] = useState()
    const [totalOrcamentoAno, setTotalOrcamentoAno] = useState()
    const [totalOrcamentoAnoPerc, setTotalOrcamentoAnoPerc] = useState(0)

    const [totalAnoPerc, setTotalAnoPerc] = useState(0)


    const consulta = () => {

        switch (tipo) {

            case 'RECEITAS':
            
                setTipoTotal('Arrecadado')
                break;

            case 'DESPESAS':

                setTipoTotal('Gasto')
                break;

        }

        if (tipo && ano && fluxoCaixa) {


            var dataPost = {

                tipo: tipo,
                ano: ano,
                mes: mes,
                fluxoCaixa: fluxoCaixa,

            }

            

            api.post('fluxoCaixaAno', dataPost).then((result) => {

                
                var data = result.data[0]

                //console.log(data)

                setTotalP(fmtMoney(result.data[1].totalP))
                setTotalM(fmtMoney(result.data[2].totalM))
                
                setTotalOrcamentoAno(result.data[3].totalOrcamentoAno ? fmtMoney(result.data[3].totalOrcamentoAno) : 0)
                setTotalOrcamentoAnoPerc(result.data[3].percAtingidoAno == null ? 0 : result.data[3].percAtingidoAno.toFixed(2).replaceAll('.', ',') + '%')

                //console.log(result.data[4])

                setTotalAnoPerc(result.data[4].percOrcado == null ? 0 : result.data[4].percOrcado.toFixed(2).replaceAll('.', ',') + '%')
                                
                setResultado(data.map((rs) => 
                    
                    <tr style={{ fontSize: '11px'}}>

                        <td style={{ textAlign: 'center'}}>
                            {rs.mesNome}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.meta)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.total)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.perc}
                        </td>

                    </tr>
                    

                ))

                setDataChart(data.map((rs) => {

                    return {

                        name: rs.mesNome,
                        total: tipo == 'DESPESAS' ? rs.total * -1 : rs.total,
                        meta: rs.meta,
                    }

                }
                
                ))

            }).catch((err) => {

                console.log(err)

            })



        }


    }


    const consultaTotal = () => {

        if (tipo && ano) {

            switch (tipo) {

                case 'RECEITAS':
                
                    setTipoTotal('Arrecadado')
                    break;
    
                case 'DESPESAS':
    
                    setTipoTotal('Gasto')
                    break;
    
            }

            var dataPost = {

                tipo: tipo,
                ano: ano,
                mes: mes,

            }

            api.post('fluxoCaixaAnoTotal', dataPost).then((result) => {

                
                var data = result.data[0]
                
                setTotalP(fmtMoney(result.data[1].totalP))
                setTotalM(fmtMoney(result.data[2].totalM))

                setTotalOrcamentoAno(result.data[3].totalOrcamentoAno ? fmtMoney(result.data[3].totalOrcamentoAno) : 0)
                setTotalOrcamentoAnoPerc(result.data[3].percAtingidoAno == null ? 0 : result.data[3].percAtingidoAno.toFixed(2).replaceAll('.', ',') + '%')

                setTotalAnoPerc(result.data[4].percOrcado == null ? 0 : result.data[4].percOrcado.toFixed(2).replaceAll('.', ',') + '%')

                                
                setResultado(data.map((rs) => 
                    
                    <tr style={{ fontSize: '11px'}}>

                        <td style={{ textAlign: 'center'}}>
                            {rs.mesNome}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.meta)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {fmtMoney(rs.total)}
                        </td>
                        <td style={{ textAlign: 'right'}}>
                            {rs.perc.toFixed(2).replaceAll('.', ',') + '%'}
                        </td>

                    </tr>
                    

                ))

                setDataChart(data.map((rs) => {

                    return {

                        name: rs.mesNome,
                        total: tipo == 'DESPESAS' ? rs.total * -1 : rs.total,
                        meta: rs.meta,
                    }

                }
                
                ))

            }).catch((err) => {

                console.log(err)

            })



        }
        

    }


    const limpaDados = () => {

        setResultado([])
        setDataChart([])
        setTotalP()
        setTotalM()
        setTotalAnoPerc()
        setTipoTotal()
        setTotalOrcamentoAno()
        setTotalOrcamentoAnoPerc()


    }


    useEffect(() => {

        limpaDados()

        if (fluxoCaixa) {

            consulta()

        } else {

            consultaTotal()

        }
       

    }, [tipo, ano, fluxoCaixa, mes])

    return (

        <div>


            <div class="modal fade modal-FluxoCaixaDetalheAno"
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Fluxo Caixa {ano} - {tipo} - {fluxoCaixa ? fluxoCaixa : 'TOTAL'}</h4>
                        </div>
                        <div class="modal-body">

                            <table id="table-sm" class="table table-bordered table-hover table-sm">
                                <thead>
                                    <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF', textAlign: 'center'}}>
                                        <th>
                                            Mês
                                        </th>
                                        <th>
                                            Orçado
                                        </th>
                                        
                                        <th>
                                            {tipoTotal}
                                        </th>
                                        <th>
                                            %
                                        </th>
                                        
                                    </tr>
                                </thead>
                                <tbody>

                                   {resultado}   

                                <tr style={{ fontSize: '11px', backgroundColor: '#EFEFEF'}}>
                                    <th >
                                        <center>
                                        Total
                                        </center>
                                    </th>
                                    <th>
                                        <div align="right">
                                        {totalM}
                                        </div>
                                    </th>
                                    <th>
                                        <div align="right">
                                        {totalP}
                                        </div>
                                    </th>
                                    <th>
                                        <div align="right">
                                        {totalAnoPerc}
                                        </div>
                                    </th>
                                </tr>     
                                <tr style={{ fontSize: '11px', backgroundColor: '#e0dae8'}}>
                                    <th>
                                        <center>
                                        Previsão Ano
                                        </center>
                                    </th>
                                    <th>
                                        <div align="right">
                                        {totalOrcamentoAno}
                                        </div>
                                    </th>
                                    <th>
                                        &nbsp;
                                    </th>
                                    <th>
                                        <div align="right">
                                        {totalOrcamentoAnoPerc}
                                        </div>
                                    </th>
                                </tr>                           

                                </tbody>

                            </table>

                            <br/>
                            <br/>

                            <ResponsiveContainer width="100%" height={200}>

                                    

                                <LineChart data={dataChart} margin={{ top: 5, right: 20, bottom: 5, left: 0 } }>
                                    <Line type="monotone" dataKey="total" stroke="#8884d8" label={<CustomizedLabel />} />
                                    <Line type="monotone" dataKey="meta" stroke="#eb4034" />

                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" style={{ fontSize: '11px', fontFamily: 'Arial' }}
                                    
                                    
                                    />
                                    <YAxis style={{ fontSize: '10px', fontFamily: 'Arial' }} 
                                    tick={false}
                                    //hide={true}
                                    //tickFormatter={tick => {return tick.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });}}
                                    />
                                    
                                    
                                    
                                    <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
                                </LineChart>


                            </ResponsiveContainer>

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}


export default ModalFluxoCaixaDetalheAno