
import { useEffect, useState, useMemo } from 'react'
//import { useNavigate } from 'react-router-dom';
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import ModalVinculoEdit from '../modal/ModalVinculoEdit'
import { useAtualizaContext } from '../context/atualizaContext'
import { sleep } from '../functions/generalFunctions'


const FluxoCaixaVinculosAtivos = (props) => {

    const { dataAtualiza } = useAtualizaContext()
    
    const [resultado, setResultado] = useState([])
    const [vinculo_id, setVinculo_id] = useState()


    const columns = useMemo(
        () => [
          {
            accessorKey: 'codigoNatureza', //access nested data with dot notation
            header: 'Cód. Natureza',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'natureza',
            header: 'Natureza',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
          },
          {
            accessorKey: 'itemContabil', //normal accessorKey
            header: 'Item Contábil',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          {
            accessorKey: 'tipo',
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            accessorKey: 'fluxoCaixa',
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          {
            accessorKey: 'area',
            header: 'Área',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
            
          },
          {
            header: 'Descr. item contábil',
            accessorKey: 'itemContabilDescri',
            muiTableHeadCellProps: {
                align: 'left',
              },
            muiTableBodyCellProps: {
                align: 'left',
              },
            
          },
          
          
        ],
        [],
      );



      async function exportaExcel() {


        //return false
  
        window.toastr.info('Aguarde, gerando XLSX...')
  
  
        await api.post('vinculoXLSX').then((result) => {
  
            console.log(result.data)
  
            if (result.data.status == 'ok') {
  

                sleep(3000).then(() => {
            
                  //console.log(result.data[0].fileNameCompleto, '!!')
                  //window.location.href = result.data.arquivo
                  //window.location.href = `http://192.168.255.165:3378/${result.data.arquivo}`
                  window.location.href = `${process.env.REACT_APP_API}/${result.data.arquivo}`
                  
                  //window.location.href = `http://172.16.1.253:3378/${result.data.arquivo}`
  
              })
                
  
            }
  
        }).catch((err) => {
  
            console.log(err.response)
  
        })
  
  
      }
  


    const carregaVinculos = () => {

        api.post('listaVinculo').then((result) => {

            
            var data = result.data
            setResultado(data.map((rs) => {

                return {

                    vinculo_id: rs.vinculo_id,
                    codigoNatureza: rs.codigoNatureza,
                    natureza: rs.natureza,
                    itemContabil: rs.itemContabil,
                    tipo: rs.tipo,
                    fluxoCaixa: rs.fluxoCaixa,
                    area: rs.area,
                    itemContabilDescri: rs.itemContabilDescri,

                }

            }
            
            ))

        }).catch((err) => {

            console.log(err.response)

        })

    }



    useEffect(() => {

      //if (sessionStorage.getItem('perfil') == 'Administrador') {

          carregaVinculos()

      //}

    }, [dataAtualiza])




    return (

        <div>


            <button type="button" class="btn btn-inline btn-warning" onClick={() => {window.$('.modal-VinculoEdit').modal('show');setVinculo_id()}}>+ Vínculo</button>
            <button type="button" class="btn btn-inline btn-success" onClick={() => exportaExcel()}>Exportar Excel</button>

            <br/><br/>


            <MaterialReactTable 
                            
                            columns={columns} 
                            data={resultado} 
                            localization={MRT_Localization_PT_BR}
                            //rowsPerPage={20}
                            //options={options}
                            initialState={{ density: 'compact' }}
                            muiTableHeadCellProps={{
                                //easier way to create media queries, no useMediaQuery hook needed.
                                sx: {
                                fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                },
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    fontSize: {
                                    xs: '8px',
                                    sm: '9px',
                                    md: '10px',
                                    lg: '11px',
                                    xl: '12px',
                                    },
                                },
                            }}

                            muiTableBodyRowProps={({ row }) => ({
                                onClick: (event) => {
                                  //console.info(event, row.original.usuario_id);
                                  //navigate(`/FluxoCaixa/Vinculo/${row.original.vinculo_id}`)
                                  window.$('.modal-VinculoEdit').modal('show');setVinculo_id(row.original.vinculo_id)
            
                                },
                                sx: {
                                  cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                                },
                              })}
                            
                        />

        

              <ModalVinculoEdit vinculo_id={vinculo_id} origem='' consultaTotalVinculosPendentes={props.consultaTotalVinculosPendentes} />


        </div>
    )

}

export default FluxoCaixaVinculosAtivos