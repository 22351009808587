import { useState, useEffect } from 'react'
import api from '../components/api'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import ptLocale from '@fullcalendar/core/locales/pt-br'
import interactionPlugin from "@fullcalendar/interaction"



const Calendario = () => {


    const [eventos, setEventos] = useState([])


    const carrega = () => {

        

        api.get('calendarioTotais').then((result) => {

            //console.log(result.data)

            setEventos(result.data)

        }).catch((err) => {

            console.log(err.response)

        })


    }


    useEffect(() => {


        carrega()


    }, [])


    return (

        <div>


                <h5 className="m-t-lg with-border">Compras - Calendário</h5>



                <div className="row">
                    <div className="col-md-12">
                    

                        <FullCalendar 
                            locale={ptLocale}
                            plugins={[dayGridPlugin, interactionPlugin]}
                            initialView="dayGridMonth"
                            events={eventos}
                            displayEventTime={false}
                            contentHeight="auto"
                            color="#FFF"
                            eventClick={
                                function (arg) {

                                    //alert(arg.event.title)
                                    //alert(arg.event.id)
                                    //history.push(`/Evento/${arg.event.id}`)

                                }
                            }

                        />

                    </div>

                </div>

                

        </div>

    )


}


export default Calendario