
import ComprasDemandas from "../componentsPages/ComprasDemandas"
import ComprasSolicitantes from '../componentsPages/ComprasSolicitantes'
import ComprasModalidades from '../componentsPages/ComprasModalidades'
import ComprasIndicadores from '../componentsPages/ComprasIndicadores'
import ComprasReservaRecursos from '../componentsPages/ComprasReservaRecursos'



const Compras = () => {

    const perfil = sessionStorage.getItem('perfil')
    const assinaReservaRecurso = sessionStorage.getItem('assinaReservaRecurso')

    //console.log(assinaReservaRecurso)
    return (

        <div>


            <h5 className="m-t-lg with-border">Compras - Demandas</h5>

            <section className="tabs-section">
                <div className="tabs-section-nav tabs-section-nav-inline">
                    <ul className="nav" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#tabs-4b-tab-1b" role="tab" data-toggle="tab">
                                Demandas
                            </a>
                        </li>

                        <li className="nav-item" style={{ display: perfil == 'Administrador' || assinaReservaRecurso == 'true' ? 'table-row' : 'none' }}>
                            <a className="nav-link " href="#tabs-4b-tab-5b" role="tab" data-toggle="tab">
                                Reserva de Recursos
                            </a>
                        </li>

                        <li className="nav-item" style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>
                            <a className="nav-link " href="#tabs-4b-tab-2b" role="tab" data-toggle="tab">
                                Solicitantes
                            </a>
                        </li>

                        <li className="nav-item" style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>
                            <a className="nav-link " href="#tabs-4b-tab-3b" role="tab" data-toggle="tab">
                                Modalidade
                            </a>
                        </li>
                        <li className="nav-item" style={{ display: perfil == 'ConsultaCompras' || perfil == 'Financeiro' ? 'none' : 'table-row' }}>
                            <a className="nav-link " href="#tabs-4b-tab-4b" role="tab" data-toggle="tab">
                                Indicadores
                            </a>
                        </li>

                        
                        
                    </ul>
                </div>

                <div className="tab-content" >
                    <div role="tabpanel" className="tab-pane fade in active show" id="tabs-4b-tab-1b">

                        <ComprasDemandas />

                    </div>
                    <div role="tabpanel" className="tab-pane fade in show" id="tabs-4b-tab-5b">

                        <ComprasReservaRecursos />

                    </div>
                    <div role="tabpanel" className="tab-pane fade in show" id="tabs-4b-tab-2b">

                        <ComprasSolicitantes />

                    </div>
                    <div role="tabpanel" className="tab-pane fade in show" id="tabs-4b-tab-3b">

                        <ComprasModalidades />

                    </div>

                    <div role="tabpanel" className="tab-pane fade in show" id="tabs-4b-tab-4b">

                        <ComprasIndicadores />

                    </div>
                 

                </div>

               
                    

            </section>


            
                
                  

        </div>
    )


}


export default Compras