import FluxoCaixaMovimento from '../componentsPages/FluxoCaixaMovimento'
import FluxoCaixaVinculos from '../componentsPages/FluxoCaixaVinculos'

const FinanceiroAdm = () => {

    return (

        
			<div>


            <h5 className="m-t-lg with-border">Financeiro - Administração</h5>

            <section className="tabs-section" >
                <div className="tabs-section-nav tabs-section-nav-icons" >
                    <div className="tbl" >
                        <ul className="nav" role="tablist" >


                            
                                <li className="nav-item" >
                                    <a className="nav-link active" href="#tabs-1-tab-1" role="tab" data-toggle="tab">
                                        <span className="nav-link-in">
                                            <i className="font-icon font-icon-cogwheel"></i>
                                            Movimento
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-item" >
                                    <a className="nav-link" href="#tabs-1-tab-2" role="tab" data-toggle="tab">
                                        <span className="nav-link-in">
                                        <i className="font-icon font-icon-users"></i>
                                            Vínculos 
                                        </span>
                                    </a>
                                </li>
                                
                            
                        </ul>
                    </div>
                </div>

                
                    <div class="tab-content" >
                        
                        <div role="tabpanel" className="tab-pane fade in active show" id="tabs-1-tab-1" >
                            
                                <FluxoCaixaMovimento />
                            
                        
                        </div>
                        <div role="tabpanel" className="tab-pane fade" id="tabs-1-tab-2" >
                            
                                <FluxoCaixaVinculos />
                            

                        </div>
                        

                    </div>
                
                
            </section>

            


        </div>


    )

}

export default FinanceiroAdm