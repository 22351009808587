
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../components/api'
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import { fmtMoney } from '../functions/generalFunctions';


const FluxoCaixaMensalMeta = () => {


    const [resultado, setResultado] = useState([])


    const consulta = () => {


        api.get('metaSaldo').then((result) => {

            

            setResultado(result.data.map((rs) => {

                return {
                    
                    metaSaldo_id: rs.metaSaldo_id,
                    ano: rs.ano,
                    mes:rs.mes,
                    tipo: rs.tipo,
                    fluxoCaixa: rs.fluxoCaixa,
                    valor: fmtMoney(rs.valor),
                }


            }))

        }).catch((err) => {

            console.log(err.response)

        })


    }


    
      
      const columns = useMemo(
        () => [
         
          {
            accessorKey: 'ano', //access nested data with dot notation
            header: 'Ano',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          {
            accessorKey: 'mes', //access nested data with dot notation
            header: 'Mês',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
           
          },
          
          {
            accessorKey: 'tipo', //normal accessorKey
            header: 'Tipo',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },

          {
            accessorKey: 'fluxoCaixa', //normal accessorKey
            header: 'Fluxo de Caixa',
            muiTableHeadCellProps: {
                align: 'center',
              },
            muiTableBodyCellProps: {
                align: 'center',
              },
          },
          

          {
            accessorKey: 'valor',
            header: 'Valor',
            muiTableHeadCellProps: {
                align: 'right',
              },
            muiTableBodyCellProps: {
                align: 'right',
              },
            
          },
          
          
        ],
        [],
      );




    useEffect(() => {

        consulta()

    }, [])


    return (
        <div>

            <button type="button" class="btn btn-inline btn-warning">+ Orçamento</button>
                    
            <br/><br/>


            <MaterialReactTable 
                
                columns={columns} 
                data={resultado} 
                localization={MRT_Localization_PT_BR}
                //rowsPerPage={20}
                //options={options}
                initialState={{ density: 'compact' }}
                muiTableHeadCellProps={{
                    //easier way to create media queries, no useMediaQuery hook needed.
                    sx: {
                    fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                    },
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        fontSize: {
                        xs: '8px',
                        sm: '9px',
                        md: '10px',
                        lg: '11px',
                        xl: '12px',
                        },
                    },
                }}

                muiTableBodyRowProps={({ row }) => ({
                    onClick: (event) => {
                    //console.info(event, row.original.usuario_id);
                    //navigate(`/Usuario/${row.original.usuario_id}`)

                    },
                    sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                    },
                })}
                
            />


        </div>
    )

}

export default FluxoCaixaMensalMeta