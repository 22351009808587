import { useEffect, useState } from 'react'
import api from '../components/api'


const Modal = (props) => {

    const fluxoCaixaOrdem_id = props.fluxoCaixaOrdem_id

    const [classe, setClasse] = useState('')
    const [ordem, setOrdem] = useState('')
    const [ordemClasse, setOrdemClasse] = useState('')
    const [tipo, setTipo] = useState('')
    const [fluxoCaixa, setFluxoCaixa] = useState('')

    const apaga = () => {


        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'red',
		    title: 'Confirmação',
		    content: 'Confirma apagar?',
		    buttons: {
		        Sim: function () {

            
                        api.delete(`fluxoCaixaOrdem/${demandaSolicitante_id}`).then((result) => {
            
                            if (result.data.alerta == 'ok') {
                                
                                window.toastr.success('Registro apagado com sucesso!')
                                window.$('.modal-FluxoCaixaEdit').modal('hide')
                                limpa()
                                props.listaFluxosCaixa()

                            }

                                        
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                   
                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });


    }

    const salva = () => {

        if (fluxoCaixa == '') {

            window.toastr.error('<b>Fluxo de Caixa</b> não pode ficar em branco!')
            return false

        }

        if (classe == '') {

            window.toastr.error('<b>Classe</b> não pode ficar em branco!')
            return false

        }

        if (ordemClasse == '') {

            window.toastr.error('<b>Ordem classe</b> não pode ficar em branco!')
            return false

        }


        if (ordem == '') {

            window.toastr.error('<b>Ordem fluxo de caixa</b> não pode ficar em branco!')
            return false

        }


        var dataPost = {

            ordemClasse: ordemClasse,
            classe: classe,
            fluxoCaixa: fluxoCaixa,
            tipo: tipo,
            ordem: ordem,
            
        }

        //console.log(dataPost)

        window.$.confirm({
			//icon: 'fa fa-question',
            theme: 'dark',
            closeIcon: true,
            animation: 'scale',
            type: 'green',
		    title: 'Confirmação',
		    content: 'Confirma salvar o solicitante?',
		    buttons: {
		        Sim: function () {


                    if (fluxoCaixaOrdem_id) {

                        api.put(`fluxoCaixaOrdem/${fluxoCaixaOrdem_id}`, dataPost).then((result) => {
            
                            console.log(result.data)
                            if (result.data.alerta == 'ok') {

                                window.toastr.success('Registro salvo com sucesso!')
                                window.$('.modal-FluxoCaixaEdit').modal('hide')
                                limpa()
                                props.listaFluxosCaixa()

                            }
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    } else {
            
                        api.post('fluxoCaixaOrdem', dataPost).then(() => {
            

                            window.toastr.success('Registro salvo com sucesso!')
                            window.$('.modal-FluxoCaixaEdit').modal('hide')
                            limpa()
                            props.listaFluxosCaixa()
            
                        }).catch((err) => {
            
                            console.log(err.response)
            
                        })
            
                    }

                    

                                    

                },
		        Cancelar: function () {
		            //$.alert('Canceled!');
		        }

            },

        });
        
    }


    const carrega = () => {

        api.get(`fluxoCaixaOrdem/${fluxoCaixaOrdem_id}`).then((result) => {

            var data = result.data[0]
            

            setOrdem(data.ordem)
            setOrdemClasse(data.ordemClasse)
            setFluxoCaixa(data.fluxoCaixa)
            setTipo(data.tipo)
            setClasse(data.classe)

        }).catch((err) => {

            console.log(err.response)

        })
    }


    const limpa = () => {

        setOrdem('')
        setOrdemClasse('')
        setFluxoCaixa('')
        setTipo('')
        setClasse('')

    }

    useEffect(() => {

        limpa()
        carrega()

    }, [fluxoCaixaOrdem_id])



    return (

        <div>

            <div class={`modal fade modal-FluxoCaixaEdit`}
                    id="testeModal"
                    tabindex="-1"
                    role="dialog"
                    
                    aria-labelledby="myLargeModalLabel"
                    aria-hidden="true"
                    
                    >
                <div class="modal-dialog modal-lg" >
                    <div class="modal-content"  >
                        <div class="modal-header" >
                            <button type="button" class="modal-close" data-dismiss="modal" aria-label="Close">
                                <i class="font-icon-close-2"></i>
                            </button>
                            <h4 class="modal-title" id="myModalLabel">Fluxo de Caixa {fluxoCaixaOrdem_id}</h4>
                        </div>
                        <div class="modal-body">


                            <div className="row">
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Ordem Classe</label>
                                        <input type="text" className="form-control" value={ordemClasse} maxLength={255} onChange={event => setOrdemClasse(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} />
                                    </fieldset>
                                </div>
                                <div className="col-md-9">
                                    <fieldset className="form-group">
                                        <label className="form-label">Classe</label>
                                        <input type="text" className="form-control" value={classe} maxLength={255} onChange={event => setClasse(event.target.value)}
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Ordem Fluxo de Caixa</label>
                                        <input type="text" className="form-control" value={ordem} maxLength={255} onChange={event => setOrdem(event.target.value)}
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                            }
                                        }} />
                                    </fieldset>
                                </div>
                                <div className="col-md-6">
                                    <fieldset className="form-group">
                                        <label className="form-label">Fluxo de Caixa</label>
                                        <input type="text" className="form-control" value={fluxoCaixa} maxLength={255} onChange={event => setFluxoCaixa(event.target.value)}
                                        />
                                    </fieldset>
                                </div>
                                <div className="col-md-3">
                                    <fieldset className="form-group">
                                        <label className="form-label">Tipo</label>
                                        <select className="form-control" value={tipo} onChange={event => setFluxoCaixa(event.target.value)}>
                                            <option value="">[Selecione]</option>
                                            <option value="RECEITAS">RECEITAS</option>
                                            <option value="DESPESAS">DESPESAS</option>
                                        </select>
                                    </fieldset>
                                </div>
                            </div>

                            

                        </div>
                        <div class="modal-footer">
                            
                            
                            <button type="button" className="btn btn-inline btn-default-outline"  data-dismiss="modal">Fechar</button>
                            <button type="button" className="btn btn-inline btn-danger-outline" onClick={() => apaga()} style={{ display: fluxoCaixaOrdem_id != null ? 'table-row' : 'none' }} >Apagar</button>
                            <button type="button" className="btn btn-inline btn-success-outline" onClick={() => salva()}  >Salvar</button>
                            
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )

}

export default Modal